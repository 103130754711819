import { z } from 'zod';
import {
  AxiomEmployeeTypeValues,
  LegalTechSkillsProficiencies,
  WorkPreferenceKeys,
} from '@axiom/const';
import { SchemaDate, ZodArrayToEnum } from '@axiom/types';

import { CandidateEmployeeTypes, CandidateProfileStatuses } from './candidate';
import { LanguageProficiencies } from './language';

export const EnvoyOccupationTypes = {
  Lawyer: 'Lawyer',
  'Legal Support': 'Legal Support',
  'Lawyer-Flex': 'Lawyer-Flex',
} as const;

export const EnvoyOccupationTypeValues = Object.values(EnvoyOccupationTypes);

export const TALENT_MIN_COMPENSATION_RATE = 0;
export const TALENT_MAX_COMPENSATION_RATE = 2250000;

export const TALENT_MIN_WEEKLY_AVAILABILITY = 0;
export const TALENT_MAX_WEEKLY_AVAILABILITY = 80;

export const TALENT_MIN_YEARS_OF_EXPERIENCE = 0;
export const TALENT_MAX_YEARS_OF_EXPERIENCE = 50;

const containsInvalidCharacters = (searchTerm: string | null = '') => {
  searchTerm = searchTerm || '';
  const invalidChars = /([!()+/:[\\\]^{}~-])/g;
  return invalidChars.test(searchTerm);
};

export const rangeRefinementError = 'To cannot be less than From';
export const decimalPlaceError = 'Only whole numbers allowed';
export const invalidCharactersErrorMessage = String.raw`Your search contains one or more of the following invalid characters ^ : / \ ( ) { } [ ] ! - ~ +. Please update your search and try again`;

const handleUndefinedNumber = (val: number | unknown) =>
  val || val === 0 ? Number(val) : undefined;

const isStartLessThanOrEqualToEnd = (
  start: number | unknown,
  end: number | unknown
) => {
  const a = handleUndefinedNumber(start);
  const b = handleUndefinedNumber(end);

  return a === undefined || b === undefined || a <= b;
};

const buildRangeField = (min: number, max: number) => {
  return z
    .number()
    .int(decimalPlaceError)
    .nonnegative()
    .min(min)
    .max(max)
    .nullish();
};

const buildRangeSchema = (min: number, max: number) => {
  return z
    .object({
      start: buildRangeField(min, max),
      end: buildRangeField(min, max),
    })
    .nullish()
    .refine(data => isStartLessThanOrEqualToEnd(data?.start, data?.end), {
      message: rangeRefinementError,
      path: ['end'],
    });
};

export const EnvoyCandidateSearchFiltersCompensationSchema = buildRangeSchema(
  TALENT_MIN_COMPENSATION_RATE,
  TALENT_MAX_COMPENSATION_RATE
);

export const EnvoyCandidateSearchFiltersWeeklyAvailabilitySchema =
  buildRangeSchema(
    TALENT_MIN_WEEKLY_AVAILABILITY,
    TALENT_MAX_WEEKLY_AVAILABILITY
  );

export const EnvoyCandidateSearchFiltersYearsOfExperienceSchema =
  buildRangeSchema(
    TALENT_MIN_YEARS_OF_EXPERIENCE,
    TALENT_MAX_YEARS_OF_EXPERIENCE
  );

export const EnvoyCandidateSearchFiltersSoonestEngagementEndDateSchema = z
  .object({ end: SchemaDate })
  .nullish();

export const EnvoyCandidateSearchFiltersSchema = z.object({
  axiomEmployeeType: z
    .array(z.enum(ZodArrayToEnum(AxiomEmployeeTypeValues)))
    .nullish(),
  barredLocations: z.array(z.string().uuid()).nullish(),
  compensation: EnvoyCandidateSearchFiltersCompensationSchema,
  employeeType: z
    .array(z.enum(ZodArrayToEnum(CandidateEmployeeTypes)))
    .nullish(),
  homeOfficeId: z.array(z.string().uuid()).nullish(),
  industries: z.array(z.string()).nullish(),
  languages: z.array(z.string().uuid()).nullish(),
  languageProficiencies: z
    .array(z.enum(ZodArrayToEnum(LanguageProficiencies)))
    .nullish(),
  legalTechnicalSkills: z.array(z.string()).nullish(),
  legalTechnicalSkillProficiencies: z
    .array(z.enum(ZodArrayToEnum(LegalTechSkillsProficiencies)))
    .nullish(),
  occupationType: z
    .array(z.enum(ZodArrayToEnum(EnvoyOccupationTypeValues)))
    .nullish(),
  ownerUserId: z.array(z.string().uuid()).nullish(),
  practiceAreaId: z.array(z.string().uuid()).nullish(),
  profileStatus: z
    .array(z.enum(ZodArrayToEnum(CandidateProfileStatuses)))
    .nullish(),
  search: z
    .string()
    .nullish()
    .refine(s => !containsInvalidCharacters(s), {
      message: invalidCharactersErrorMessage,
    }),
  soonestEngagementEndDate:
    EnvoyCandidateSearchFiltersSoonestEngagementEndDateSchema,
  tags: z.array(z.string().uuid()).nullish(),
  weeklyAvailability: EnvoyCandidateSearchFiltersWeeklyAvailabilitySchema,
  workPreferences: z
    .array(
      z.enum(
        ZodArrayToEnum(
          Object.keys(WorkPreferenceKeys) as Array<
            keyof typeof WorkPreferenceKeys
          >
        )
      )
    )
    .nullish(),
  yearsOfExperience: EnvoyCandidateSearchFiltersYearsOfExperienceSchema,
});

export type EnvoyCandidateSearchFilters = z.infer<
  typeof EnvoyCandidateSearchFiltersSchema
>;

export const EnvoyCandidateSearchFiltersFormSchema =
  EnvoyCandidateSearchFiltersSchema.pick({
    barredLocations: true,
    compensation: true,
    employeeType: true,
    homeOfficeId: true,
    industries: true,
    languages: true,
    languageProficiencies: true,
    legalTechnicalSkills: true,
    legalTechnicalSkillProficiencies: true,
    occupationType: true,
    ownerUserId: true,
    practiceAreaId: true,
    profileStatus: true,
    search: true,
    tags: true,
    weeklyAvailability: true,
    workPreferences: true,
    yearsOfExperience: true,
  }).extend({
    daysToSoonestEngagementEnd: z.number().nullish(),
    rollOffDateDropdown: z.number().nullish(),
    rollOffDatePicker: SchemaDate.nullish(),
  });

export type EnvoyCandidateSearchFiltersForm = z.infer<
  typeof EnvoyCandidateSearchFiltersFormSchema
>;

export const EnvoyCandidateSearchFiltersFormSchemaFields =
  EnvoyCandidateSearchFiltersFormSchema.keyof().Enum;
