import Ajv from 'ajv';
import { z } from 'zod';
import { TagConst } from '@axiom/const';
import { ZodArrayToEnum } from '@axiom/types';

import { axiomValidationOptions } from './options';
import { arrayValidatorCreator } from './general';

const { TAG_TYPES } = TagConst;

export const tagDbValues = Object.values(TAG_TYPES);

export const TagSchema = z.object({
  name: z.string().max(255),
  id: z.string().uuid(),
  type: z.enum(ZodArrayToEnum(tagDbValues)).nullish(),
  parentId: z.string().uuid().nullable(),
});

export type Tag = z.infer<typeof TagSchema>;

const ajv = new Ajv(axiomValidationOptions());

const tagCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
    type: {
      type: 'string',
      enum: tagDbValues,
    },
    parentId: {
      type: 'string',
      format: 'uuid',
    },
  },
  required: ['name'],
};

export const updateTagValidator = ajv.compile(tagCommonValidation);

export const createTagsValidator = arrayValidatorCreator(tagCommonValidation);
