import {
  CandidateProfileIssueConst,
  CandidateProfileIssueExperienceValue,
} from '@axiom/const';

const ProfileCompletionCriteria = {
  needsNativeLanguage: 'needsNativeLanguage',
  needsNonAxiomExperience: 'needsNonAxiomExperience',
  needsExperienceUpdate: 'needsExperienceUpdate',
  needsLegalTechSkills: 'needsLegalTechSkills',
  needsDegrees: 'needsDegrees',
  needsAcceptableSummary: 'needsAcceptableSummary',
  needsValidName: 'needsValidName',
  needsPracticeStartYear: 'needsPracticeStartYear',
  needsUpdatedPreferences: 'needsUpdatedPreferences',
} as const;

const ProfileCompletionSummaryBanner = {
  emptyTitle: 'Write a profile summary',
  emptyContent:
    'Your summary is the first thing a client sees. Help them get to know you and your experience better. We recommend writing at least 200 characters. You have a maximum of 500 characters.',
  tooShortTitle: 'Improve your profile summary',
  tooShortContent:
    'Your summary is too short. We find that summaries are received best by clients when they are between 200-500 characters.',
} as const;

const ProfileCompletionNameBanner = {
  allCaps: 'Use sentence case, not all caps, for your display name.',
  qualifications:
    'Remove qualifications (JD, MBA, LLM, etc.) from your display name.',
  titles: 'Remove the title (Esq., Mr., Mrs., etc.) from your display name.',
  nicknames:
    'Remove any nicknames from your display name, or replace your first name with your preferred name.',
} as const;

const ProfileCompletionExperienceDescriptionBanner = {
  missingTitle: 'Describe this experience',
  missingShortOrOldContent:
    'Clients want to see detailed work descriptions that show how you contribute to projects. For shorter experiences or older experiences like this, aim for at least 1 bullet point with a minimum of 100 characters.',
  missingLongOrRecentContent:
    'Clients want to see detailed work descriptions that show how you contribute to projects. For longer experiences within the last 10 years, aim for at least 3 bullet points with a minimum of 300 characters each.',
  tooLongTitle: 'Shorten this experience',
  tooLongContent:
    'Clients love details, but a shorter, punchier description better highlights core responsibilities and output. Try to keep this description to 10 bullet points or less (2,500 characters).',
  tooShortTitle: 'Expand on this experience',
  tooShortShortOrOldContent:
    'Clients love details and you’ve got room to add more. For shorter or older experiences like this, aim for at least 1 bullet point with a minimum of 100 characters.',
  tooShortLongOrRecentContent:
    'Clients love details and you’ve got room to add more. For longer experiences like this, aim for at least 3 bullet points and a minimum of 300 characters.',
  ongoingAxiomTitle: 'Update your ongoing engagement',
  ongoingAxiomShortOrOld:
    'Include new skills or projects you take on as your role evolves. Clients want to understand the full scope of your work even if it’s still ongoing. For shorter or older experiences like this, aim for at least 1 bullet point with a minimum of 100 characters.',
  ongoingAxiomLongOrRecent:
    'Include new skills or projects you take on as your role evolves. Clients want to understand the full scope of your work even if it’s still ongoing. For longer experiences like this, aim for at least 3 bullet points and a minimum of 300 characters.',
} as const;

type ProfileCompletionExperienceDescriptionBannerValue =
  (typeof ProfileCompletionExperienceDescriptionBanner)[keyof typeof ProfileCompletionExperienceDescriptionBanner];

const ExperienceDescriptionBannerConfigByCandidateProfileIssueContext: Partial<
  Record<
    CandidateProfileIssueExperienceValue,
    {
      title: ProfileCompletionExperienceDescriptionBannerValue;
      description: ProfileCompletionExperienceDescriptionBannerValue;
      estimatedTime: string;
    }
  >
> = {
  [CandidateProfileIssueConst.CandidateProfileIssueExperience
    .ShortOrOldExperienceMissingDescription]: {
    title: ProfileCompletionExperienceDescriptionBanner.missingTitle,
    description:
      ProfileCompletionExperienceDescriptionBanner.missingShortOrOldContent,
    estimatedTime: '5 min',
  },
  [CandidateProfileIssueConst.CandidateProfileIssueExperience
    .LongAndRecentExperienceMissingDescription]: {
    title: ProfileCompletionExperienceDescriptionBanner.missingTitle,
    description:
      ProfileCompletionExperienceDescriptionBanner.missingLongOrRecentContent,
    estimatedTime: '15 min',
  },
  [CandidateProfileIssueConst.CandidateProfileIssueExperience
    .ShortOrOldExperienceDescriptionTooShort]: {
    title: ProfileCompletionExperienceDescriptionBanner.tooShortTitle,
    description:
      ProfileCompletionExperienceDescriptionBanner.tooShortShortOrOldContent,
    estimatedTime: '5 min',
  },
  [CandidateProfileIssueConst.CandidateProfileIssueExperience
    .LongAndRecentExperienceDescriptionTooShort]: {
    title: ProfileCompletionExperienceDescriptionBanner.tooShortTitle,
    description:
      ProfileCompletionExperienceDescriptionBanner.tooShortLongOrRecentContent,
    estimatedTime: '15 min',
  },
  [CandidateProfileIssueConst.CandidateProfileIssueExperience
    .ShortOngoingExperienceMissingOrDescriptionTooShort]: {
    title: ProfileCompletionExperienceDescriptionBanner.ongoingAxiomTitle,
    description:
      ProfileCompletionExperienceDescriptionBanner.ongoingAxiomShortOrOld,
    estimatedTime: '5 min',
  },
  [CandidateProfileIssueConst.CandidateProfileIssueExperience
    .LongOngoingExperienceMissingOrDescriptionTooShort]: {
    title: ProfileCompletionExperienceDescriptionBanner.ongoingAxiomTitle,
    description:
      ProfileCompletionExperienceDescriptionBanner.ongoingAxiomLongOrRecent,
    estimatedTime: '15 min',
  },
};

const ProfileCompletionExperienceIndustryBanner = {
  missingTitle: 'Add an Industry',
  missingContent:
    'We require an Industry for each experience. This helps Axiom find your profile and know what types of engagements to submit you for in the future.',
} as const;

const ProfileCompletionExperienceTaxonomyBanner = {
  missingTitle: 'Review focus areas & skills',
  missingContent:
    'Each focus area needs to have at least one skill. Adding focus areas and skills to each experience helps clients and Axiom find you for the most relevant engagements. We’ve suggested focus areas and skills for you based on your experience.',
} as const;

const ProfileCompletionLegalTechSkillsBanner = {
  missingTitle: 'Add legal tech skills',
  missingContent:
    'Some roles require proficiency with specific tools. Add your proficiency so we can match and promote you for the opportunities that fit your skillset.',
} as const;

const ProfileCompletionAccomplishmentsBanner = {
  missingTitle: 'Add an accomplishment',
  missingContent:
    'Demonstrate your passion and help your profile stand out by adding certifications, awards, and qualifications.',
} as const;

const ProfileCompletionLanguagesBanner = {
  missingTitle: 'Add a language',
  missingContent:
    'Add your primary language and any other languages you speak. Make sure to include proficiency, skill, and CEFR (as needed).',
} as const;

const ProfileCompletionEducationBanner = {
  missingTitle: 'Complete your education section',
  missingContent:
    'Make sure that at least one degree is present for each level of education entered, and all fields are filled out.',
} as const;

export const ProfileCompletionConst = {
  ExperienceDescriptionBannerConfigByCandidateProfileIssueContext,
  ProfileCompletionCriteria,
  ProfileCompletionExperienceDescriptionBanner,
  ProfileCompletionExperienceIndustryBanner,
  ProfileCompletionExperienceTaxonomyBanner,
  ProfileCompletionLegalTechSkillsBanner,
  ProfileCompletionSummaryBanner,
  ProfileCompletionNameBanner,
  ProfileCompletionAccomplishmentsBanner,
  ProfileCompletionLanguagesBanner,
  ProfileCompletionEducationBanner,
  minimumAcceptableSummaryLength: 200,
  maximumAcceptableExperienceDescriptionLength: 2500,
} as const;
