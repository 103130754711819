// replaces const found in domino/api/listeners/notifications/src/responders/constants.js
// replaces const found in domino/api/src/lib/time-triggered-notifications/constants.js
export const NOTIFICATION_TYPE = 'notification';

export const EMAIL_TYPE = 'email';

export const HUBSPOT_TYPE = 'hubspot';
export const isHubspotType = (t: string) => t === HUBSPOT_TYPE;

export const NOTIFICATION_CATEGORY = {
  benchUpdate: 'benchUpdate',
  availabilityUpdate: 'availabilityUpdate',
} as const;

export const HUBSPOT_PARAMS = 'hubspotParams';

export const HubspotEmails = {
  blockedSignupDomain: 85643091064, // Sign Up - New User Blocked Domain
  callToAction: 117321533057, // Call To Action Template For: Hire, Interview Requested
  callToActionPassing: 128369595016, // Call To Action Template For: Passing
  candidateOffWaitlist: 86077240071, // New Credentials for Axiom for Talent
  candidateOpportunityInterestedRejection: 103861662103, // Interested Rejection
  candidateOpportunityInterested: 85649479817, // Opportunity Interest Confirmation
  contactSignUpAdmin: 85643089997, // Sign Up - Admin Notification
  contactSignUpNewUser: 85615382608, // Sign Up - New User (dev)
  contactSignUpExistingUser: 85643050160, // Sign Up - Existing User
  envoyCandidateAlum: 94780061578, // Alum Initiated for Talent. Here are your next steps
  envoyCandidateOpportunityInterested: 85644223240, // Opp Feed - Talent Expressed Interest
  exTalentAvailabilityIncrease: 87107872024, // Availability Change (exTalent refers to previously engaged talent<->account relationship)
  exTalentPendingBeach: 86063723702, // Availability Soon
  initialReviewProfile: 128363538357, // 48 hours after candidate create + resume parsed to review profile
  interviewRequested: 112883590020, // Interview Requested TODO: Remove?
  jobActivity: 85649475199, // Envoy Activity Opportunity
  magicLink: 85643445689, // Magic Link
  newTalentEngaged: 86074603426, // Joined Your Bench (newTalent refers to any new addition to a bench)
  newUserSetPassword: 87199476581, // Welcome to Axiom!
  scheduledOpportunityFeedsEmail: 139043503451, // Daily or Weekly Opportunity Feed Emails
  sendAvailabilityUpdate: 88698269746, // Availability Notification
  setPassword: 124595769691, // CTA set new password - first time user
  shareProject: 117306008474, // CTA Shared Project
  soonestEngagementRollOffDate: 87773634675, // Soonest Engagement Roll Off Date
  talentActivity: 85649475758, // Envoy Activity Talent
  talentDirectExpressedEmail: 164241085578, // Opp Feed - Talent Expressed Interested on Direct Eligible Opportunity
  talentJobActivity: 85616378738, // Envoy Activity All
  talkRequestClient: 85608259131, // Talk Request - Client
  welcomePart3: 134450622114, // Axiom Platform - Welcome Email 3
} as const;

export const HUBSPOT_ACTIONS = {
  // Make key name -> value enum: {emailName1: 'emailName1', emailName2: 'emailName2'}
  ...Object.keys(HubspotEmails).reduce((acc, h) => ({ ...acc, [h]: h }), {}),
} as Record<string, string>;
