import Ajv from 'ajv';
import ajvKeywords from 'ajv-keywords';
import { z } from 'zod';

import { axiomValidationOptions } from './options';
import { ContactSchema } from './contacts';

export const AccountShareSchema = z.object({
  message: z.string().nullish(),
  contacts: z
    .array(ContactSchema.pick({ email: true, firstName: true, lastName: true }))
    .nullish(),
});
export type AccountShare = z.infer<typeof AccountShareSchema>;

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

ajvKeywords(ajv, 'transform');

const accountsShareValidation = {
  additionalProperties: false,
  type: 'object',
  properties: {
    message: { type: 'string' },
    submissionId: {
      type: ['string'],
      format: 'uuid',
    },
    contacts: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: false,
        properties: {
          email: {
            type: 'string',
            format: 'email',
            transform: ['trim', 'toLowerCase'],
          },
          firstName: {
            type: 'string',
          },
          lastName: {
            type: 'string',
          },
        },
        required: ['email', 'firstName', 'lastName'],
      },
    },
  },
  required: ['contacts', 'submissionId'],
};
export const accountsShareValidator = ajv.compile(accountsShareValidation);
