import React from 'react';
import { CondensedMedium, Flashy, Gutter } from '@axiom/ui';
import { PasswordStrengthUtil } from '@axiom/utils';

import { StrengthBlock, StrengthMeter } from './PasswordStrengthMeterStyles';

export const scoreLabels = [
  'Very Weak',
  'Weak',
  'Fair',
  'Strong',
  'Very Strong',
] as const;
export const PasswordStrengthMeter: React.FC<{
  password: string;
}> = ({ password }) => {
  const score = PasswordStrengthUtil.getPasswordScore(password);
  const label = scoreLabels[score];
  return (
    <>
      <Gutter bottom="8px">
        <StrengthMeter>
          <StrengthBlock
            data-test="block-0"
            full={!!password && score >= 0}
            score={score}
          />
          <StrengthBlock data-test="block-1" full={score >= 1} score={score} />
          <StrengthBlock data-test="block-2" full={score >= 2} score={score} />
          <StrengthBlock data-test="block-3" full={score >= 3} score={score} />
          <StrengthBlock data-test="block-4" full={score >= 4} score={score} />
        </StrengthMeter>
      </Gutter>
      <CondensedMedium className="text-align-center" name="strength-label">
        <Flashy color="textSubtle">Strength: {!!password && label}</Flashy>
      </CondensedMedium>
    </>
  );
};
