import React from 'react';
import styled from 'styled-components';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

type ElementType = 'label' | 'div' | 'span';

interface LabelProps {
  as: string;
  children?: React.ReactNode;
  dataTest?: string;
}

export interface FormLabelProps {
  as?: ElementType;
  for?: string;
  children?: React.ReactNode;
  className?: string;
  invalid?: boolean;
  name?: string;
  maxLines?: number;
}

const Label = ({ as, children, dataTest }: LabelProps) =>
  React.createElement(as, { dataTest }, children);

const StyledLabel = styled(Label)`
  vertical-align: middle;
  padding-top: 3px;
`;

export const FormLabel = ({
  as = 'label',
  for: forProp,
  children,
  className = '',
  invalid = false,
  name,
  maxLines,
}: FormLabelProps) => (
  <StyledLabel
    as={as}
    className={AttrsHelper.formatClassname(
      'form-label',
      maxLines && `max-lines-${maxLines}`,
      invalid && 'invalid',
      className
    )}
    data-test={`${name || children}`}
    htmlFor={forProp}
  >
    {children}
  </StyledLabel>
);
