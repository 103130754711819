import { z } from 'zod';
import { SchemaEmail, SchemaTimestamp } from '@axiom/types';

import { OpportunitySchema } from './opportunity';
import { TimeZoneIanaSchema } from './time-zone';

export const CalendarEventStates = [
  'saved',
  'new',
  'modified',
  'delete',
] as const;
export const CalendarEventAttendeeUserTypes = ['Talent', 'Client'] as const;

export const CalendarEventAttendeeSchema = z.object({
  email: SchemaEmail.nullable(),
  name: z.string(),
  userType: z.enum(CalendarEventAttendeeUserTypes).nullable(),
});
export type CalendarEventAttendee = z.infer<typeof CalendarEventAttendeeSchema>;

const CalendarEventBaseSchema = z.object({
  accountName: z.string().nullish(),
  attendees: z.array(CalendarEventAttendeeSchema).nullish(),
  busy: z.boolean(),
  error: z.string().nullish(),
  id: z.string().nullish(),
  name: z.string().nullish(),
  state: z.enum(CalendarEventStates),
  title: z.string().nullish(),
});

export const CalendarEventSchema = CalendarEventBaseSchema.extend({
  endTime: SchemaTimestamp,
  startTime: SchemaTimestamp,
});
export type CalendarEventType = z.infer<typeof CalendarEventSchema>;

export const CalendarComponentEventSchema = CalendarEventBaseSchema.extend({
  end: z.date(),
  start: z.date(),
  isBackgroundEvent: z.boolean().nullish(),
});

export type CalendarComponentEvent = z.infer<
  typeof CalendarComponentEventSchema
>;

const CalendarBaseSchema = z.object({
  candidateId: z.string().uuid().nullable(),
  contactId: z.string().uuid().nullable(),
  id: z.string().uuid().nullable(),
  nylasCalendarId: z.string().nullable(),
  timezone: TimeZoneIanaSchema,
});

export const CalendarFreeTimeUpdatedUserTypes = ['Talent', 'HQ'] as const;
export const CalendarSchema = CalendarBaseSchema.extend({
  events: z.array(CalendarEventSchema).nullable(),
  freeTimeUpdatedAt: SchemaTimestamp.nullable(),
  freeTimeUpdatedUserType: z.enum(CalendarFreeTimeUpdatedUserTypes).nullable(),
});
export type Calendar = z.infer<typeof CalendarSchema>;

export const CalendarManualInterviewAttendeeSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: SchemaEmail,
});

export const CalendarManualInterviewSchema = CalendarBaseSchema.pick({
  candidateId: true,
})
  .merge(
    OpportunitySchema.pick({
      submissionId: true,
    })
  )
  .extend({
    adhocAttendees: z.array(CalendarManualInterviewAttendeeSchema).nullable(),
    availabilityNote: z.string().nullish(),
    contactIds: z.array(z.string().uuid()).nullable(),
  });

export type CalendarManualInterview = z.infer<
  typeof CalendarManualInterviewSchema
>;

export const CalendarScheduledInterviewSchema =
  CalendarManualInterviewSchema.merge(
    CalendarEventSchema.pick({
      title: true,
      endTime: true,
      startTime: true,
    })
  );
export type CalendarScheduledInterview = z.infer<
  typeof CalendarScheduledInterviewSchema
>;

export const CalendarPermissionsSchema = z.object({
  staticEvent: z.object({
    canCreate: z.boolean().default(false),
    canDragToCreate: z.boolean().default(false),
    canCreateMultiple: z.boolean().default(false),
    canCreateFreely: z.boolean().default(false),
    canMove: z.boolean().default(false),
    canResize: z.boolean().default(false),
    canDelete: z.boolean().default(false),
    canDeleteSaved: z.boolean().default(false),
  }),
  eventBoundary: z.object({
    canCreate: z.boolean().default(false),
    canMove: z.boolean().default(false),
    canResize: z.boolean().default(false),
    canDragToCreate: z.boolean().default(false),
    canDelete: z.boolean().default(false),
  }),
  allEvents: z.object({
    canSameDay: z.boolean().default(false),
  }),
});

export type CalendarPermissions = z.infer<typeof CalendarPermissionsSchema>;
