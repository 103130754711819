import { Location as ReactRouterLocation } from 'react-router-dom';

export const WindowUtil = {
  getFullRedirect: (
    location: Location | ReactRouterLocation = window.location
  ) => `${location.search}${location.hash}`,
  getUrlWithRelayState: (
    basePath: string,
    {
      query = new URLSearchParams(),
      location = window.location,
    }: {
      query?: URLSearchParams;
      location?: Location | ReactRouterLocation;
    } = {}
  ) => {
    const search = new URLSearchParams(location.search);
    const relayState = search.get('RelayState');
    if (relayState) {
      query.set('RelayState', relayState);
    }
    const queryString = query.toString();

    return `${basePath}${queryString ? `?${queryString}` : ''}`;
  },
};
