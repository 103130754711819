import React, { useEffect, useState } from 'react';

import { isVisible } from '../Visible/Visible';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { useBreakpoint } from '../../../hooks/useBreakpoint';

import {
  SidebarProps,
  SidedrawerDirections,
  SidedrawerUtil,
} from './SidedrawerUtil';

export const Sidedrawer = ({ children }: { children: React.ReactNode }) => {
  const breakpoints = useBreakpoint();
  const [, setLastUpdatedAt] = useState<Date>();
  useEffect(() => {
    const subscription = SidedrawerUtil.listen(() => {
      setLastUpdatedAt(new Date());
    });

    setLastUpdatedAt(new Date());

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (breakpoints.isSmallScreen) {
      document.body.style.overflowY = SidedrawerUtil.isOpen()
        ? 'hidden'
        : 'initial';
    }
  }, [SidedrawerUtil.isOpen()]);

  const visibleSidedrawers = Object.values(SidedrawerDirections).reduce(
    (acc: Record<string, Array<SidebarProps>>, direction) => {
      acc[direction] = SidedrawerUtil.getAll()[direction].filter(
        sidedrawerConfig =>
          !sidedrawerConfig.only ||
          isVisible(sidedrawerConfig.only, breakpoints)
      );

      return acc;
    },
    {}
  );
  return (
    <div
      className={AttrsHelper.formatClassname(
        'sidedrawer-container',
        SidedrawerUtil.isOpen() && 'sidedrawer-open'
      )}
    >
      {['left', 'right'].map(direction => {
        const sidedrawers = visibleSidedrawers[direction];
        return (
          (sidedrawers?.length ?? 0) > 0 && (
            <div className={`sidedrawer-${direction}`} key={direction}>
              {sidedrawers?.map(sidebar => (
                <div
                  className="sidedrawer-block"
                  key={sidebar.name}
                  data-test={sidebar.name}
                >
                  {sidebar.content}
                </div>
              ))}
            </div>
          )
        );
      })}

      <div
        className={AttrsHelper.formatClassname(
          'sidedrawer-wrapper-content',
          (visibleSidedrawers?.left?.length ?? 0) > 0 &&
            `sidedrawer-left-${visibleSidedrawers?.left?.length}`,
          (visibleSidedrawers?.right?.length ?? 0) > 0 &&
            `sidedrawer-right-${visibleSidedrawers?.right?.length}`
        )}
      >
        {children}
      </div>
    </div>
  );
};
