import { z } from 'zod';
import { CandidateProfileIssueConst } from '@axiom/const';
import { ZodArrayToEnum } from '@axiom/types';

export const CandidateProfileCompletionIssues = Object.values(
  CandidateProfileIssueConst.CandidateProfileIssue
);

export const CandidateProfileCompletionCategories = Object.values(
  CandidateProfileIssueConst.CandidateProfileCategory
);

export const CandidateProfileCompletionIssueContexts = [
  ...Object.values(CandidateProfileIssueConst.CandidateProfileIssuePreference),
  ...Object.values(CandidateProfileIssueConst.CandidateProfileIssueName),
  ...Object.values(CandidateProfileIssueConst.CandidateProfileIssueExperience),
];

export const CandidateProfileCompletionIssueSchema = z.object({
  category: z.enum(ZodArrayToEnum(CandidateProfileCompletionCategories)),
  context: z
    .enum(ZodArrayToEnum(CandidateProfileCompletionIssueContexts))
    .nullable(),
  entityId: z.string().uuid().nullable(),
  issue: z.enum(ZodArrayToEnum(CandidateProfileCompletionIssues)),
});

export type CandidateProfileIssue = z.infer<
  typeof CandidateProfileCompletionIssueSchema
>;

export const CandidateProfileIssuesSchema = z.object({
  issuesCount: z.number(),
  issues: z.array(CandidateProfileCompletionIssueSchema),
});

export type CandidateProfileIssues = z.infer<
  typeof CandidateProfileIssuesSchema
>;
