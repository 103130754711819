import React from 'react';
import { GridColumn as SGridColumn } from 'semantic-ui-react';

import {
  ResponsiveColumnOptions,
  ResponsiveConfig,
  StringifiedResponsiveColumnOptions,
  ViewportName,
} from '../../../configs/responsive-config';

type ElementType = 'div' | 'span';

type FloatedType = 'left' | 'right';
type TextAlignType = 'left' | 'center' | 'right' | 'justified';
type VerticalAlignType = 'top' | 'middle' | 'bottom';

export interface GridColumnProps {
  as?: ElementType;
  children?: React.ReactNode;
  className?: string;
  desktopWidth?: StringifiedResponsiveColumnOptions | ResponsiveColumnOptions;
  floated?: FloatedType;
  largeScreenWidth?:
    | StringifiedResponsiveColumnOptions
    | ResponsiveColumnOptions;
  mobileWidth?: StringifiedResponsiveColumnOptions | ResponsiveColumnOptions;
  name?: string;
  /**
   * Usage:
   * only="desktop tablet"
   * only="largeScreen"
   * only="smallScreen"
   *
   * For Storybook, it uses this syntax
   * only={["desktop"]}
   */
  only?: string | ViewportName[];
  smallScreenWidth?:
    | StringifiedResponsiveColumnOptions
    | ResponsiveColumnOptions;
  stretched?: boolean;
  tabletWidth?: StringifiedResponsiveColumnOptions | ResponsiveColumnOptions;
  textAlign?: TextAlignType;
  verticalAlign?: VerticalAlignType;
  widescreenWidth?:
    | StringifiedResponsiveColumnOptions
    | ResponsiveColumnOptions;
  width?: StringifiedResponsiveColumnOptions | ResponsiveColumnOptions;
}

const defaultOnly: ViewportName[] = [];

export const GridColumn = ({
  as = 'div',
  children,
  desktopWidth,
  className,
  floated,
  largeScreenWidth,
  mobileWidth,
  name,
  only = defaultOnly,
  smallScreenWidth,
  stretched,
  tabletWidth,
  textAlign,
  verticalAlign,
  widescreenWidth,
  width,
}: GridColumnProps) => {
  interface AdditionalProps {
    mobile?: StringifiedResponsiveColumnOptions | ResponsiveColumnOptions;
    tablet?: StringifiedResponsiveColumnOptions | ResponsiveColumnOptions;
    largeScreen?: StringifiedResponsiveColumnOptions | ResponsiveColumnOptions;
    widescreen?: StringifiedResponsiveColumnOptions | ResponsiveColumnOptions;
  }

  const additionalProps: AdditionalProps = {
    mobile: mobileWidth || smallScreenWidth,
    tablet: tabletWidth || smallScreenWidth,
    largeScreen: desktopWidth || largeScreenWidth,
    widescreen: widescreenWidth || largeScreenWidth,
  };

  const keys = Object.keys(additionalProps) as (keyof AdditionalProps)[];
  return React.createElement(
    SGridColumn,
    {
      as,
      'data-test': name,
      className,
      floated,
      only: ResponsiveConfig.convertOnlyToSemanticOnly(only) || undefined,
      stretched,
      textAlign,
      verticalAlign,
      ...keys.reduce((acc, propName) => {
        if (additionalProps[propName]) {
          acc[propName] = additionalProps[propName];
        }
        return acc;
      }, {} as AdditionalProps),
      width,
    },
    children
  );
};
