import React from 'react';

import { LightTheme } from '../../theme/LightTheme/LightTheme';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export const CardShadows = {
  default: 'default',
  elevated: 'elevated',
} as const;

export type CardShadows = (typeof CardShadows)[keyof typeof CardShadows];

export type CardProps = {
  background?: 'light';
  children?: React.ReactNode;
  className?: string;
  name?: string;
  shadow?: CardShadows | null;
  stretched?: boolean;
  scrollable?: boolean;
};

export const Card = ({
  background = 'light',
  className,
  children,
  name,
  shadow = 'default',
  stretched,
  scrollable,
}: CardProps) => {
  const CardTemplate = (
    <div
      data-test={name}
      className={AttrsHelper.formatClassname(
        'card-container',
        background && `background-${background}`,
        shadow ? `shadow-${shadow}` : 'flat-card',
        stretched && 'stretched',
        scrollable && 'scrollable',
        className
      )}
    >
      {children}
    </div>
  );

  return shadow ? (
    <LightTheme backgroundName={null}>{CardTemplate}</LightTheme>
  ) : (
    CardTemplate
  );
};
