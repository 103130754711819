import Ajv from 'ajv';
import { z } from 'zod';

import { AhqSubmissionContactsOpportunitySchema } from './ahq-submission-contacts-opportunity';
import { axiomValidationOptions } from './options';
import { OpportunitySchema } from './opportunity';
import { AhqSubmissionCandidateOpportunitySchema } from './ahq-submission-candidate-opportunity';

export const AhqSubmissionSchema = OpportunitySchema.pick({
  accountId: true,
  accountName: true,
  currency: true,
  jobName: true,
  submissionId: true,
  submissionLastUpdatedAt: true,
  submissionPublishedAt: true,
}).extend({
  candidateOpportunities: z.array(AhqSubmissionCandidateOpportunitySchema),
  contactsOpportunities: z.array(AhqSubmissionContactsOpportunitySchema),
  newSubmissionType: z.boolean().default(false),
});

export type AhqSubmission = z.infer<typeof AhqSubmissionSchema>;

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

const updateSubmissionValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    newSubmissionType: { type: 'boolean' },
    candidates: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: false,
        properties: {
          candidateId: { type: 'string', format: 'uuid' },
          isActiveForSubmission: { type: 'boolean' },
          submissionRank: { type: 'integer' },
          submissionDescription: { type: 'string' },
          highlightedExperiences: {
            type: ['array', 'null'],
            items: {
              type: 'object',
              additionalProperties: false,
              properties: {
                id: {
                  type: 'string',
                  format: 'uuid',
                },
                isHighlighted: {
                  type: ['boolean', 'null'],
                },
                experienceVisible: {
                  type: 'boolean',
                },
                submissionDescription: {
                  type: ['string', 'null'],
                },
              },
            },
          },
          newSubmissionType: {
            type: ['boolean', 'null'],
          },
        },
        required: ['candidateId'],
        anyOf: [
          { required: ['isActiveForSubmission'] },
          { required: ['submissionRank'] },
          { required: ['submissionDescription'] },
          { required: ['highlightedExperiences'] },
        ],
      },
    },
  },
  anyOf: [{ required: ['candidates'] }],
};

const sendSubmissionEmailValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    contactIds: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
  },
  required: ['contactIds'],
};

export const updateSubmissionValidator = ajv.compile(
  updateSubmissionValidation
);

export const sendSubmissionEmailValidator = ajv.compile(
  sendSubmissionEmailValidation
);
