import React from 'react';
import styled from 'styled-components';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

type HeaderType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'div'
  | 'span';

export interface HeaderLinkProps {
  as?: HeaderType;
  children?: React.ReactNode;
  className?: string;
  name?: string;
  maxLines?: number | null;
}

interface H6Props {
  as: HeaderType;
  children: React.ReactNode;
  dataTest: string;
}

const span = ({ as, children, dataTest }: H6Props) =>
  React.createElement(as, { dataTest }, children);

const StyledSpan = styled(span)``;

export const HeaderLink = ({
  as = 'span',
  children = null,
  className = '',
  name,
  maxLines = null,
}: HeaderLinkProps) => (
  <StyledSpan
    as={as}
    className={AttrsHelper.formatClassname(
      'header-link',
      maxLines && `max-lines-${maxLines}`,
      className
    )}
    data-test={name}
  >
    {children}
  </StyledSpan>
);
