import { shape, string, arrayOf, bool } from 'prop-types';
import { CookieUtil } from '@axiom/ui';

import { getLoggedUser } from '../api/user-login-api';
import { UsersApi } from '../api/users-api';
import GenericStore from '../classes/generic-store';
import { EnvUtil } from '../utils/env-util';

class UserStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      id: string,
      firstName: string,
      lastName: string,
      fullName: string,
      email: string,
      candidateId: string,
      roles: arrayOf(string),
      hasAcceptedTermsAndConditions: bool,
      termsAndConditionsAcceptedAt: string,
      contactId: string,
    });
  }

  load() {
    return this.setState(
      getLoggedUser().catch(() => {
        /**
         * If we get an error from fetching the logged in user, it probably means they have an
         * invalid token, because before we even get to this point, we've sanity checked for a
         * cookie and that it hasn't expired already
         */
        CookieUtil.clearUser(EnvUtil.cookieDomain);
        window.location.href = '/';
      })
    );
  }

  setAcceptTermsAndConditions(userId) {
    return this.watchState(
      UsersApi.updateUser({
        id: userId,
        termsAndConditionsAcceptedAt: new Date(),
      })
    );
  }
}

export const UserStore = new UserStoreClass();
