import { z } from 'zod';

import { ContactSchema } from './contacts';

export const AhqSubmissionContactSchema = ContactSchema.pick({
  email: true,
  firstName: true,
  fullName: true,
  id: true,
  lastName: true,
});

export type AhqSubmissionContact = z.infer<typeof AhqSubmissionContactSchema>;
