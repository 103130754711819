import { z } from 'zod';

import { AccountSchema } from './account';
import { ContactSchema } from './contacts';

export const UserContactSchema = ContactSchema.extend({
  accounts: z.array(AccountSchema).nullish(),
});

export type UserContact = z.infer<typeof UserContactSchema>;
