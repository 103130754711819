import { z } from 'zod';

import { OpportunitySchema } from './opportunity';
import { ZodCandidateOpportunityCandidateStatuses } from './candidate-opportunities';

export const OpportunityReduxSchema = OpportunitySchema.extend({
  opportunityId: z.string().uuid(),
  candidateStatus: ZodCandidateOpportunityCandidateStatuses.nullable(),
});

export type OpportunityRedux = z.infer<typeof OpportunityReduxSchema>;
