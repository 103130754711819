const eventStatusMap = {
  initialStatus: 'initialStatus',
  finalStatus: 'finalStatus',
  errorStatus: 'errorStatus',
} as const;

export const DailyCronSystemEventMap = {
  autoAlum: {
    type: 'autoAlum',
    name: 'autoAlum',
    ...eventStatusMap,
  },
  autoAlumReservoir: {
    type: 'autoAlumReservoir',
    name: 'autoAlumReservoir',
    ...eventStatusMap,
  },
  autoIdle: {
    type: 'autoIdle',
    name: 'autoIdle',
    ...eventStatusMap,
  },
  autoUpdateProfileStatus: {
    type: 'autoUpdateProfileStatus',
    name: 'autoUpdateProfileStatus',
    ...eventStatusMap,
  },
  deleteCandidatesSavedOpportunities: {
    type: 'deleteCandidatesSavedOpportunities',
    name: 'deleteCandidatesSavedOpportunities',
    ...eventStatusMap,
  },
  deleteExpiredTokens: {
    type: 'deleteExpiredTokens',
    name: 'deleteExpiredTokens',
    ...eventStatusMap,
  },
  updateTos: {
    type: 'updateTos',
    name: 'updateTos',
    ...eventStatusMap,
  },
} as const;

export const SystemEventMap = {
  // Include all the daily crons
  ...DailyCronSystemEventMap,

  reindex: {
    type: 'reindex',
    name: 'reindex',
    ...eventStatusMap,
  },
  dailyCron: {
    type: 'daily',
    name: 'dailyCron',
    ...eventStatusMap,
  },
  hydrating: {
    type: 'seeding',
    name: 'hydrate',
    ...eventStatusMap,
  },
  loadRecommended: {
    type: 'loadRecommended',
    name: 'candidates',
    gettingMLData: 'getting ML data',
    updatingES: 'Updating ES Nearest Neighbor',
    reindexingCandidates: 'Reindexing candidates',
    ...eventStatusMap,
  },
  endCandidateEngagements: {
    type: 'endCandidateEngagements',
    name: 'endCandidateEngagements',
    ...eventStatusMap,
  },
  recalculateIndustryYearsOfExperience: {
    type: 'calculation',
    name: 'recalculateIndustryYearsOfExperience',
    ...eventStatusMap,
  },
} as const;
