import React, { FormEvent } from 'react';
import { Formik, FormikValues } from 'formik';
import isObjectLike from 'lodash/isObjectLike';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';

import { FormSchemaUtil } from '../../../utils/form-schema-util';

import { RawForm } from './RawForm';
import { FormProps } from './form-types';

type GenericFormObjectType = { [key: string | number]: unknown };

export const isDifferent = (value: unknown, compareAgainst: unknown) => {
  if (isObjectLike(value) && isObjectLike(compareAgainst)) {
    try {
      return !isEqual(value, compareAgainst);
    } catch {
      return true;
    }
  }
  return value !== compareAgainst;
};

export const filterOutUnchangedData = (
  initialData?: unknown,
  finalData?: unknown
) => {
  if (!initialData || !isObjectLike(initialData)) {
    return finalData;
  }

  let allKeys = uniq(Object.keys(finalData as Record<string, unknown>));
  if (allKeys.length === 0 && Object.keys(initialData).length > 0) {
    allKeys = uniq(Object.keys(initialData));
  }
  return allKeys.reduce((acc: GenericFormObjectType, propName) => {
    if (
      (!has(initialData, propName) && has(finalData, propName)) ||
      (has(initialData, propName) &&
        isDifferent(get(finalData, propName), get(initialData, propName)))
    ) {
      acc[propName] = get(finalData, propName);
    }

    return acc;
  }, {});
};

export const Form = <FormValues extends FormikValues = FormikValues>({
  children,
  initialValues,
  name = 'Form',
  onSubmit,
  schema,
  submitOnChange,
}: FormProps<FormValues>) => {
  if (!initialValues) {
    if (FormSchemaUtil.isObjectSchema(schema)) {
      initialValues = {} as FormValues;
    } else if (FormSchemaUtil.isArraySchema(schema)) {
      initialValues = [] as unknown as FormValues;
    }
  }

  return (
    <Formik<FormValues>
      validate={values => {
        const { errors } = FormSchemaUtil.validate(schema, values);
        if (process.env.NODE_ENV === 'development' && errors) {
          // eslint-disable-next-line no-console
          console.log('(local only) Validation Info:', { errors, values });
        }
        return errors || {};
      }}
      // TODO: APCORE-2672 - Come back around and force initialValues to be passed in
      initialValues={initialValues || ({} as unknown as FormValues)}
      onSubmit={async (formData, actions) => {
        const { values } = FormSchemaUtil.validate(schema, formData);
        const changedData = filterOutUnchangedData(
          initialValues,
          values
        ) as FormValues;

        if (Object.keys(changedData).length === 0 && submitOnChange) {
          if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log(
              '(local only) Form submitted without any changes but its submitOnChange so consuming'
            );
          }
          /**
           * We're doing an instant resolve promise here so that the function we send into
           * Formik is always an async one, which will trigger some internal logic around
           * when it notifies us that the Form is in submitting mode.
           */
          await Promise.resolve();
          return;
        }

        return onSubmit?.(changedData, actions);
      }}
      enableReinitialize // allow form selection to update if initialValues change
    >
      {({
        dirty,
        handleReset,
        handleSubmit,
        isSubmitting,
        isValid,
        submitCount,
        setTouched,
        setFieldTouched,
        setValues,
        values,
      }) => {
        return (
          <RawForm
            dirty={dirty}
            handleReset={handleReset}
            handleSubmit={event =>
              handleSubmit(event as FormEvent<HTMLFormElement>)
            }
            isSubmitting={isSubmitting}
            isValid={isValid}
            name={name}
            schema={schema}
            setTouched={setTouched}
            setFieldTouched={setFieldTouched}
            setValues={setValues}
            submitCount={submitCount}
            submitOnChange={submitOnChange}
            values={values}
          >
            {children}
          </RawForm>
        );
      }}
    </Formik>
  );
};
