import {
  Candidate,
  AfcSubmissionPosition,
  AfcSubmissionCandidateOpportunity,
  AfcSubmission,
  AhqSubmissionCandidateOpportunity,
} from '@axiom/validation';
import { CurrencyUtil } from '@axiom/utils';
import { CandidateOpportunitiesConst, CurrencySymbolTypes } from '@axiom/const';

const { hourly, daily, weekly } =
  CandidateOpportunitiesConst.DisplayBillingRate;
const { DisplayBillingRate } = CandidateOpportunitiesConst;

const RateIncrement = {
  DAY: 'day',
  HOUR: 'hour',
  WEEK: 'week',
} as const;

// Create a type from the values
type RateIncrementType = (typeof RateIncrement)[keyof typeof RateIncrement];

const getRateCopy = ({
  currency,
  price,
  increment,
}: {
  currency: CurrencySymbolTypes | null;
  price: number;
  increment: RateIncrementType;
}) => {
  return `${currency ?? ''} ${CurrencyUtil.getFormattedCurrency(price)}/${increment}`.trim();
};

const getRate = ({
  proposedHourlyRate,
  displayBillingRate,
  billingHoursPerDay,
  currency,
}: {
  proposedHourlyRate?: number | null;
  displayBillingRate?:
    | (typeof DisplayBillingRate)[keyof typeof DisplayBillingRate]
    | null;
  billingHoursPerDay: number | null;
  currency: CurrencySymbolTypes | null;
}) => {
  let price = proposedHourlyRate;
  if (!price || price < 0) {
    return '--';
  }

  const hoursPerDay = billingHoursPerDay ?? 0;

  switch (displayBillingRate) {
    case hourly: {
      return getRateCopy({
        currency,
        price,
        increment: RateIncrement.HOUR,
      });
    }
    case daily: {
      if (hoursPerDay > 0) {
        price *= hoursPerDay;
        return getRateCopy({ currency, price, increment: RateIncrement.DAY });
      }
      break;
    }
    case weekly: {
      if (hoursPerDay > 0) {
        price *= hoursPerDay * 5;
        return getRateCopy({ currency, price, increment: RateIncrement.WEEK });
      }
      break;
    }
    default: {
      return '--';
    }
  }

  return '--';
};

export const CandidateRateUtil = {
  formatCandidateRateLegacy(
    candidate: AhqSubmissionCandidateOpportunity,
    currency: CurrencySymbolTypes | null
  ) {
    const { displayBillingRate, proposedHourlyRate, position } = candidate;

    return getRate({
      proposedHourlyRate,
      displayBillingRate,
      billingHoursPerDay: position?.billingHoursPerDay || null,
      currency,
    });
  },
  formatCandidateRate(
    candidateId: Candidate['id'],
    currency: CurrencySymbolTypes | null,
    submission: AfcSubmission
  ) {
    let displayBillingRate:
      | AfcSubmissionCandidateOpportunity['displayBillingRate']
      | null = null;
    let proposedHourlyRate:
      | AfcSubmissionCandidateOpportunity['proposedHourlyRate']
      | null = null;

    const position = submission.positions.find((pos: AfcSubmissionPosition) => {
      const data = pos.candidateOpportunities.find(
        (co: AfcSubmissionCandidateOpportunity) =>
          co.candidate.id === candidateId
      );
      if (data) {
        displayBillingRate = data.displayBillingRate;
        proposedHourlyRate = data.proposedHourlyRate;
      }
      return !!data;
    });
    const billingHoursPerDay = position?.billingHoursPerDay || null;

    return getRate({
      proposedHourlyRate,
      displayBillingRate,
      billingHoursPerDay,
      currency,
    });
  },
  shouldDisplayRate(candidateOpportunity: AfcSubmissionCandidateOpportunity) {
    return !!(
      candidateOpportunity.displayBillingRate &&
      candidateOpportunity.displayBillingRate !==
        CandidateOpportunitiesConst.DisplayBillingRate.none &&
      candidateOpportunity.proposedHourlyRate
    );
  },
};
