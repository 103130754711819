import React from 'react';
import {
  AfcSubmissionCandidate,
  PracticeArea,
  Taxonomy,
} from '@axiom/validation';
import { LawFirmConst } from '@axiom/const';
import {
  CandidateIndustryModel,
  SubmissionCandidateModel,
} from '@axiom/ui-models';

import { Divider } from '../../element/Divider/Divider';
import { Grid } from '../../layout/Grid/Grid';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { GridRow } from '../../layout/Grid/GridRow';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';
import { PracticeAreaUtil } from '../../../utils/practice-area-util';

export type CandidateHighlightExperienceDetailsTypes = {
  candidate: AfcSubmissionCandidate;
  practiceAreas: PracticeArea[];
  taxonomy: Taxonomy;
};

export const CandidateHighlightExperienceDetails = ({
  candidate,
  practiceAreas,
  taxonomy,
}: CandidateHighlightExperienceDetailsTypes) => {
  const filteredLawFirms = [
    ...new Set(candidate?.lawFirms?.map(item => item.client)),
  ];

  const isOnlyAxiomAdiceAndCounsel =
    filteredLawFirms.length === 1 &&
    filteredLawFirms[0] === LawFirmConst.Firms.axiomAdviceAndCounsel;

  const practiceAreaAndSpecialty = PracticeAreaUtil.getPracticeAreaAndSpecialty(
    practiceAreas,
    candidate?.practiceArea?.id
  );

  const candidateAddress = new SubmissionCandidateModel({
    candidate,
  }).calculatedAddress();

  const formattedPracticeAreaAndSpecialty =
    PracticeAreaUtil.displayPracticeAreaAndSpecialty(practiceAreaAndSpecialty);
  return (
    <Grid name="highlights-experience-details">
      {(candidate?.industries?.length ?? 0) > 0 && (
        <GridRow gutterBottom="16px" name="hightlight-industries-section">
          <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
            <ParagraphHeader>Industry</ParagraphHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
            <Paragraph name="highlight-industries">
              <Divider>
                {new CandidateIndustryModel(candidate.industries)
                  .formatIndustries()
                  .map(industry => {
                    const yoe = Math.floor(+industry.yearsOfExperience);
                    return (
                      <span key={`industry-${industry.industryValue}`}>
                        {
                          taxonomy.industries?.[industry.industryValue]
                            ?.displayValue
                        }{' '}
                        {yoe < 1 ? `(< 1 yr.)` : `(${yoe}+ yrs.)`}
                      </span>
                    );
                  })}
              </Divider>
            </Paragraph>
          </GridColumn>
        </GridRow>
      )}
      {!!formattedPracticeAreaAndSpecialty && (
        <GridRow gutterBottom="16px" name="highlight-practicearea-section">
          <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
            <ParagraphHeader>Practice Area</ParagraphHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
            <Paragraph name="highlight-practicearea">
              {formattedPracticeAreaAndSpecialty}
            </Paragraph>
          </GridColumn>
        </GridRow>
      )}
      {filteredLawFirms.length > 0 && !isOnlyAxiomAdiceAndCounsel && (
        <GridRow gutterBottom="16px" name="hightlight-lawfirms-section">
          <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
            <ParagraphHeader>Law Firms</ParagraphHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
            <Paragraph name="highlight-lawfirms">
              <Divider>
                {filteredLawFirms.map(client => (
                  <span key={`firm-${client}`}>{client}</span>
                ))}
              </Divider>
            </Paragraph>
          </GridColumn>
        </GridRow>
      )}
      {!!candidateAddress && (
        <GridRow gutterBottom="16px" name="highlight-location-section">
          <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
            <ParagraphHeader>Location</ParagraphHeader>
          </GridColumn>
          <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
            <Paragraph name="highlight-location">{candidateAddress}</Paragraph>
          </GridColumn>
        </GridRow>
      )}
    </Grid>
  );
};
