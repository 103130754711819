export const LANGUAGE_SKILLS_KEYS = {
  SPEAKING: 'speaking',
  READING: 'reading',
  WRITING: 'writing',
} as const;

export const LANGUAGE_PROFICIENCIES_KEYS = {
  BASIC: 'basic',
  PROFICIENT: 'proficient',
  FLUENT: 'fluent',
} as const;

const LANGUAGE_CEFR_LEVELS = {
  A1: 'A1',
  A2: 'A2',
  B1: 'B1',
  B2: 'B2',
  C1: 'C1',
  C2: 'C2',
} as const;

export const LanguageConst = {
  LANGUAGE_PROFICIENCIES: {
    [LANGUAGE_PROFICIENCIES_KEYS.BASIC]: 'Conversational',
    [LANGUAGE_PROFICIENCIES_KEYS.PROFICIENT]: 'Business Proficient',
    [LANGUAGE_PROFICIENCIES_KEYS.FLUENT]: 'Native or Bilingual',
  },
  LANGUAGE_SKILLS: {
    [LANGUAGE_SKILLS_KEYS.SPEAKING]: 'Speaking',
    [LANGUAGE_SKILLS_KEYS.READING]: 'Reading',
    [LANGUAGE_SKILLS_KEYS.WRITING]: 'Writing',
  },
  LANGUAGE_CEFR_LEVELS,
  LanguageProficienciesOptions: [
    {
      label: 'Conversational',
      value: LANGUAGE_PROFICIENCIES_KEYS.BASIC,
    },
    {
      label: 'Business Proficient',
      value: LANGUAGE_PROFICIENCIES_KEYS.PROFICIENT,
    },
    {
      label: 'Native or Bilingual',
      value: LANGUAGE_PROFICIENCIES_KEYS.FLUENT,
    },
  ],
} as const;
