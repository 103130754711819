import { z } from 'zod';

import { CandidateSchema } from './candidate';
import { CandidateOpportunitySchema } from './candidate-opportunities';

export const CandidateReduxSchema = CandidateOpportunitySchema.merge(
  CandidateSchema
).extend({
  candidateId: z.string().uuid(),
  candidateOpportunities: z.array(CandidateOpportunitySchema),
});

export type CandidateRedux = z.infer<typeof CandidateReduxSchema>;
