import { z } from 'zod';
import { PositionsConst } from '@axiom/const';
import { SchemaDate, SchemaLocation, ZodArrayToEnum } from '@axiom/types';

export const licensedLawyerValues = {
  Myself: 'Myself',
  SomeoneElse: 'Someone else',
} as const;

export const occupationTypeValues = {
  Lawyer: 'Lawyer',
  'Legal Support': 'Legal support',
} as const;

export const levelOfExperienceValues = {
  ZeroYears: '0+ years',
  FourYears: '4+ years',
  EightYears: '8+ years',
  TwelveYears: '12+ years',
  FifteenYears: '15+ years',
} as const;

export const levelOfExperienceKeys = {
  ZeroYears: 'ZeroYears',
  FourYears: 'FourYears',
  EightYears: 'EightYears',
  TwelveYears: 'TwelveYears',
  FifteenYears: 'FifteenYears',
} as const;

export const legalTeamSizeValues = {
  One: '1',
  TwoToFive: '2-5',
  SixToTen: '6-10',
  ElevenToTwenty: '11-20',
  TwentyOneToFortyNine: '21-49',
  FiftyPlus: '50+',
} as const;

export const positionReservedTypeValues = {
  Reserved: 'Reserved',
  'Not Reserved': 'Not Reserved',
} as const;

const licensedLawyerAllowedValues = Object.keys(licensedLawyerValues) as Array<
  keyof typeof licensedLawyerValues
>;

const levelOfExperienceAllowedValues = Object.keys(
  levelOfExperienceValues
) as Array<keyof typeof levelOfExperienceValues>;

const occupationTypeAllowedValues = Object.keys(occupationTypeValues) as Array<
  keyof typeof occupationTypeValues
>;

const reservedTypeAllowedValues = Object.keys(
  positionReservedTypeValues
) as Array<keyof typeof positionReservedTypeValues>;

const legalTeamSizeAllowedValues = Object.values(legalTeamSizeValues);

const worksiteAllowedValues = Object.values(PositionsConst.Worksites);

export const AccountIntakeSchema = z.object({
  accountId: z.string().uuid(),
  additionalDetailsForTalent: z.string().nullish(),
  addressState: z.string().nullish(),
  addressCity: z.string().nullish(),
  addressZip: z.string().nullish(),
  addressStreet: z.string().nullish(),
  billingUnitsPerWeek: z.number(),
  billingUnitsPerDay: z.number(),
  companyLocation: SchemaLocation.nullish(),
  endDate: SchemaDate,
  generalSkills: z.array(z.string()).nullish(),
  industry: z.string().nullish(),
  languageId: z.string().uuid().nullish(),
  legalSkills: z.array(z.string()).nullish(),
  legalTeamSize: z.enum(ZodArrayToEnum(legalTeamSizeAllowedValues)).nullish(),
  legalTechSkills: z.array(z.string()).nullish(),
  levelOfExperience: z.enum(ZodArrayToEnum(levelOfExperienceAllowedValues)),
  licensedLawyer: z.enum(ZodArrayToEnum(licensedLawyerAllowedValues)),
  licensedLawyerState: z.string(),
  maxBudgetPerHour: z.number(),
  minBudgetPerHour: z.number(),
  occupationType: z.enum(ZodArrayToEnum(occupationTypeAllowedValues)),
  opportunityId: z.string().uuid(),
  otherFocusAreas: z.array(z.string()).nullish(),
  practiceArea: z.string(),
  primaryFocusArea: z.string(),
  reservedType: z.enum(ZodArrayToEnum(reservedTypeAllowedValues)),
  startDate: SchemaDate,
  urgency: z.string().nullish(),
  worksite: z.enum(ZodArrayToEnum(worksiteAllowedValues)),
});
export type AccountIntake = z.infer<typeof AccountIntakeSchema>;
