import { z } from 'zod';

import { AfcSubmissionSchema } from './afc-submission';
import { AfcSubmissionPublicPositionSchema } from './afc-submission-public-position';

export const AfcSubmissionPublicSchema = AfcSubmissionSchema.pick({
  accountName: true,
  accountType: true,
  confidential: true,
  currency: true,
  isQualificationComplete: true,
  jobName: true,
  practiceArea: true,
  projectState: true,
  selfService: true,
  submissionLastUpdatedAt: true,
}).extend({
  positions: z.array(AfcSubmissionPublicPositionSchema),
});

export type AfcSubmissionPublic = z.infer<typeof AfcSubmissionPublicSchema>;
