import React, { useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { FormFieldProps } from 'semantic-ui-react';
import { CustomSchemaTypes, SchemaLocation } from '@axiom/types';
import { z } from 'zod';

import { FormField } from '../FormField/FormField';
import { useGoogleMaps } from '../../../hooks/useGoogleMaps';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import {
  RawDropdown,
  RawDropdownProps,
  RawDropdownValueType,
} from '../Dropdown/RawDropdown';
import { DesignSysEnvUtil } from '../../../utils/design-sys-env-util';

export type SchemaLocation = z.infer<typeof SchemaLocation> | null;
export type LocationProps = {
  description?: string;
  disabled?: RawDropdownProps['disabled'];
  id?: RawDropdownProps['id'];
  label?: React.ReactNode;
  name: RawDropdownProps['name'];
  onBlur?: (value?: SchemaLocation) => void;
  onChange?: (value?: SchemaLocation) => void;
  onFocus?: (value?: SchemaLocation) => void;
  placeholder?: RawDropdownProps['placeholder'];
  showMap?: boolean;
  Tooltip?: React.ReactNode;
};

const noopFn = () => {};

export const Location = ({
  description,
  disabled,
  id,
  label,
  name,
  onBlur = noopFn,
  onChange = noopFn,
  onFocus = noopFn,
  placeholder,
  showMap,
  Tooltip,
}: LocationProps) => {
  const [mapState, setMapState] = useState<{
    isFocused?: boolean;
    predictions: Array<SchemaLocation>;
    valueChanged?: boolean;
  }>({
    isFocused: false,
    predictions: [],
    valueChanged: false,
  });
  const mapRef = useRef<HTMLDivElement | null>(null);
  const googleApiService = useGoogleMaps({
    googleApiUrl: DesignSysEnvUtil.googleMapsApi,
    mapRef,
  });
  id = id || name;
  const inValue = (location?: SchemaLocation): RawDropdownValueType =>
    location?.locationPlaceId || '';

  const searchPlace = (v: string) => {
    googleApiService.requests
      .getPlaceSuggestions(v)
      .then(predictionLocations => {
        setMapState({
          ...mapState,
          predictions: predictionLocations,
        });
      });
  };
  const debouncedTypeHandler = useMemo(() => debounce(searchPlace, 300), []);

  return (
    <div
      data-test={`${name}-location-wrapper`}
      className={AttrsHelper.formatClassname('location-dropdown')}
    >
      <FormField
        {...{
          description,
          disabled,
          icon: 'waypoint',
          id,
          name,
          label,
          onClear: () => {
            googleApiService.requests.clear();
            onChange(null);
          },
          Tooltip,
        }}
        renderField={({
          value,
          onBlur: formOnBlur,
          setFieldValue,
          invalid,
          schemaProperty,
        }: FormFieldProps) => {
          if (schemaProperty.type !== CustomSchemaTypes.SchemaLocation) {
            throw new Error(
              'You must provide a SchemaLocation schema type for the LocationTypeahead'
            );
          }

          return (
            <RawDropdown
              displayKey="locationName"
              disabled={disabled}
              hideIcon
              id={id}
              invalid={invalid}
              name={name}
              onBlur={e => {
                setMapState({
                  ...mapState,
                  isFocused: false,
                });
                onBlur(value);
                formOnBlur({ ...e, target: { ...e.target, name } });
              }}
              // @ts-expect-error Needs new type
              onChange={async (v: string) => {
                const placeDetails =
                  await googleApiService.requests.getPlaceDetails(v);
                onChange(placeDetails);
                setFieldValue(name, placeDetails);

                setMapState({
                  ...mapState,
                  valueChanged: true,
                });
              }}
              // @ts-expect-error Needs new type
              onType={debouncedTypeHandler}
              onFocus={() => {
                setMapState({
                  ...mapState,
                  isFocused: true,
                });
                onFocus();
              }}
              options={
                value && mapState.predictions.length === 0
                  ? [value]
                  : mapState.predictions
              }
              placeholder={placeholder}
              value={inValue(value)}
              valueKey="locationPlaceId"
            />
          );
        }}
      />
      <div
        ref={mapRef}
        data-test={`${name}-location-map`}
        className={AttrsHelper.formatClassname(
          'location-dropdown-map',
          (showMap || mapState.isFocused || mapState.valueChanged) && 'show-map'
        )}
      />
    </div>
  );
};
