import React from 'react';
import { GridRow as SGridRow } from 'semantic-ui-react';

import {
  ResponsiveColumnOptions,
  ResponsiveConfig,
  StringifiedResponsiveColumnOptions,
  ViewportName,
} from '../../../configs/responsive-config';

type ElementType = 'div' | 'span';
type TextAlignType = 'left' | 'center' | 'right' | 'justified';
type VerticalAlignType = 'top' | 'middle' | 'bottom';

export interface GridRowProps {
  as?: ElementType;
  children?: React.ReactNode;
  centered?: boolean;
  className?: string;
  columns?: StringifiedResponsiveColumnOptions | ResponsiveColumnOptions;
  gutterTop?: string;
  gutterBottom?: string;
  gutterVertical?: string;
  name?: string;
  only?: string | ViewportName[];
  stretched?: boolean;
  textAlign?: TextAlignType;
  verticalAlign?: VerticalAlignType;
}

const defaultOnly: ViewportName[] = [];

export const GridRow = ({
  children,
  as = 'div',
  centered,
  className,
  columns,
  gutterTop,
  gutterBottom,
  gutterVertical,
  name,
  only = defaultOnly,
  stretched,
  textAlign,
  verticalAlign,
}: GridRowProps) => {
  const appendStyles: {
    paddingTop?: string | null;
    paddingBottom?: string | null;
  } = {
    paddingTop: null,
    paddingBottom: null,
  };

  if (gutterTop || gutterVertical) {
    appendStyles.paddingTop = gutterTop || gutterVertical;
  }

  if (gutterBottom || gutterVertical) {
    appendStyles.paddingBottom = gutterBottom || gutterVertical;
  }

  return React.createElement(
    SGridRow,
    {
      as,
      centered,
      'data-test': name,
      className,
      columns,
      style: appendStyles,
      only: ResponsiveConfig.convertOnlyToSemanticOnly(only) || undefined,
      stretched,
      textAlign,
      verticalAlign,
    },
    children
  );
};
