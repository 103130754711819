import { TokenConst } from '@axiom/const';

import { enumValidatorCreator } from './general';

const {
  ViewBioNoPasswordLogin,
  ViewBenchNoPasswordLogin,
  BenchUrlNoPasswordLogin,
} = TokenConst.statementActions;
const actionTypeWhitelist = [
  ViewBioNoPasswordLogin,
  ViewBenchNoPasswordLogin,
  BenchUrlNoPasswordLogin,
];

export const tokenActionValidator = enumValidatorCreator(actionTypeWhitelist);
