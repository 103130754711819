import { Enum } from 'enumify';

const opportunityFreeFilterColumns = [
  'amount',
  'jobName',
  'requiredNumResources',
] as const;

const VALIDATE = '0B - Validate';
const DEVELOP = '0A - Develop';
const CONTRACT = '3 - Contract';
const COMPETE = '2 - Compete';
const CRAFT = '1 - Craft';
const CLOSED_WON = 'Closed Won';
const CLOSED_LOST = 'Closed Lost';

const Stages = {
  // Note: DEVELOP isn't really used.
  DEVELOP,
  VALIDATE,
  CRAFT,
  COMPETE,
  CONTRACT,
  CLOSED_LOST,
  CLOSED_WON,
} as const;

export const Opportunity = {
  FREE_FILTER_COLUMNS: opportunityFreeFilterColumns,
  FILTER_COLUMNS: [
    ...opportunityFreeFilterColumns,
    'accountName',
    'billingCurrency',
    'competitorType',
    'competitorView',
    'cultureSkills',
    'currency',
    'description',
    'engagementType',
    'forecastCategory',
    'jobName',
    'matterId',
    'nextStep',
    'offering',
    'practiceAreaId',
    'reasonLost',
    'salesLeadId',
    'salesNote',
    'salesforceId',
    'stage',
    'useCase',
  ],
  SORT_COLUMNS: [
    'relevance',
    'jobName',
    'accountName',
    'clientName',
    'salesCloseDate',
    'stage',
    'stageCode',
    'positions',
    'practiceAreaId',
    'createdAt',
  ],
  SORT_COLUMNS_NAMES_MAP: {
    relevance: 'Relevance',
    jobName: 'Opportunity',
    accountName: 'Account Name',
    clientName: 'Client Name',
    salesCloseDate: 'Close Date',
    stage: 'Status',
    stageCode: 'Stage',
    positions: 'Positions',
    practiceAreaId: 'Practice Area',
    createdAt: 'Created At',
  },
  CLOSED_LOST,
  CLOSED_WON,
  COMPETE,
  CONTRACT,
  CRAFT,
  DEVELOP,
  VALIDATE,
  Stages,
  STAGES: [
    // Note: DEVELOP isn't really used.
    DEVELOP,
    VALIDATE,
    CRAFT,
    COMPETE,
    CONTRACT,
    CLOSED_LOST,
    CLOSED_WON,
  ],
  STAGE_CODE_MAP: {
    // Note: any existing in DEVELOP map to 0 as default.
    [DEVELOP]: '0',
    [VALIDATE]: '0',
    [CRAFT]: '1',
    [COMPETE]: '2',
    [CONTRACT]: '3',
    [CLOSED_WON]: 'W',
    [CLOSED_LOST]: 'L',
  },

  /** field names */
  ACCOUNT_NAME: 'accountName',
  ACCOUNT_SALESFORCE_ID: 'accountSalesforceId',
  ADDRESS_CITY: 'addressCity',
  ADDRESS_COUNTRY_CODE: 'addressCountryCode',
  ADDRESS_STATE: 'addressState',
  ADDRESS_STREET: 'addressStreet',
  ADDRESS_ZIP: 'addressZip',
  AMOUNT: 'amount',
  BILLING_CURRENCY: 'billingCurrency',
  BILLING_ESTIMATED_UNITS: 'billingEstimatedUnits',
  BILLING_NAME: 'billingName',
  BUSINESS_TEAM: 'businessTeam',
  CLIENT_BUDGET_CONFIRMED: 'clientBudgetConfirmed',
  CLIENT_BUDGET_MAX: 'clientBudgetMax',
  CLIENT_BUDGET_MIN: 'clientBudgetMin',
  CLOSED_LOST_NOTES: 'closedLostNotes',
  COLLABORATOR_ID: 'collaboratorId',
  COMPANY_ID: 'companyId',
  COMPETITOR_TYPE: 'competitorType',
  COMPETITOR_VIEW: 'competitorView',
  CREATED_AT: 'createdAt',
  CULTURE_SKILLS: 'cultureSkills',
  CURRENCY: 'currency',
  DESCRIPTION: 'description',
  DRIVING_NEED: 'drivingNeed',
  END_DATE: 'contractedEndDate',
  ENGAGEMENT_TYPE: 'engagementType',
  FIRST_CANDIDATE_SUBMITTED_AT: 'firstCandidateSubmittedAt',
  FIRST_CANDIDATE_SELECTED_OR_FURTHER_AT: 'firstCandidateSelectedOrFurtherAt',
  FORECAST_CATEGORY: 'forecastCategory',
  ID: 'id',
  INADEQUATE_SUPPLY_CATEGORY: 'inadequateSupplyCategory',
  IS_REMOTE: 'isRemote',
  JOB_NAME: 'jobName',
  LANGUAGES: 'languages',
  LAST_UPDATED_BY: 'lastUpdatedBy',
  MATTER_ID: 'matterId',
  NEXT_STEP: 'nextStep',
  OFFERING: 'offering',
  WORK_LOCATION: 'workLocation',
  OPPORTUNITY_LOST_COVERAGE_PLAN: 'opportunityLostCoveragePlan',
  OPPORTUNITY_OWNER_SALESFORCE_ID: 'opportunityOwnerSalesforceId',
  OWNER_USER_ID: 'ownerUserId',
  PRACTICE_AREA_ID: 'practiceAreaId',
  QUALIFIED_AT: 'qualifiedAt',
  REASON_LOST: 'reasonLost',
  REGION: 'region',
  REQUIRED_NUM_RESOURCES: 'requiredNumResources',
  SALES_CLOSE_DATE: 'salesCloseDate',
  SALES_CX_LEAD_ID: 'salesforceCxLeadId',
  SALES_LEAD_ID: 'salesLeadId',
  SALES_NOTE: 'salesNote',
  SALESFORCE_ACCOUNT_ID: 'salesforceAccountId',
  SALESFORCE_ID: 'salesforceId',
  STAGE: 'stage',
  START_DATE: 'startDate',
  STATUS_UPDATED_AT: 'statusUpdatedAt',
  REQUIRED_TALENT_COUNTRY_CODE: 'requiredTalentCountryCode',
  UPDATED_AT: 'updatedAt',
  USE_CASE: 'useCase',
} as const;

class FulfillmentStatuses extends Enum {}
FulfillmentStatuses.initEnum({
  FulfillmentNeeded: { v: 'Fulfillment Needed' },
  FulfillmentComplete: { v: 'Fulfillment Complete' },
  RescopeNeeded: { v: 'Rescope Needed' },
});
FulfillmentStatuses.v = v =>
  FulfillmentStatuses.enumValues.find(x => x.v === v) as { v: string };

const WorkLocation = {
  Onsite: 'Onsite',
  Remote: 'Remote',
  Hybrid: 'Hybrid',
  NoPreference: 'No Preference',
} as const;

export type WorkLocationKey = keyof typeof WorkLocation;

const WorkLocationKeys = Object.keys(WorkLocation).reduce(
  (acc, v): Record<WorkLocationKey, WorkLocationKey> => ({
    ...acc,
    [v]: v,
  }),
  {} as Record<WorkLocationKey, WorkLocationKey>
);

const TransactionSubType = {
  Backfill: 'Backfill',
  AdditionalResource: 'AdditionalResource',
  EarlyRollOff: 'EarlyRollOff',
  UtilizationChange: 'UtilizationChange',
  NewDeal: 'NewDeal',
} as const;

const ClientBudgetConfirmed = {
  Yes: 'Yes',
  No: 'No',
  NoButPricingShared: 'No, but Axiom Pricing has been shared',
} as const;

const DrivingNeed = [
  'Axiom Talent Roll-Through',
  'Axiom Team Expansion',
  'Fixed Timeline Project',
  'Gap Fill, Leave Cover',
  'Overflow/Surge Support',
  'Shift in Work or Competitor Displacement',
  'Specialist Advice',
] as const;

const OpportunityLostCoveragePlan = [
  'Big Four',
  'Enterprise Legal Services',
  'Law Firm',
  'Law Firm ALSP',
  'Legal Flexible Talent Specialist',
  'Other',
  'Staffing Agency',
  'Unknown',
] as const;

const BusinessTeams = {
  'Commercial AA&C': 'Commercial AA&C',
  'Germany Sales': 'Germany Sales',
  'Hong Kong Sales': 'Hong Kong Sales',
  'NA CS Sales': 'NA CS Sales',
  'NA FS Sales': 'NA FS Sales',
  'NA Industrials Sales': 'NA Industrials Sales',
  'NA LS Sales': 'NA LS Sales',
  'NA SMB Sales': 'NA SMB Sales',
  'NA Tech Sales': 'NA Tech Sales',
  'Singapore Sales': 'Singapore Sales',
  Switzerland: 'Switzerland',
  'TP Australia': 'TP Australia',
  'UK Sales': 'UK Sales',
} as const;

export const OpportunitiesConst = {
  WorkLocation,
  WorkLocationKeys,
  TransactionSubType,
  ClientBudgetConfirmed,
  BusinessTeams,
  OpportunityLostCoveragePlan,
  DrivingNeed,
  Solutions: {
    SingleSecondee: 'Single Secondee',
    TeamSolution: 'Team Solution',
    Offering: 'Offering',
  },
  BillingTypes: {
    Variable: 'Variable',
    ReservedHourly: 'Reserved Hourly',
    ReservedDaily: 'Reserved Daily',
  },
  Stages: {
    Validate: VALIDATE,
    Develop: DEVELOP,
    Contract: CONTRACT,
    Compete: COMPETE,
    Craft: CRAFT,
    ClosedWon: CLOSED_WON,
    ClosedLost: CLOSED_LOST,
  },
  StageCodes: [
    Opportunity.STAGE_CODE_MAP[VALIDATE],
    Opportunity.STAGE_CODE_MAP[CRAFT],
    Opportunity.STAGE_CODE_MAP[COMPETE],
    Opportunity.STAGE_CODE_MAP[CONTRACT],
    Opportunity.STAGE_CODE_MAP[CLOSED_LOST],
    Opportunity.STAGE_CODE_MAP[CLOSED_WON],
  ],
  FulfillmentStatuses,
  Expansions: {
    mlRecommendationsInfo: 'mlRecommendationsInfo',
    opportunityES: 'opportunityES',
  },
  ErrorText: {
    DisallowedStatusTransition: 'Disallowed Status Transition',
  },
  ProjectStates: {
    Active: 'Active',
    Submitted: 'Submitted',
  },
} as const;

export const JOB_PRACTICE_AREAS = {
  100000: 'Asset Management',
  100005: 'Banking & Finance - Commercial',
  100010: 'Banking & Finance - Retail',
  100015: 'Commercial Contracts',
  100020: 'Corporate & Securities',
  100025: 'Derivatives',
  100030: 'Intellectual Property',
  100035: 'Labor & Employment',
  100040: 'Life Sciences',
  100045: 'Litigation',
  100050: 'Mergers & Acquisitions',
  100055: 'Privacy',
  100060: 'Real Estate',
  100065: 'Regulatory & Compliance - FS',
  100070: 'Regulatory & Compliance - LS',
  100075: 'Regulatory & Compliance - Other',
  100080: 'Technology',
} as const;
