import Ajv from 'ajv';
import { z } from 'zod';
import { SchemaTimestamp, ZodArrayToEnum } from '@axiom/types';

import { OpportunityStageCodes } from './opportunity';
import { axiomValidationOptions } from './options';

const ajv = new Ajv(axiomValidationOptions());
const setOfStageCodes = new Set(OpportunityStageCodes);
export const OpportunitySavedSearchStageCodes = [...setOfStageCodes];

export const OpportunitySavedSearchSearchSchema = z.object({
  stageCode: z
    .array(z.enum(ZodArrayToEnum(OpportunitySavedSearchStageCodes)).nullable())
    .nullish(),
});
export const OpportunitySavedSearchSchema = z.object({
  createdAt: SchemaTimestamp,
  id: z.string().uuid(),
  name: z.string().nullable(),
  search: OpportunitySavedSearchSearchSchema.nullable(),
  type: z.string().nullable(),
  updatedAt: SchemaTimestamp.nullish(),
  userId: z.string().uuid().nullable(),
});

export type OpportunitySavedSearch = z.infer<
  typeof OpportunitySavedSearchSchema
>;

const opportunitySavedSearchValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    id: {
      type: 'string',
      format: 'uuid',
    },
    name: {
      type: 'string',
    },
    search: {
      type: 'object',
      properties: {
        stageCode: {
          type: 'array',
          items: {
            enum: OpportunitySavedSearchStageCodes,
          },
        },
      },
    },
    type: {
      type: 'string',
    },
    userId: {
      type: 'string',
      format: 'uuid',
    },
  },
};

export const opportunitySavedSearchValidator = ajv.compile({
  ...opportunitySavedSearchValidation,
});
