import { z } from 'zod';

import { CandidateOpportunitySchema } from './candidate-opportunities';
import { PositionSchema } from './position';

export const OpportunityPositionSchema = PositionSchema.extend({
  candidates: z.array(CandidateOpportunitySchema),
});

export type OpportunityPosition = z.infer<typeof OpportunityPositionSchema>;
