import { z } from 'zod';
import { SchemaTimestamp, ZodArrayToEnum } from '@axiom/types';
import {
  CandidateOppFeedAlertsConst,
  CountryCodesConst,
  EngagementLengthConst,
  PositionsConst,
  TimeCommitment,
} from '@axiom/const';

const { CountryCodes } = CountryCodesConst;
const { CalculatedTalentTypes, Worksites } = PositionsConst;
const { EngagementLengths } = EngagementLengthConst;
const { Frequency } = CandidateOppFeedAlertsConst;

export const CandidateAlertWorkFeedEngagementLengths =
  Object.values(EngagementLengths);

export const CandidateAlertWorkFeedCalculatedTalentTypes = Object.values(
  CalculatedTalentTypes
);

export const CandidateAlertWorkFeedTimeCommitments = Object.values(
  TimeCommitment.HoursPerWeek
);

export const CandidateAlertWorkFeedWorksites = Object.values(Worksites);

export const CandidateAlertWorkFeedCountryCodes = Object.keys(
  CountryCodes
) as Array<keyof typeof CountryCodes>;

export const CandidateAlertWorkFeedFrequencies = Object.values(Frequency);

export const CandidateAlertWorkFeedSchema = z.object({
  alertName: z.string().max(255),
  countries: z
    .array(z.enum(ZodArrayToEnum(CandidateAlertWorkFeedCountryCodes)))
    .nullish(),
  createdAt: SchemaTimestamp.nullable(),
  engagementLengths: z
    .array(z.enum(ZodArrayToEnum(CandidateAlertWorkFeedEngagementLengths)))
    .nullish(),
  frequency: z.enum(ZodArrayToEnum(CandidateAlertWorkFeedFrequencies)),
  id: z.string().uuid().nullish(),
  industries: z.array(z.string()).nullish(),
  legalRoles: z
    .array(z.enum(ZodArrayToEnum(CandidateAlertWorkFeedCalculatedTalentTypes)))
    .nullish(),
  practiceAreas: z.array(z.string().uuid()).nullish(),
  updatedAt: SchemaTimestamp.nullable(),
  weeklyCommitments: z
    .array(z.enum(ZodArrayToEnum(CandidateAlertWorkFeedTimeCommitments)))
    .nullish(),
  worksites: z
    .array(z.enum(ZodArrayToEnum(CandidateAlertWorkFeedWorksites)))
    .nullish(),
});

export type CandidateAlertWorkFeed = z.infer<
  typeof CandidateAlertWorkFeedSchema
>;
