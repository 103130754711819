import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  convertToRaw,
  convertFromRaw,
  EditorState,
  RichUtils,
  DraftEditorCommand,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import ItalicIcon from '../../../assets/rte-icons/icon-italic.svg?url';
import UnderlineIcon from '../../../assets/rte-icons/icon-underline.svg?url';
import StrikethroughIcon from '../../../assets/rte-icons/icon-strike-through.svg?url';
import BulletPointIcon from '../../../assets/rte-icons/icon-bullet-point.svg?url';
import LeftAlignIcon from '../../../assets/rte-icons/icon-left-align.svg?url';
import CenterAlignIcon from '../../../assets/rte-icons/icon-center-align.svg?url';
import RightAlignIcon from '../../../assets/rte-icons/icon-right-align.svg?url';
import BoldIcon from '../../../assets/rte-icons/icon-bold.svg?url';

import { TextareaReplacementUtil } from './textarea-replacement-util';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const TextareaWrapper = styled.div``;

export type RawTextareaValueType = string | null;

export type RawTextareaProps = {
  className?: string;
  disabled?: boolean;
  full?: boolean;
  id?: string;
  invalid?: boolean;
  name: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (value: RawTextareaValueType) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readonly?: boolean;
  stretched?: boolean;
  value?: RawTextareaValueType;
};

export const RawTextarea = ({
  className = '',
  disabled = false,
  full = false,
  id,
  invalid = false,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  readonly,
  stretched,
  value = '',
}: RawTextareaProps) => {
  let convertedValue: string | null | undefined = value;
  /**
   * Keeps it from flop-toggling between an uncontrolled/controlled component
   */
  if (convertedValue === null) {
    convertedValue = '';
  }

  /**
   * This and the outFormat regex are for supporting the old RTE syntax so that if we
   * use this new component but display with the old component or have this load syntax
   * from the old RTE markdown in the db, it'll come in and go back out as the old format.
   *
   * When the old RTE is completely removed from all apps, we should strip this out
   * and do a db update to correct the Markdown format holistically. But it's not
   * critical to do right away.
   */
  convertedValue = new TextareaReplacementUtil(convertedValue)
    .replacesOneNewLineWithTwo()
    .getText();

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      convertFromRaw(
        markdownToDraft(convertedValue, { preserveNewlines: true })
      )
    )
  );

  /**
   * Clean up empty bullet point list
   * Convert to null if the textarea is an empty string
   */
  const outFormat = (v?: string | null) => {
    if (!v) return null;

    return new TextareaReplacementUtil(v)
      .replaceEmptyBulletListItem()
      .replacesTwoNewLinesWithOne()
      .replaceFourOrMoreStartingSpaces()
      .getText();
  };

  useEffect(() => {
    const markdown = draftToMarkdown(
      convertToRaw(editorState.getCurrentContent()),
      { preserveNewlines: true }
    );
    const convertedMarkdown = outFormat(markdown) ?? '';
    const convertedUseEffectValue = value === null ? '' : value;

    if (convertedUseEffectValue !== convertedMarkdown) {
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(
            markdownToDraft(convertedValue, { preserveNewlines: true })
          )
        )
      );
    }
  }, [convertedValue]);

  const toolbar = {
    options: ['list'],
    inline: {
      // Underline doesn't make a markdown prop so disabling for now and product doesn't want the rest
      options: ['bold', 'italic', 'underline', 'strikethrough'],
      bold: {
        icon: BoldIcon,
        className: 'custom-bold',
      },
      italic: {
        icon: ItalicIcon,
      },
      underline: {
        icon: UnderlineIcon,
      },
      strikethrough: {
        icon: StrikethroughIcon,
      },
    },
    list: {
      options: ['unordered'],
      unordered: {
        icon: BulletPointIcon,
        title: 'Bulleted list',
      },
    },
    // We're taking out alignment because center and right doesn't convert to markdown well
    textAlign: {
      options: ['left', 'center', 'right'],
      left: {
        icon: LeftAlignIcon,
      },
      center: {
        icon: CenterAlignIcon,
      },
      right: {
        icon: RightAlignIcon,
      },
    },
  };

  /**
   * Setting these properties to the toolbar will disable keyboard shortcuts
   * https://github.com/jpuri/react-draft-wysiwyg/issues/633
   */
  const emptyToolbar: {
    options: string[];
    inline: {
      options: string[];
    };
  } = { options: [], inline: { options: [] } };

  /**
   * Custom handler for key commands, focusing on 'backspace'.
   * This addresses an issue where deleting complex blocks (like lists)
   * in react-draft-wysiwyg doesn't behave as expected.
   * GitHub issue for more details: https://github.com/jpuri/react-draft-wysiwyg/issues/987#issuecomment-766424943.
   * @param command
   */
  const handleKeyCommand = (command: DraftEditorCommand) => {
    if (command === 'backspace') {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return 'handled';
      }
    }

    return 'not-handled';
  };

  return (
    <TextareaWrapper
      className={AttrsHelper.formatClassname(
        'raw-textarea',
        invalid && 'error-state',
        disabled && 'disabled-state',
        readonly && 'readonly-state',
        stretched && 'stretched-state',
        full && 'full-mode',
        className
      )}
      data-test={name}
      id={id}
    >
      <Editor
        ariaAutoComplete="on"
        editorClassName="TextArea"
        editorState={editorState}
        key={name}
        handleKeyCommand={handleKeyCommand}
        readOnly={disabled || readonly}
        onEditorStateChange={editor => {
          const changedValue = draftToMarkdown(
            convertToRaw(editor.getCurrentContent()),
            { preserveNewlines: true }
          );

          setEditorState(editor);

          if (convertedValue !== changedValue || !changedValue) {
            onChange?.(outFormat(changedValue));
          }
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        stripPastedStyles
        toolbar={full ? toolbar : emptyToolbar}
        toolbarHidden={!full || disabled || readonly}
      />
    </TextareaWrapper>
  );
};
