import crypto from 'crypto-js/sha256';
import { User } from '@axiom/validation';

type Payload = {
  visitor: {
    id?: string;
    role?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    apUserId?: string;
  };
  account: {
    id?: 'AxiomLaw';
  };
};

export const PendoUtil = {
  initializePendo({
    id,
    roles,
    email,
    firstName,
    lastName,
  }: Partial<User> = {}) {
    // Pendo doesn't init every reload without the delay

    setTimeout(() => {
      if (window?.pendo) {
        const visitorId = id ? crypto(id).toString() : undefined;
        const payload: Payload = {
          visitor: {
            id: visitorId,
            role: (roles?.length ?? 0) > 0 ? roles?.[0] : undefined,
            firstName: firstName || undefined,
            lastName: lastName || undefined,
            email: email || undefined,
            apUserId: id || undefined,
          },
          account: {
            id: 'AxiomLaw',
          },
        };

        // This is a good console
        // eslint-disable-next-line no-console
        console.log('Initing Pendo As:', payload.visitor);
        window.pendo.initialize(payload);
      }
    }, 1000);
  },
};
