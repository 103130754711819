import React, { useEffect, useMemo, useState } from 'react';

import { Card, CardProps } from '../Card/Card';

import { DrawerContext, DrawerContextType } from './DrawerContext';

export type DrawerProps = {
  children: React.ReactNode;
  className?: string;
  expanded?: boolean;
  name?: string;
  shadow?: CardProps['shadow'] | null;
};

export const Drawer = ({
  children,
  className,
  expanded,
  name,
  shadow = 'default',
}: DrawerProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const drawerProviderValue = useMemo(
    () =>
      ({
        isExpanded,
        setIsExpanded,
      }) as DrawerContextType,
    [isExpanded]
  );

  return (
    <Card
      name={name}
      shadow={shadow}
      background={shadow ? 'light' : undefined}
      className={className}
    >
      <DrawerContext.Provider value={drawerProviderValue}>
        {children}
      </DrawerContext.Provider>
    </Card>
  );
};
