import { z } from 'zod';

import { AhqSubmissionCandidateSchema } from './ahq-submission-candidate';
import { AhqSubmissionPositionSchema } from './ahq-submission-position';
import { CandidateOpportunitySchema } from './candidate-opportunities';

export const AhqSubmissionCandidateOpportunitySchema =
  CandidateOpportunitySchema.pick({
    baseHourlyCompensation: true,
    candidateStatus: true,
    displayBillingRate: true,
    id: true,
    isActiveForSubmission: true,
    marginApprovalCeiling: true,
    marginApprovalFloor: true,
    meetsMinPayRequirements: true,
    proposedHourlyCompensation: true,
    proposedHourlyRate: true,
    proposedMarginTarget: true,
    submissionDescription: true,
    submissionRank: true,
  }).extend({
    candidate: AhqSubmissionCandidateSchema,
    position: AhqSubmissionPositionSchema,
  });

export type AhqSubmissionCandidateOpportunity = z.infer<
  typeof AhqSubmissionCandidateOpportunitySchema
>;
