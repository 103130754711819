import { z } from 'zod';

import { ContactSchema } from './contacts';

export const AfcSubmissionContactSchema = ContactSchema.pick({
  displayRole: true,
  email: true,
  firstName: true,
  fullName: true,
  id: true,
  lastName: true,
});

export type AfcSubmissionContact = z.infer<typeof AfcSubmissionContactSchema>;
