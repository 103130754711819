import React from 'react';
import { z } from 'zod';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { offset } from '@floating-ui/react';
import { CustomSchemaTypes, SchemaDate } from '@axiom/types';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { DateUtil } from '../../../utils/date-util';
import { CondensedMedium } from '../../content/CondensedMedium/CondensedMedium';
import { Button } from '../../element/Button/Button';
import { IconButton } from '../../element/Button/IconButton';

import { RawDateInputPopperHeader } from './RawDateInputPopperHeader';

import 'react-datepicker/dist/react-datepicker.css';

const DateInputWrapper = styled.div``;

export type RawDateInputValueType = string | number | null;

type formatOutValue = Date | string | null;

export const RAW_DATE_INPUT_DIRECTIONS = {
  DOWN: 'down',
  DEFAULT: 'default',
} as const;

export type RawDateInputDirectionType =
  (typeof RAW_DATE_INPUT_DIRECTIONS)[keyof typeof RAW_DATE_INPUT_DIRECTIONS];

export const ALLOWED_DATE_TYPES = [
  CustomSchemaTypes.SchemaDate,
  CustomSchemaTypes.SchemaMonthYear,
  CustomSchemaTypes.SchemaTime,
  CustomSchemaTypes.SchemaTimestamp,
  CustomSchemaTypes.SchemaYear,
] as const;

export type RawDateInputProps = {
  direction?: RawDateInputDirectionType;
  disabled?: boolean;
  excludeDates?: z.infer<typeof SchemaDate>[];
  id?: string;
  invalid?: boolean;
  name: string;
  onBlur?: (value?: RawDateInputValueType) => void;
  onChange?: (value?: RawDateInputValueType) => void;
  onFocus?: (value?: RawDateInputValueType) => void;
  placeholder?: string;
  type: (typeof ALLOWED_DATE_TYPES)[number];
  value?: RawDateInputValueType;
};

const defaultExcludeDatesArr: RawDateInputProps['excludeDates'] = [];

export const RawDateInput = ({
  disabled = false,
  direction = 'default',
  excludeDates = defaultExcludeDatesArr,
  id,
  invalid = false,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  type,
  value,
}: RawDateInputProps) => {
  if (!ALLOWED_DATE_TYPES.includes(type)) {
    throw new Error(
      `Only allowed schema types for DateInput are: ${ALLOWED_DATE_TYPES.join(
        ', '
      )}; Schema type provided is ${type}`
    );
  }
  const renderIconButton = (schemaType: string) => {
    return (
      <IconButton
        pattern="secondary"
        variation="minimal"
        icon={
          schemaType === CustomSchemaTypes.SchemaTime ? 'clock' : 'calendar'
        }
        name="form-element-icon"
        className="control-icon fixed-dimension-inline"
        tabIndex={-1}
      />
    );
  };

  const typeSpecificProps = {
    [CustomSchemaTypes.SchemaDate]: {
      showIcon: !value,
      icon: renderIconButton(CustomSchemaTypes.SchemaDate),
      formatIn: (v?: RawDateInputValueType) => {
        return v ? moment(v).toDate() : null;
      },
      formatOut: (date?: formatOutValue) => {
        return DateUtil.formatAsDate(date);
      },
      formatWeekDay: (nameOfDay: string) => `${nameOfDay.slice(0, 1)}`,
      renderCustomHeader: ({
        date,
        decreaseMonth,
        increaseMonth,
      }: {
        date: Date;
        decreaseMonth: () => void;
        increaseMonth: () => void;
      }) => (
        <RawDateInputPopperHeader
          navigatePrevious={decreaseMonth}
          navigateNext={increaseMonth}
          title={DateUtil.displayLongMonthYear(date)}
        />
      ),
      renderDayContents: (dayOfMonth: number) => (
        <CondensedMedium>{dayOfMonth}</CondensedMedium>
      ),
    },
    [CustomSchemaTypes.SchemaTimestamp]: {
      dateFormat: 'MMMM d, yyyy h:mm aa',
      showTimeSelect: true,
      showTimeCaption: false,
      formatIn: (v?: RawDateInputValueType) => {
        return v ? moment(v).toDate() : null;
      },
      formatOut: (date?: formatOutValue) => {
        return DateUtil.formatAsTimestamp(date);
      },
      formatWeekDay: (nameOfDay: string) => `${nameOfDay.slice(0, 1)}`,
      renderCustomHeader: ({
        date,
        decreaseMonth,
        increaseMonth,
      }: {
        date: Date;
        decreaseMonth: () => void;
        increaseMonth: () => void;
      }) => (
        <RawDateInputPopperHeader
          navigatePrevious={decreaseMonth}
          navigateNext={increaseMonth}
          title={DateUtil.displayLongMonthYear(date)}
        />
      ),
      renderDayContents: (dayOfMonth: number) => (
        <CondensedMedium>{dayOfMonth}</CondensedMedium>
      ),
    },
    [CustomSchemaTypes.SchemaTime]: {
      dateFormat: 'h:mm aa',
      icon: renderIconButton(CustomSchemaTypes.SchemaTime),
      showIcon: !value,
      showTimeSelect: true,
      showTimeSelectOnly: true,
      showTimeCaption: false,
      // `todayButton: false` is needed to remove `.react-datepicker__time-container--with-today-button`
      // although the Today Button by default does not display here, the included
      // class messes with the popper positioning
      todayButton: false,
      formatIn: (v?: RawDateInputValueType) => {
        return v ? moment(v, DateUtil.getTimeFormat()).toDate() : null;
      },
      formatOut: (date?: formatOutValue) => {
        return DateUtil.formatAsTime(date);
      },
    },
    [CustomSchemaTypes.SchemaMonthYear]: {
      dateFormat: 'MM/yyyy',
      icon: renderIconButton(CustomSchemaTypes.SchemaMonthYear),
      showFullMonthYearPicker: true,
      showIcon: !value,
      showMonthYearPicker: true,
      formatIn: (v?: RawDateInputValueType) => {
        return v ? moment(v).date(1).toDate() : null;
      },
      formatOut: (date?: formatOutValue) => {
        return DateUtil.formatAsDate(date);
      },
      renderCustomHeader: ({
        date,
        decreaseYear,
        increaseYear,
      }: {
        date: Date;
        decreaseYear: () => void;
        increaseYear: () => void;
      }) => (
        <RawDateInputPopperHeader
          navigatePrevious={decreaseYear}
          navigateNext={increaseYear}
          title={DateUtil.formatAsYear(date)}
        />
      ),
      renderMonthContent: (monthIndex: number, shortMonthText: string) => (
        <CondensedMedium>{shortMonthText}</CondensedMedium>
      ),
      todayButton: (
        <Button pattern="secondary" variation="minimal" name="NOW_BUTTON">
          THIS MONTH
        </Button>
      ),
    },
    [CustomSchemaTypes.SchemaYear]: {
      dateFormat: 'yyyy',
      icon: renderIconButton(CustomSchemaTypes.SchemaYear),
      showIcon: !value,
      showYearPicker: true,
      formatIn: (v?: RawDateInputValueType) => {
        return v ? moment().year(+v).month(6).date(1).toDate() : null;
      },
      formatOut: (date?: formatOutValue) => {
        return +DateUtil.formatAsYear(date);
      },
      renderCustomHeader: ({
        date,
        decreaseYear,
        increaseYear,
      }: {
        date: Date;
        decreaseYear: () => void;
        increaseYear: () => void;
      }) => {
        const { startPeriod, endPeriod } = DateUtil.getYearsPeriod(date);
        return (
          <RawDateInputPopperHeader
            navigatePrevious={decreaseYear}
            navigateNext={increaseYear}
            title={`${startPeriod} - ${endPeriod}`}
          />
        );
      },
      renderYearContent: (year: number) => (
        <CondensedMedium>{year}</CondensedMedium>
      ),
      todayButton: (
        <Button pattern="secondary" variation="minimal" name="NOW_BUTTON">
          THIS YEAR
        </Button>
      ),
    },
  };

  const { formatIn, formatOut, ...componentProps } = typeSpecificProps[type];
  /* eslint-disable react/jsx-props-no-spreading */

  // Required work around for using storybook controls
  // Opening the control defaults to an {}, which crashes the field
  const formattedExcludeDates = Array.isArray(excludeDates)
    ? excludeDates.map(v => new Date(v as string))
    : [];

  return (
    <DateInputWrapper
      className={AttrsHelper.formatClassname(
        'raw-dateinput',
        invalid && 'error-state',
        disabled && 'disabled-state'
      )}
      data-test={name}
    >
      <DatePicker
        autoComplete="off"
        calendarClassName="rdi-datepicker"
        className="calendar-input"
        disabled={disabled}
        disabledKeyboardNavigation
        excludeDates={formattedExcludeDates}
        id={id}
        onBlur={e => {
          const element = e.target as HTMLInputElement;
          onBlur?.(formatOut(element.value));
        }}
        onChange={(date: Date | null) => {
          onChange?.(formatOut(date));
        }}
        onFocus={() => {
          onFocus?.();
        }}
        placeholderText={placeholder}
        popperClassName={AttrsHelper.formatClassname(
          'rdi-datepicker-popper',
          direction === RAW_DATE_INPUT_DIRECTIONS.DOWN && 'direction-down'
        )}
        popperModifiers={[offset(-8)]} // included offset is 10, must subtract for proper spacing
        selected={formatIn(value)}
        showPopperArrow={false}
        todayButton={
          <Button pattern="secondary" variation="minimal" name="NOW_BUTTON">
            TODAY
          </Button>
        }
        toggleCalendarOnIconClick
        wrapperClassName="rdi-input-wrapper"
        {...componentProps}
      />
    </DateInputWrapper>
  );
};
