import { z } from 'zod';
import { ZodArrayToEnum } from '@axiom/types';

import { CandidateSchema } from './candidate';
import { CandidateOpportunityDisplayBillingRates } from './candidate-opportunities';

export const AfcSubmissionCandidateSchema = CandidateSchema.pick({
  addressCity: true,
  addressCountry: true,
  addressState: true,
  badges: true,
  barredLocations: true,
  calculatedDisplayName: true,
  calculatedFirstName: true,
  calculatedLastName: true,
  calendar: true,
  candidateSummary: true, // Bad Prop APCORE-2591
  certifications: true,
  degrees: true,
  experiences: true,
  firstName: true, // Bad Prop APCORE-2591
  id: true,
  industries: true,
  isProfileShared: true,
  languages: true,
  lawFirms: true,
  lawDegreeSchools: true,
  occupationType: true,
  position: true, // Bad Prop APCORE-2591
  practiceArea: true,
  profileImageKey: true,
  profileStatus: true, // Bad Prop APCORE-2591
  pronouns: true,
  publicCandidateSummary: true,
  publicRefId: true,
  yearsOfExperience: true,
}).extend({
  proposedHourlyRate: z.number().nullish(), // Bad Prop APCORE-2591
  displayBillingRate: z
    .enum(ZodArrayToEnum(CandidateOpportunityDisplayBillingRates))
    .nullable(), // Bad Prop APCORE-2591
});

export type AfcSubmissionCandidate = z.infer<
  typeof AfcSubmissionCandidateSchema
>;
