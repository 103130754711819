import { z } from 'zod';
import moment from 'moment';
import { EngagementsUtil } from '@axiom/utils';

import { ProjectNeedsSchema } from './project-needs';

function checkDateRange(
  context: z.RefinementCtx,
  errorPath: string,
  startDate: string,
  endDate: string
) {
  if (!moment(endDate).isAfter(moment(startDate), 'day')) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Must be after start date',
      path: [errorPath],
    });
  }
}

function checkHourlyRate(
  context: z.RefinementCtx,
  errorPath: string,
  minHourlyRate: number,
  maxHourlyRate: number
) {
  if (maxHourlyRate <= minHourlyRate) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Must be more than min hourly rate',
      path: [errorPath],
    });
  }
}

function checkEngagementLength(
  context: z.RefinementCtx,
  errorPath: string,
  startDate: string,
  endDate: string,
  billingUnitsPerWeek: number
) {
  const engagementLengthInDays =
    EngagementsUtil.calculateEngagementLengthInDays(startDate, endDate);
  const weeks = EngagementsUtil.calculateEngagementWeeks(
    engagementLengthInDays
  );
  if (
    EngagementsUtil.showEngagementLengthWarning(
      startDate,
      endDate,
      billingUnitsPerWeek,
      weeks
    )
  ) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Total engagement length must be at least 20 hours',
      path: [errorPath],
    });
  }
}

export const ProjectNeedsWithLegalSkillsSchema = ProjectNeedsSchema.extend({
  legalSkillsAnswers: z.array(z.array(z.string()).nullish()).nullish(),
}).superRefine((schema, ctx) => {
  checkDateRange(ctx, 'endDate', schema.startDate, schema.endDate);
  checkHourlyRate(
    ctx,
    'maxBudgetPerHour',
    schema.minBudgetPerHour,
    schema.maxBudgetPerHour
  );
  checkEngagementLength(
    ctx,
    'billingUnitsPerWeek',
    schema.startDate,
    schema.endDate,
    schema.billingUnitsPerWeek
  );
});

export type ProjectNeedsWithLegalSkills = z.infer<
  typeof ProjectNeedsWithLegalSkillsSchema
>;
