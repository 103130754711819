import React, { useContext } from 'react';

import { CondensedHeader } from '../../content/CondensedHeader/CondensedHeader';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { backgroundColorNames } from '../../../theme/axiom-theme';
import { BadgeBackgrounds } from '../Badge/Badge';
import {
  ProgressGroupContext,
  ProgressGroupContextType,
} from '../ProgressGroup/ProgressGroupContext';

import { ProgressGraduations } from './ProgressGraduations';

export const ProgressBarColors = Object.values(backgroundColorNames);
export type ProgressProps = {
  className?: string;
  color: (typeof BadgeBackgrounds)[number];
  backgroundColor?: (typeof ProgressBarColors)[number];
  currentStep: number;
  name?: string;
  size?: 'default' | 'large';
  type?: 'number' | 'percent';
  totalSteps: number;
};

/**
 * TODO Bootstrap: remove rd- when we remove bootstrap stylesheet
 */
export const Progress = ({
  className,
  color,
  backgroundColor = 'light blue',
  currentStep,
  name,
  size = 'default',
  type = 'number',
  totalSteps,
}: ProgressProps) => {
  const { showLabel, graduations }: ProgressGroupContextType =
    useContext(ProgressGroupContext);

  // In case current step == 10 and total steps == 8
  const currentStepFormatted = Math.min(currentStep, totalSteps);
  return (
    <Layout
      horizontalGutter="24px"
      className={AttrsHelper.formatClassname('rd-progress', className)}
      position="middle"
      name={name}
    >
      <LayoutItem fluid>
        <div
          className={AttrsHelper.formatClassname(
            'progress-bar-display',
            `size-${size}`,
            `background-${backgroundColor.replace(' ', '-')}`
          )}
        >
          <div
            className={AttrsHelper.formatClassname(
              'progress-bar-complete',
              `background-${color.replace(' ', '-')}`
            )}
            style={{
              width: `${(currentStepFormatted / totalSteps) * 100}%`,
            }}
          />
          <ProgressGraduations graduationsCount={graduations} />
        </div>
      </LayoutItem>
      {showLabel && (
        <LayoutItem className="progress-bar-steps">
          <CondensedHeader name="LABEL">
            {type === 'number' ? (
              <>
                <span data-test="CURRENT_PROGRESS_STEP">
                  {currentStepFormatted}
                </span>
                /<span data-test="TOTAL_PROGRESS_STEPS">{totalSteps}</span>
              </>
            ) : (
              <span data-test="CURRENT_PROGRESS_STEP">
                {Math.round((currentStepFormatted / totalSteps) * 100)}%
              </span>
            )}
          </CondensedHeader>
        </LayoutItem>
      )}
    </Layout>
  );
};
