import { z } from 'zod';
import { SchemaTimestamp } from '@axiom/types';

import { AccountSchema } from './account';
import { ContactSchema } from './contacts';
import { UserSchema } from './user';

export const ContactLegacySchema = ContactSchema.extend({
  accounts: z.array(AccountSchema),
  user: UserSchema,
  welcomeEmailLastSentAt: SchemaTimestamp.nullable(),
  welcomeEmailLastSentBy: z.string().uuid().nullable(),
  welcomeEmailLastSentByUser: UserSchema.nullable(),
});

export type ContactLegacy = z.infer<typeof ContactLegacySchema>;
