import { ContactAccountConst } from './contact-account';

export const AccountConst = {
  Expansions: {
    accountTeamUserInfo: 'accountTeamUserInfo',
    teamUserInfo: 'teamUserInfo',
    parentAccountInfo: 'parentAccountInfo',
    // listener notifications
    accountCandidatesInfo: 'accountCandidatesInfo',
    accountES: 'accountES',
  },
  PreferredVideoClient: {
    zoom: 'Zoom',
  },
  Types: {
    Direct: 'Direct',
  },
  // Attrs we want to show through AccountContactAccountAssociation
  ContactAccountOptOutAttrs: ContactAccountConst.optOutColumns,
} as const;
