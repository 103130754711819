import React, { useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SchemaEmail } from '@axiom/types';
import {
  ApiError,
  Button,
  CondensedLarge,
  CondensedMedium,
  Form,
  Gutter,
  Input,
  Well,
  Grid,
  GridRow,
  GridColumn,
  SmallHeader,
  AxiomLogo,
} from '@axiom/ui';

import sideImageUrl from '../../public/svg/fingerprint.svg?url';
import { AuthApi } from '../../api/auth-api';
import { ClientLoginTwoColumnLayout } from '../../layouts/ClientLogin/ClientLoginTwoColumnLayout';
import { WindowUtil } from '../../utils/WindowUtil';
import { MfaUtil } from '../../utils/mfa-util';
import { PasswordStrengthMeter } from '../PasswordStrengthMeter/PasswordStrengthMeter';
import { LoginContext } from '../LoginContext/LoginContext';

import {
  ClientResetPasswordFormData,
  ClientResetPasswordFormSchema,
} from './client-reset-password-schema';

const { getUrlWithRelayState } = WindowUtil;

export type ClientResetPasswordProps = {
  email: string;
  token?: string;
};

export const ClientResetPassword: React.FC<ClientResetPasswordProps> = ({
  email,
  token,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const loginScreen = getUrlWithRelayState('/', {
    location,
  });
  const { setLoginState } = useContext(LoginContext);

  useEffect(() => {
    if (!email || !SchemaEmail.safeParse(email).success) {
      navigate(
        getUrlWithRelayState('/', {
          location,
        })
      );
    }
  }, [location]);

  return (
    <ClientLoginTwoColumnLayout sideImageUrl={sideImageUrl}>
      <Gutter bottom="48px" only="largeScreen" />
      <Gutter bottom="24px" only="tablet" />
      <Grid centered>
        <GridRow>
          <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
            <Gutter bottom="24px" className="text-align-center">
              <AxiomLogo width="230px" />
            </Gutter>
            <Gutter bottom="24px">
              <SmallHeader>Set a password</SmallHeader>
            </Gutter>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
            <Form
              name="RESET_PASSWORD_FORM"
              onSubmit={async (
                { password }: ClientResetPasswordFormData,
                actions
              ) => {
                try {
                  if (token) {
                    await AuthApi.updateTokenSetPassword(token, {
                      password,
                    });
                  }

                  const { data: jwt } = await AuthApi.createAuth({
                    email,
                    password,
                    mfaCode: null,
                  });

                  setLoginState({
                    email,
                    password,
                  });

                  MfaUtil.handleNavigation(navigate, location, jwt);
                } catch (e) {
                  if (!(e instanceof ApiError)) throw e;
                  actions.setFieldError(
                    'password',
                    'An error occurred. Please try again or reach out to Axiom Answers for Clients.'
                  );
                }
              }}
              schema={ClientResetPasswordFormSchema}
              initialValues={{ password: null }}
            >
              {({ fireSubmit, values: { password } }) => (
                <>
                  <Gutter bottom="8px">
                    <Well>
                      <CondensedLarge name="email">{email}</CondensedLarge>
                    </Well>
                  </Gutter>
                  <Gutter bottom="24px">
                    <Link to={loginScreen}>
                      <CondensedMedium>
                        Not you? Sign in with a different account.
                      </CondensedMedium>
                    </Link>
                  </Gutter>
                  <Gutter bottom="16px">
                    <Input label="Password" name="password" />
                  </Gutter>
                  <Gutter bottom="16px">
                    <PasswordStrengthMeter password={password} />
                  </Gutter>
                  <Button fluid onClick={fireSubmit} name="set-password">
                    Set Password
                  </Button>
                </>
              )}
            </Form>
          </GridColumn>
        </GridRow>
      </Grid>
      <Gutter bottom="48px" only="largeScreen" />
      <Gutter bottom="24px" only="tablet" />
    </ClientLoginTwoColumnLayout>
  );
};
