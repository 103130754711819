import Ajv from 'ajv';
import { z } from 'zod';
import { EventsConst } from '@axiom/const';
import {
  SchemaDate,
  SchemaEmail,
  SchemaTimestamp,
  ZodArrayToEnum,
} from '@axiom/types';

import { axiomValidationOptions } from './options';

const ajv = new Ajv(axiomValidationOptions());

export const EventTypes = Object.values(EventsConst.Types);
export const EventPurposes = Object.values(EventsConst.Purposes);

export const EventSchema = z.object({
  attachmentKey: z.string().max(255).nullable(),
  attachmentName: z.string().max(255).nullable(),
  bullhornNoteId: z.string().max(255).nullable(),
  candidateId: z.string().uuid().nullable(),
  createdAt: SchemaTimestamp,
  description: z.string().nullable(),
  emailAddresses: z.array(SchemaEmail).nullable(),
  id: z.string().uuid(),
  integrationId: z.string().max(255).nullable(),
  lastAuthoredBy: z.string().uuid(),
  lastUpdatedBy: z.string().uuid().nullable(),
  opportunityId: z.string().uuid().nullable(),
  purpose: z.enum(ZodArrayToEnum(EventPurposes)),
  salesforceEventId: z.string().max(255).nullable(),
  scheduledDate: SchemaDate,
  type: z.enum(ZodArrayToEnum(EventTypes)),
  updatedAt: SchemaTimestamp,
});

export type Event = z.infer<typeof EventSchema>;

const eventCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    candidateId: { type: 'string', format: 'uuid' },
    opportunityId: { type: 'string', format: 'uuid' },
    lastAuthoredBy: { type: 'string', format: 'uuid' },
    description: { type: 'string' },
    purpose: {
      type: 'string',
      enum: Object.values(EventsConst.Purposes),
    },
    type: {
      type: 'string',
      enum: Object.values(EventsConst.Types),
    },
    bullhornNoteId: {
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
    salesforceEventId: {
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
    createdAt: {
      type: 'string',
      format: 'date-time',
    },
    integrationId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    updatedAt: {
      type: 'string',
      format: 'date-time',
    },
    emailAddresses: {
      type: 'array',
      items: {
        type: 'string',
        format: 'email',
      },
    },
    file: {
      type: 'object',
    },
    scheduledDate: {
      type: 'string',
      format: 'date',
    },
  },
};

const eventUpsertValidation = {
  ...eventCommonValidation,
  coerceTypes: true,
  properties: {
    ...eventCommonValidation.properties,
    integrationId: {
      type: ['string'],
      maxLength: 255,
    },
    // boomi sends integration or salesforce ids for the following
    candidateId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    opportunityId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    lastAuthoredBy: {
      type: ['string', 'null'],
      maxLength: 255,
    },
  },
  anyOf: [
    { required: ['integrationId'] },
    { required: ['candidateId'] },
    { required: ['opportunityId'] },
    { required: ['salesforceEventId'] },
    { required: ['description'] },
    { required: ['purpose'] },
    { required: ['scheduledDate'] },
  ],
};

export const createEventValidator = ajv.compile({
  ...eventCommonValidation,
  required: ['purpose', 'type', 'scheduledDate'],
  anyOf: [{ required: ['candidateId'] }, { required: ['opportunityId'] }],
});

export const updateEventValidator = ajv.compile({
  ...eventCommonValidation,
  anyOf: [
    { required: ['candidateId'] },
    { required: ['opportunityId'] },
    { required: ['bullhornNoteId'] },
    { required: ['salesforceEventId'] },
    { required: ['description'] },
    { required: ['purpose'] },
    { required: ['type'] },
    { required: ['file'] },
    { required: ['emailAddresses'] },
    { required: ['scheduledDate'] },
  ],
});

// used by PUT endpoint upsert for integration through boomi

export const upsertEventValidatorCreator = ({
  ajvInstance = ajv,
  additionalOpts = {},
} = {}) =>
  ajvInstance.compile({
    ...eventUpsertValidation,
    ...additionalOpts,
  });

export const upsertEventValidator = upsertEventValidatorCreator();

// ONLY CONDITIONALLY USE THIS - for boomi endpoints only
export const upsertEventAdditionalPropertiesValidator =
  upsertEventValidatorCreator({
    ajvInstance: new Ajv({
      ...axiomValidationOptions(),
      removeAdditional: true,
    }),
    additionalOpts: {
      additionalProperties: false,
    },
  });

export const fileExtensionValidator = ajv.compile({
  type: 'object',
  additionalProperties: false,
  properties: {
    fileExtension: {
      type: 'string',
      enum: EventsConst.AllowedFileExtensions,
    },
    fileMimeType: {
      type: 'string',
      enum: EventsConst.AllowedFileMimeTypes,
    },
  },
});
