import React, { useEffect, useState } from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export type ImageCircleProps = {
  background?: 'blue' | 'light blue';
  src?: string | null;
  imageName?: string | null;
  name?: string | null;
  size?: 'large' | 'default' | 'small' | 'thumbnail';
};
export const ImageCircle = ({
  background = 'blue',
  src,
  imageName,
  name,
  size = 'default',
}: ImageCircleProps) => {
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const nameSplit = imageName?.split(' ') || [];
  const imageInitials = imageName?.split(' ').reduce((acc, word, index) => {
    if (word && (index === 0 || index === nameSplit.length - 1)) {
      acc += word[0];
    }

    return acc;
  }, '');

  useEffect(() => {
    if (src) {
      const getImageAsBase64 = async (url: string) => {
        const rawImg = await fetch(url).catch(response => {
          return response;
        });

        if (!rawImg.ok) {
          return;
        }

        const img = await rawImg.blob();
        const base64 = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          // eslint-disable-next-line unicorn/prefer-add-event-listener
          reader.onerror = reject;
          reader.readAsDataURL(img);
        });

        return setBase64Image(base64 as string);
      };

      getImageAsBase64(src);
    } else {
      setBase64Image(null);
    }

    return () => {
      setBase64Image(null);
    };
  }, [src]);

  return (
    <span
      className={AttrsHelper.formatClassname(
        'image-circle',
        `background-${background.replace(' ', '-')}`,
        `size-${size}`
      )}
      data-test={name}
      data-src={src}
    >
      {base64Image ? (
        <img alt={imageName || ''} src={base64Image} data-test="IMAGE" />
      ) : (
        <span data-test="INITIALS" className="image-initials">
          {imageInitials}
        </span>
      )}
    </span>
  );
};
