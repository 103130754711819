import React from 'react';
import { FormFieldProps } from 'semantic-ui-react';

import { FormField } from '../FormField/FormField';

import { CalendarProps, RawCalendar } from './RawCalendar';

type CalendarFormProps = Omit<CalendarProps, 'events'>;

const defaultProps: Partial<CalendarFormProps> = {
  backgroundEvents: [],
};

export const Calendar = ({
  backgroundEvents = defaultProps.backgroundEvents,
  dayMode,
  displayDate,
  height,
  name,
  permissions,
  startMode,
  timezone,
  scrollToTime,
  weekMode,
}: CalendarFormProps) => {
  return (
    <FormField
      name={name}
      renderField={({ setFieldValue, value }: FormFieldProps) => {
        return (
          <RawCalendar
            name={name}
            permissions={permissions}
            events={value}
            startMode={startMode}
            weekMode={weekMode}
            dayMode={dayMode}
            backgroundEvents={backgroundEvents}
            scrollToTime={scrollToTime}
            timezone={timezone}
            height={height}
            onChange={async (data, bgData) => {
              await setFieldValue(name, data);
              await setFieldValue('BACKGROUND_EVENTS', bgData);
            }}
            displayDate={displayDate}
          />
        );
      }}
    />
  );
};
