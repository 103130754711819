import React, { useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  CondensedMedium,
  Form,
  Gutter,
  Input,
  UnauthorizedError,
  CondensedLarge,
  Well,
  Grid,
  GridRow,
  GridColumn,
  SmallHeader,
  AxiomLogo,
} from '@axiom/ui';
import { SchemaEmail } from '@axiom/types';

import sideImageUrl from '../../public/svg/head-bubbles.svg?url';
import { ClientLoginTwoColumnLayout } from '../../layouts/ClientLogin/ClientLoginTwoColumnLayout';
import { WindowUtil } from '../../utils/WindowUtil';
import { MfaUtil } from '../../utils/mfa-util';
import { AuthApi } from '../../api/auth-api';
import { LoginContext } from '../LoginContext/LoginContext';

import { PasswordFormData, PasswordFormSchema } from './password-schema';

const { getUrlWithRelayState } = WindowUtil;

export const Password: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { providedEmail } = useParams();
  const search = new URLSearchParams(location.search);
  const { setLoginState } = useContext(LoginContext);

  useEffect(() => {
    if (!SchemaEmail.safeParse(providedEmail).success) {
      navigate(
        getUrlWithRelayState(`/`, {
          location,
        })
      );
    }
  }, [location, providedEmail]);

  const handleForgotPasswordClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();

    const body = {
      email: providedEmail,
      RelayState: search.get('RelayState') || undefined,
    };
    AuthApi.createForgotPassword(body);

    navigate(`../forgot-password`);
  };

  return (
    <ClientLoginTwoColumnLayout sideImageUrl={sideImageUrl}>
      <Gutter bottom="48px" only="largeScreen" />
      <Gutter bottom="24px" only="tablet" />
      <Grid centered>
        <GridRow>
          <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
            <Gutter bottom="24px" className="text-align-center">
              <AxiomLogo width="230px" />
            </Gutter>
            <Gutter bottom="24px">
              <SmallHeader>Sign In</SmallHeader>
            </Gutter>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
            <Form
              name="PASSWORD_FORM"
              onSubmit={async (formData: PasswordFormData, actions) => {
                try {
                  const { data: jwt } = await AuthApi.createAuth({
                    password: formData.password,
                    email: providedEmail,
                    mfaCode: null,
                  });

                  setLoginState({
                    email: providedEmail,
                    password: formData.password,
                  });

                  MfaUtil.handleNavigation(navigate, location, jwt);
                } catch (e) {
                  if (!(e instanceof UnauthorizedError)) {
                    throw e;
                  }
                  actions.setFieldError(
                    'password',
                    'Invalid email/password. Please try again.'
                  );
                }
              }}
              schema={PasswordFormSchema}
              initialValues={{ password: null }}
            >
              {({ fireSubmit }) => {
                return (
                  <>
                    <Gutter bottom="8px">
                      <Well>
                        <CondensedLarge name="email">
                          {providedEmail}
                        </CondensedLarge>
                      </Well>
                    </Gutter>
                    <Gutter bottom="24px">
                      <CondensedMedium>
                        <Link
                          to={getUrlWithRelayState(`/`, {
                            location,
                          })}
                        >
                          Not you? Sign in with a different account.
                        </Link>
                      </CondensedMedium>
                    </Gutter>
                    <Gutter bottom="8px">
                      <Input label="Password" name="password" />
                    </Gutter>
                    <Gutter bottom="16px">
                      <a
                        href="#"
                        onClick={handleForgotPasswordClick}
                        data-test="FORGOT_PASSWORD"
                      >
                        Forgot password?
                      </a>
                    </Gutter>
                    <Button fluid onClick={fireSubmit} name="sign-in">
                      Sign in
                    </Button>
                  </>
                );
              }}
            </Form>
          </GridColumn>
        </GridRow>
      </Grid>
      <Gutter bottom="48px" only="largeScreen" />
      <Gutter bottom="24px" only="tablet" />
    </ClientLoginTwoColumnLayout>
  );
};
