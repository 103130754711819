type EnvProps = {
  COOKIE_DOMAIN?: string | null;
  GOOGLE_MAPS_API_URL?: string | null;
  CLIENT_API_BASE?: string | null;
  S3_PUBLIC_BASE_URL?: string | null;
  S3_PUBLIC_FILE_BUCKET?: string | null;
  ENABLE_SUGGESTIONS?: string | null;
  TYPING_DEBOUNCE?: string | null;
  ENABLE_CALENDAR_EVENTS_TODAY?: string | null;
};

const env: EnvProps = {
  COOKIE_DOMAIN: null,
  GOOGLE_MAPS_API_URL: null,
  CLIENT_API_BASE: null,
  S3_PUBLIC_BASE_URL: null,
  S3_PUBLIC_FILE_BUCKET: null,
  ENABLE_SUGGESTIONS: null,
  TYPING_DEBOUNCE: null,
  ENABLE_CALENDAR_EVENTS_TODAY: null,
};

const envKeys = Object.keys(env) as Array<keyof EnvProps>;

export const DesignSysEnvUtil = {
  initialize(data: EnvProps) {
    envKeys.forEach(key => {
      const value = data[key];
      if (value !== null && value !== undefined) {
        env[key] = value;
      }
    });
  },
  get typingDebounce() {
    let debounce = 200;
    if (
      typeof env.TYPING_DEBOUNCE === 'string' &&
      Number.isInteger(env.TYPING_DEBOUNCE)
    ) {
      debounce = +env.TYPING_DEBOUNCE;
    } else if (typeof env.TYPING_DEBOUNCE === 'number') {
      debounce = env.TYPING_DEBOUNCE;
    }

    return debounce;
  },
  get cookieDomain() {
    return env.COOKIE_DOMAIN;
  },
  get googleMapsApi() {
    return env.GOOGLE_MAPS_API_URL;
  },
  get clientApiBase() {
    return env.CLIENT_API_BASE || '/api';
  },
  get publicBaseUrlS3() {
    return env.S3_PUBLIC_BASE_URL;
  },
  get publicS3Bucket() {
    return env.S3_PUBLIC_FILE_BUCKET;
  },
  get enableSuggestions() {
    return env.ENABLE_SUGGESTIONS;
  },
  get enableCalendarEventsToday() {
    return env.ENABLE_CALENDAR_EVENTS_TODAY === 'true';
  },
};
