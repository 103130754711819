import Ajv from 'ajv';
import { z } from 'zod';
import { LanguageConst } from '@axiom/const';
import { ZodArrayToEnum } from '@axiom/types';

import { axiomValidationOptions } from './options';
import { arrayValidatorCreator } from './general';
import { LanguageSchema } from './language';

export const OpportunityLanguageRequiredLanguageProficiencies = Object.values(
  LanguageConst.LANGUAGE_PROFICIENCIES
);

export const OpportunityLanguageRequiredLanguageSkills = Object.values(
  LanguageConst.LANGUAGE_SKILLS
);

export const OpportunityLanguageSchema = z.object({
  id: z.string().uuid(),
  language: LanguageSchema.nullish(),
  languageId: z.string().uuid(),
  opportunityId: z.string().uuid(),
  requiredLanguageProficiency: z
    .enum(ZodArrayToEnum(OpportunityLanguageRequiredLanguageProficiencies))
    .nullable(),
  requiredLanguageSkill: z
    .enum(ZodArrayToEnum(OpportunityLanguageRequiredLanguageSkills))
    .nullable(),
});

export type OpportunityLanguage = z.infer<typeof OpportunityLanguageSchema>;

const ajv = new Ajv(axiomValidationOptions());

const opportunityLanguageValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    languageId: {
      type: 'string',
      format: 'uuid',
    },
    requiredLanguageSkill: {
      type: ['string', 'null'],
    },
    requiredLanguageProficiency: {
      type: 'string',
    },
    requiredLanguageCEFR: {
      type: ['string', 'null'],
      enum: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2', null],
    },
  },
  required: ['requiredLanguageProficiency', 'languageId'],
};

export const opportunityLanguageValidator = ajv.compile(
  opportunityLanguageValidation
);

export const opportunityLanguageArrayValidator = arrayValidatorCreator(
  opportunityLanguageValidation
);
