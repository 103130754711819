import Ajv from 'ajv';
import { z } from 'zod';
import {
  LANGUAGE_PROFICIENCIES_KEYS,
  LANGUAGE_SKILLS_KEYS,
  LanguageConst,
} from '@axiom/const';
import { ZodArrayToEnum } from '@axiom/types';

import { axiomValidationOptions } from './options';

export const LanguageProficiencies = Object.values(LANGUAGE_PROFICIENCIES_KEYS);

export const LanguageSkills = Object.values(LANGUAGE_SKILLS_KEYS);

export const LanguageCEFRs = Object.values(LanguageConst.LANGUAGE_CEFR_LEVELS);

// Java version
export const LanguageSchema = z.object({
  id: z.string().uuid(),
  languageCEFR: z.enum(ZodArrayToEnum(LanguageCEFRs)).nullish(),
  languageProficiency: z.enum(ZodArrayToEnum(LanguageProficiencies)).nullable(),
  languageSkill: z.enum(ZodArrayToEnum(LanguageSkills)).nullable(),
  name: z.string().max(255),
});

export type Language = z.infer<typeof LanguageSchema>;

const ajv = new Ajv(axiomValidationOptions());

const languageValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
    },
  },
  required: ['name'],
};

export const languageValidator = ajv.compile(languageValidation);
