import { z } from 'zod';
import { ZodArrayToEnum } from '@axiom/types';
import { OpportunitiesConst } from '@axiom/const';

import { PracticeAreaSchema } from './practice-area';
import { AccountAccountTypes } from './account';
import { OpportunitySchema } from './opportunity';
import { AfcSubmissionPositionSchema } from './afc-submission-position';
import { AfcSubmissionContactSchema } from './afc-submission-contact';

export const AfcSubmissionProjectStates = Object.values(
  OpportunitiesConst.ProjectStates
);

export const AfcSubmissionSchema = OpportunitySchema.pick({
  accountName: true,
  confidential: true,
  currency: true,
  id: true,
  isQualificationComplete: true,
  jobName: true,
  primaryFocusArea: true,
  primaryPracticeArea: true,
  salesLead: true,
  salesforceContactId: true,
  selfService: true,
  submissionId: true,
  submissionLastUpdatedAt: true,
}).extend({
  accountType: z.enum(ZodArrayToEnum(AccountAccountTypes)).nullable(),
  contacts: z.array(AfcSubmissionContactSchema),
  editable: z.boolean(),
  positions: z.array(AfcSubmissionPositionSchema),
  practiceArea: PracticeAreaSchema.pick({
    id: true,
    name: true,
    parentId: true,
  }).nullish(),
  projectState: z.enum(ZodArrayToEnum(AfcSubmissionProjectStates)),
});

export type AfcSubmission = z.infer<typeof AfcSubmissionSchema>;
