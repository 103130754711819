import { z } from 'zod';
import { ZodArrayToEnum } from '@axiom/types';
import { ContactsOpportunitiesConst } from '@axiom/const';

import { AhqSubmissionContactSchema } from './ahq-submission-contact';

export const SubmissionContactsOpportunityRoles = Object.keys(
  ContactsOpportunitiesConst.Roles
) as Array<keyof typeof ContactsOpportunitiesConst.Roles>;

export const AhqSubmissionContactsOpportunitySchema = z
  .object({
    contactId: z.string().uuid(),
    id: z.string().uuid(),
    opportunityId: z.string().uuid(),
    role: z.enum(ZodArrayToEnum(SubmissionContactsOpportunityRoles)).nullable(),
  })
  .extend({
    contact: AhqSubmissionContactSchema,
  });

export type AhqSubmissionContactsOpportunity = z.infer<
  typeof AhqSubmissionContactsOpportunitySchema
>;
