import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';
import { requireAtLeastOneDefined } from './general';

const ajv = new Ajv(axiomValidationOptions());

export const PracticeAreaSchema = z.object({
  bullhornId: z.string().max(255).nullable(),
  id: z.string().uuid(),
  name: z.string().max(255),
  parentId: z.string().uuid().nullable(),
  type: z.string().max(255),
});

export type PracticeArea = z.infer<typeof PracticeAreaSchema>;

export const updatePracticeAreaSchema = PracticeAreaSchema.partial()
  .strict()
  .refine(requireAtLeastOneDefined);

const practiceAreaValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
      maxLength: 255,
    },
    bullhornId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    parentId: {
      type: ['string', 'null'],
    },
    type: {
      type: ['string', 'null'],
    },
  },
  required: ['name'],
};

export const practiceAreaValidator = ajv.compile(practiceAreaValidation);
