import { SchemaTimestamp, ZodArrayToEnum } from '@axiom/types';
import { z } from 'zod';
import { AccountConst } from '@axiom/const';

import { requireAtLeastOneDefined } from './general';
import { CandidateSchema } from './candidate';
import { UserSchema } from './user';
import { ZodCandidateOpportunityCandidateStatuses } from './candidate-opportunities';

export const CandidateAccountAccountTypes = Object.values(AccountConst.Types);

export const CandidateAccountSchema = CandidateSchema.extend({
  accountCandidateStatus: ZodCandidateOpportunityCandidateStatuses.nullable(),
  accountId: z.string().uuid(),
  accountType: z.enum(ZodArrayToEnum(CandidateAccountAccountTypes)).nullable(),
  billingUnitsPerWeek: z.string().max(255).nullable(),
  calculatedNotes: z.string().nullable(),
  candidateId: z.string().uuid(),
  hasOtherEngagements: z.boolean().nullable(),
  isHiddenExternal: z.boolean().nullable(),
  notes: z.string().nullable(),
  notesUpdatedAt: SchemaTimestamp.nullable(),
  notesUpdatedBy: z.string().uuid().nullable(),
  notesUpdatedByUser: UserSchema.nullish(),
  weeklyTimeUnit: z.string().max(255).nullable(),
});

export type CandidateAccount = z.infer<typeof CandidateAccountSchema>;

export const updateCandidateAccountSchema = CandidateAccountSchema.partial()
  .pick({
    isHiddenExternal: true,
    notes: true,
  })
  .strict()
  .refine(requireAtLeastOneDefined);
