import Ajv from 'ajv';
import { z } from 'zod';
import { ZodArrayToEnum } from '@axiom/types';

import { axiomValidationOptions } from './options';
import { arrayValidatorCreator } from './general';
import {
  LanguageCEFRs,
  LanguageProficiencies,
  LanguageSchema,
  LanguageSkills,
} from './language';

export const CandidateLanguageSchema = LanguageSchema.extend({
  candidateId: z.string().uuid().nullable(),
  id: z.string().uuid(),
  language: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .nullable(),
  languageId: z.string().uuid().nullable(),
  languageCEFR: z.enum(ZodArrayToEnum(LanguageCEFRs)).nullish(),
  languageProficiency: z.enum(ZodArrayToEnum(LanguageProficiencies)).nullable(),
  languageSkill: z.enum(ZodArrayToEnum(LanguageSkills)).nullable(),
  name: z.string().nullable(),
});

export type CandidateLanguage = z.infer<typeof CandidateLanguageSchema>;

export const CandidateLanguageFormSchema = CandidateLanguageSchema.pick({
  id: true,
  languageCEFR: true,
}).extend({
  languageProficiency: z.enum(ZodArrayToEnum(LanguageProficiencies)), // make this required
  languageSkills: z
    .array(z.enum(ZodArrayToEnum(LanguageSkills)))
    .nonempty({ message: 'Required' }),
});

export type CandidateLanguageForm = z.infer<typeof CandidateLanguageFormSchema>;

export const CandidateLanguageUpdateSchema = CandidateLanguageSchema.pick({
  languageCEFR: true,
}).extend({
  languageId: z.string().uuid(),
  languageProficiency: z.enum(ZodArrayToEnum(LanguageProficiencies)),
  languageSkill: z.enum(ZodArrayToEnum(LanguageSkills)),
});

export type CandidateLanguageUpdate = z.infer<
  typeof CandidateLanguageUpdateSchema
>;

const ajv = new Ajv(axiomValidationOptions());

const candidateLanguageValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    languageId: {
      type: 'string',
      format: 'uuid',
    },
    languageSkill: {
      type: ['string', 'null'],
    },
    languageProficiency: {
      type: 'string',
    },
    languageCEFR: {
      type: ['string', 'null'],
      enum: [...LanguageCEFRs, null],
    },
  },
  required: ['languageProficiency', 'languageId'],
};

export const candidateLanguageValidator = ajv.compile(
  candidateLanguageValidation
);

export const candidateLanguageArrayValidator = arrayValidatorCreator(
  candidateLanguageValidation
);
