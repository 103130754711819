import { z } from 'zod';

import { ExperienceSchema } from './experience';

export const AhqSubmissionCandidateExperienceSchema = ExperienceSchema.extend({
  experienceVisible: z.boolean().default(false),
  isHighlighted: z.boolean().default(false),
  submissionDescription: z.string().nullable(),
});

export type AhqSubmissionCandidateExperience = z.infer<
  typeof AhqSubmissionCandidateExperienceSchema
>;
