export const WeekdayConst = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
} as const;

const WeekdayNames = Object.keys(WeekdayConst) as Array<
  keyof typeof WeekdayConst
>;

export const WeekdayToName = (
  day: (typeof WeekdayConst)[keyof typeof WeekdayConst]
) => WeekdayNames.find(d => WeekdayConst[d] === day);
