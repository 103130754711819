import {
  Candidate,
  Taxonomy,
  TaxonomyFocusAreaEnum,
  TaxonomyPracticeAreaEnum,
  TaxonomySkillGroupEnum,
} from '@axiom/validation';
import { ProfessionConst } from '@axiom/const';

export const TaxonomyUtil = {
  getGeneralSkillEnums: (generalSkills: Taxonomy['generalSkills']) => {
    return Object.keys(generalSkills).sort();
  },

  getGeneralSkillOptions: (generalSkills: Taxonomy['generalSkills']) => {
    return TaxonomyUtil.getGeneralSkillEnums(generalSkills).map(key => {
      const generalSkill = generalSkills[
        key
      ] as Taxonomy['generalSkills'][string];
      return {
        label: generalSkill.displayName,
        value: key,
      };
    });
  },

  getIndustryEnums: (industries: Taxonomy['industries']) => {
    return Object.keys(industries).sort();
  },

  getIndustryOptions: (industries: Taxonomy['industries']) => {
    return TaxonomyUtil.getIndustryEnums(industries).map(key => {
      const industry = industries[key] as Taxonomy['industries'][string];
      return {
        label: industry.displayValue,
        value: key,
      };
    });
  },

  getFilteredIndustryOptions: (industries: Taxonomy['industries']) => {
    return TaxonomyUtil.getIndustryEnums(industries)
      .filter(opt => opt !== 'NA')
      .map(key => {
        const industry = industries[key] as Taxonomy['industries'][string];
        return {
          label: industry.displayValue,
          value: key,
        };
      });
  },

  getFilteredIntakeFormIndustryOptions: (
    industries: Taxonomy['industries']
  ) => {
    return TaxonomyUtil.getIndustryEnums(industries)
      .filter(opt => opt !== 'NA' && opt !== 'Other')
      .map(key => {
        const industry = industries[key] as Taxonomy['industries'][string];
        return {
          label: industry.displayValue,
          value: key,
        };
      });
  },

  getFocusAreaEnumsByPracticeAreaEnumForCandidate: (
    taxonomy: Taxonomy,
    candidate: Candidate
  ) => {
    const isAlp = ProfessionConst.other.occupationTypes.includes(
      candidate.occupationType
    );
    return Object.entries(taxonomy.focusAreas).reduce(
      (acc, [focusArea, { practiceArea, alpEligible }]) => ({
        ...acc,
        ...(!isAlp || alpEligible
          ? {
              [practiceArea]: [...(acc[practiceArea] ?? []), focusArea],
            }
          : {}),
      }),
      {} as Record<TaxonomyPracticeAreaEnum, TaxonomyFocusAreaEnum[]>
    );
  },

  getPracticeAreaEnums: (practiceAreas: Taxonomy['practiceAreas']) => {
    return Object.keys(practiceAreas);
  },

  getPracticeAreaOptions: (practiceAreas: Taxonomy['practiceAreas']) => {
    return TaxonomyUtil.getPracticeAreaEnums(practiceAreas).map(key => {
      const practiceArea = practiceAreas[
        key
      ] as Taxonomy['practiceAreas'][string];
      return {
        label: practiceArea.fullName,
        value: key,
      };
    });
  },

  getFocusAreaEnums: (focusAreas: Taxonomy['focusAreas']) => {
    return Object.keys(focusAreas);
  },

  getFocusAreaOptions: (
    focusAreas: Taxonomy['focusAreas'],
    practiceAreas: Taxonomy['practiceAreas']
  ) => {
    return TaxonomyUtil.getFocusAreaEnums(focusAreas).map(key => {
      const focusArea = focusAreas[key] as Taxonomy['focusAreas'][string];
      return {
        label: `${
          TaxonomyUtil.getPracticeAreaOptions(practiceAreas).find(
            practiceArea => practiceArea.value === focusArea.practiceArea
          )?.label
        } > ${focusArea.fullName}`,
        value: key,
      };
    });
  },

  getFocusAreaSkillGroups: (
    focusArea: string,
    skillGroups: Taxonomy['skillGroups']
  ) => {
    return TaxonomyUtil.getSkillGroupEnums(skillGroups)
      .map(key => ({
        key,
        ...skillGroups[key],
      }))
      .filter(skillGroup => skillGroup.focusArea === focusArea);
  },

  getSkillGroupEnums: (skillGroups: Taxonomy['skillGroups']) => {
    return Object.keys(skillGroups);
  },

  getLegalSkillEnums: (legalSkills: Taxonomy['legalSkills']) => {
    return Object.keys(legalSkills);
  },

  getLegalSkillOptions: (
    legalSkills: Taxonomy['legalSkills'],
    skillGroup: string
  ) => {
    return TaxonomyUtil.getLegalSkillEnums(legalSkills)
      .map(key => ({
        key,
        ...legalSkills[key],
      }))
      .filter(legalSkill => legalSkill.skillGroup === skillGroup)
      .map(legalSkill => ({
        label: legalSkill.fullName,
        value: legalSkill.key,
      }));
  },

  getLegalTechSkillEnums: (legalTechSkills: Taxonomy['legalTechSkills']) => {
    return Object.keys(legalTechSkills);
  },

  getLegalTechSkillOptions: (legalTechSkills: Taxonomy['legalTechSkills']) => {
    return TaxonomyUtil.getLegalTechSkillEnums(legalTechSkills).map(key => {
      const legalTechSkill = legalTechSkills[
        key
      ] as Taxonomy['legalTechSkills'][string];
      return {
        label: legalTechSkill.displayName,
        value: key,
      };
    });
  },

  getLegalTechSkillCategoryEnums: (
    legalTechSkillCategories: Taxonomy['legalTechSkillCategories']
  ) => {
    return Object.keys(legalTechSkillCategories);
  },

  getLegalTechSkillByCategoryOptions: (
    legalTechSkills: Taxonomy['legalTechSkills'],
    legalTechSkillCategories: Taxonomy['legalTechSkillCategories']
  ) => {
    const filterableLegalTechSkills = TaxonomyUtil.getLegalTechSkillEnums(
      legalTechSkills
    ).map(key => {
      const legalTechSkill = legalTechSkills[
        key
      ] as Taxonomy['legalTechSkills'][string];
      return {
        label: legalTechSkill.displayName,
        value: key,
        category: legalTechSkill.legalTechSkillCategory,
      };
    });

    return TaxonomyUtil.getLegalTechSkillCategoryEnums(
      legalTechSkillCategories
    ).map(key => {
      const legalSkillCategory = legalTechSkillCategories[
        key
      ] as Taxonomy['legalTechSkillCategories'][string];
      return {
        label: legalSkillCategory.displayName,
        value: key,
        choices: filterableLegalTechSkills
          .filter(legalTechSkill => legalTechSkill.category === key)
          .map(legalTechSkill => ({
            label: legalTechSkill.label,
            value: legalTechSkill.value,
          })),
      };
    });
  },

  getLegalSkillsBySkillGroup: (
    skillGroup: TaxonomySkillGroupEnum,
    legalSkills: Taxonomy['legalSkills']
  ) => {
    return TaxonomyUtil.getLegalSkillEnums(legalSkills)
      .map(key => ({
        key,
        ...legalSkills[key],
      }))
      .filter(legalSkill => legalSkill.skillGroup === skillGroup);
  },
};
