export enum FocusArea {
  BankruptcyAndReorganization = 'BankruptcyAndReorganization',
  DebtCapitalMarkets = 'DebtCapitalMarkets',
  EquityCapitalMarkets = 'EquityCapitalMarkets',
  AdvertisingTechnology = 'AdvertisingTechnology',
  AdvertisingMarketingAndPromotionalReview = 'AdvertisingMarketingAndPromotionalReview',
  ClinicalTrials = 'ClinicalTrials',
  CloudSaaSSoftwareLicensing = 'CloudSaaSSoftwareLicensing',
  CommercialContracts = 'CommercialContracts',
  EnergyNuclear = 'EnergyNuclear',
  EnergyOilAndGas = 'EnergyOilAndGas',
  EnergyRenewable = 'EnergyRenewable',
  GovernmentContracts = 'GovernmentContracts',
  HospitalAndHealthcareProviders = 'HospitalAndHealthcareProviders',
  MedicalDevicesAndDigitalHealth = 'MedicalDevicesAndDigitalHealth',
  PharmaceuticalsAndBiotechnology = 'PharmaceuticalsAndBiotechnology',
  ProcurementAndOutsourcing = 'ProcurementAndOutsourcing',
  ProductCounsel = 'ProductCounsel',
  SupplyChainTransportation = 'SupplyChainTransportation',
  CorporateSecretaryAndServices = 'CorporateSecretaryAndServices',
  GovernanceRiskAndCompliance = 'GovernanceRiskAndCompliance',
  DataPrivacy = 'DataPrivacy',
  Cybersecurity = 'Cybersecurity',
  AssetFinanceAndLeasing = 'AssetFinanceAndLeasing',
  CommercialBanking = 'CommercialBanking',
  ConsumerBankingAndLending = 'ConsumerBankingAndLending',
  CorporateTrustsLendingAndBorrowing = 'CorporateTrustsLendingAndBorrowing',
  Derivatives = 'Derivatives',
  FinTechAndBlockchain = 'FinTechAndBlockchain',
  Funds = 'Funds',
  InvestmentAndWealthManagement = 'InvestmentAndWealthManagement',
  LeveragedFinance = 'LeveragedFinance',
  Payments = 'Payments',
  ProjectFinance = 'ProjectFinance',
  RealEstateFinance = 'RealEstateFinance',
  StructuredFinanceAndSecuritization = 'StructuredFinanceAndSecuritization',
  TradeCommoditiesAndExportFinance = 'TradeCommoditiesAndExportFinance',
  Insurance = 'Insurance',
  CopyrightsTrademarkTradeSecret = 'CopyrightsTrademarkTradeSecret',
  IPAndSoftwareLicensing = 'IPAndSoftwareLicensing',
  Patents = 'Patents',
  EmployeeBenefitsERISACompensationBenefitsAndPensions = 'EmployeeBenefitsERISACompensationBenefitsAndPensions',
  EmployeeInvestigations = 'EmployeeInvestigations',
  GeneralEmployment = 'GeneralEmployment',
  LaborRelations = 'LaborRelations',
  ContractManager = 'ContractManager',
  LegalAnalyst = 'LegalAnalyst',
  LegalOperations = 'LegalOperations',
  Paralegal = 'Paralegal',
  ProjectManager = 'ProjectManager',
  AlternativeDisputeResolution = 'AlternativeDisputeResolution',
  BankingAndFinanceLitigation = 'BankingAndFinanceLitigation',
  CommercialLitigation = 'CommercialLitigation',
  ConsumerLitigation = 'ConsumerLitigation',
  IntellectualPropertyLitigation = 'IntellectualPropertyLitigation',
  TaxControversyAndLitigation = 'TaxControversyAndLitigation',
  MAndADivestitures = 'MAndADivestitures',
  StrategicInvestmentsAndJointVentures = 'StrategicInvestmentsAndJointVentures',
  Commercial = 'Commercial',
  ConstructionPlanningAndDevelopment = 'ConstructionPlanningAndDevelopment',
  AntitrustAndCompetition = 'AntitrustAndCompetition',
  BriberyAndCorruption = 'BriberyAndCorruption',
  ConsumerProtectionRegulation = 'ConsumerProtectionRegulation',
  EnergyRegulationAndCompliance = 'EnergyRegulationAndCompliance',
  EnvironmentalSocialAndGovernanceCompliance = 'EnvironmentalSocialAndGovernanceCompliance',
  FinancialServicesComplianceAndRegulation = 'FinancialServicesComplianceAndRegulation',
  HealthcareAndLifeSciencesRegulationAndCompliance = 'HealthcareAndLifeSciencesRegulationAndCompliance',
  InternationalTradeAndSanctionsInvestmentComplianceAndMarketAccess = 'InternationalTradeAndSanctionsInvestmentComplianceAndMarketAccess',
  RegulatoryInvestigationsAndEnforcement = 'RegulatoryInvestigationsAndEnforcement',
  Tax = 'Tax',
  ArtificialIntelligence = 'ArtificialIntelligence',
  GeneralCounselServices = 'GeneralCounselServices',
}
