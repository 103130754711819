const CandidateProfileIssue = {
  Missing: 'Missing',
  LengthShort: 'LengthShort',
  LengthLong: 'LengthLong',
  Content: 'Content',
  Outdated: 'Outdated',
} as const;

const CandidateProfileIssueExperience = {
  AxiomBlankDescription: 'AxiomBlankDescription',
  MissingIndustry: 'MissingIndustry',
  NonAxiomMissing: 'NonAxiomMissing',
  MissingTaxonomy: 'MissingTaxonomy',
  ShortOrOldExperienceMissingDescription:
    'ShortOrOldExperienceMissingDescription',
  LongAndRecentExperienceMissingDescription:
    'LongAndRecentExperienceMissingDescription',
  ShortOrOldExperienceDescriptionTooShort:
    'ShortOrOldExperienceDescriptionTooShort',
  LongAndRecentExperienceDescriptionTooShort:
    'LongAndRecentExperienceDescriptionTooShort',
  ShortOngoingExperienceMissingOrDescriptionTooShort:
    'ShortOngoingExperienceMissingOrDescriptionTooShort',
  LongOngoingExperienceMissingOrDescriptionTooShort:
    'LongOngoingExperienceMissingOrDescriptionTooShort',
} as const;

export type CandidateProfileIssueExperienceValue =
  (typeof CandidateProfileIssueExperience)[keyof typeof CandidateProfileIssueExperience];

const CandidateProfileIssueName = {
  AllCaps: 'AllCaps',
  Qualifications: 'Qualifications',
  Titles: 'Titles',
  Nicknames: 'Nicknames',
} as const;

const CandidateProfileIssuePreference = {
  WorkStyle: 'work-style',
  ProfileVisibility: 'profile-visibility',
  Programs: 'programs',
} as const;

const CandidateProfileCategory = {
  Accomplishments: 'Accomplishments',
  Availability: 'Availability',
  Education: 'Education',
  Experience: 'Experience',
  Languages: 'Languages',
  LegalTechSkills: 'LegalTechSkills',
  Name: 'Name',
  PracticeStartYear: 'PracticeStartYear',
  Preferences: 'Preferences',
  Summary: 'Summary',
} as const;

export const CandidateProfileIssueConst = {
  CandidateProfileIssue,
  CandidateProfileIssueExperience,
  CandidateProfileIssueName,
  CandidateProfileIssuePreference,
  CandidateProfileCategory,
} as const;
