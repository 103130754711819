import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

export const CandidatesSavedOpportunitySchema = z.object({
  candidateId: z.string().uuid(),
  id: z.string().uuid(),
  opportunityId: z.string().uuid(),
});

export type CandidatesSavedOpportunity = z.infer<
  typeof CandidatesSavedOpportunitySchema
>;

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

const candidatesSavedOpportunitiesValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    opportunity: {
      type: 'string',
      format: 'uuid',
    },
    candidate: {
      type: 'string',
      format: 'uuid',
    },
  },
};

export const candidatesSavedOpportunitiesValidator = ajv.compile(
  candidatesSavedOpportunitiesValidation
);
