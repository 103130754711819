import React from 'react';

import NoResults from '../../../public/static/svg/man-walking.svg';
import { Layout } from '../../layout/Layout/Layout';
import { LargeHeader } from '../../content/LargeHeader/LargeHeader';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Visible } from '../../layout/Visible/Visible';

export const ClientEngagementSelfServiceNoTalentMessage = () => {
  return (
    <Layout
      background="panelLight"
      boxShadow="default"
      direction="horizontal"
      name="self-service-no-matches"
      borderRadius="16px"
    >
      <Visible only="largeScreen tablet" name="no-instant-matches-graphic">
        <NoResults width={256} height={256} />
      </Visible>
      <Gutter vertical="32px" horizontal="24px">
        <LargeHeader>No matches? Help is on the way.</LargeHeader>
        <Gutter right="32px" top="16px">
          <Paragraph>
            We'll reach out within 1 business day or sooner to discuss your
            needs and find the perfect match.
          </Paragraph>
        </Gutter>
      </Gutter>
    </Layout>
  );
};
