import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import isEqual from 'lodash/isEqual';
import withDragAndDrop, {
  EventInteractionArgs,
} from 'react-big-calendar/lib/addons/dragAndDrop';
import {
  Calendar as BigCalendar,
  Culture,
  DateLocalizer,
  DateRange,
  momentLocalizer,
  Views,
} from 'react-big-calendar';
import {
  CalendarEventType,
  CalendarComponentEvent,
  CalendarPermissions,
} from '@axiom/validation';

import { CalendarPermissionsUtil } from '../../../utils/calendar-permissions-util';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { useOnDependencyUpdate } from '../../../hooks/useOnDependencyUpdate';
import { useInternalEventContext } from '../../../providers/InternalEventContext';

import { CalendarEventsUtil } from './calendar-events-util';
import { CalendarTimeSlot } from './CalendarTimeSlot';
import { useMergeEvents } from './useCalendarMergeEvents';
import { useCalendarAccessors } from './useCalendarAccessors';
import { CalendarEvent } from './CalendarEvent';
import { CalendarToolbar } from './CalendarToolbar';
import { CalendarDayColumn } from './CalendarDayColumn';
import { CalendarDeleteEventModal } from './CalendarDeleteEventModal';
import { EventStates } from './calendar-events-const';

// REQUIRED: DON'T REMOVE ======= START
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
// REQUIRED: DON'T REMOVE ======= END

const allZones = moment.tz.names();
const DnDCalendar = withDragAndDrop<CalendarComponentEvent>(BigCalendar);

export const TIME_FORMATS = {
  MONTH_DAY: 'MMMM D',
  MONTH_ABBR_DAY: 'ddd M/DD',
  HOUR_MINS: 'hh:mm a',
};

type UpdateEventArgType = Omit<
  EventInteractionArgs<CalendarComponentEvent>,
  'start' | 'end'
> & {
  start: CalendarComponentEvent['start'];
  end: CalendarComponentEvent['end'];
};

type TimeConfig = {
  increments?: number; // smallest selectable time
  slots?: number; // slots per section*
};

/* section = increments * slots
 * 1 * 60  = 1hr sections with 1hr selectable events
 * 2 * 30 =  1hr sections with 30min selectable events
 * */

export type CalendarProps = {
  backgroundEvents?: CalendarEventType[];
  dayMode?: TimeConfig | null;
  displayDate?: Date;
  events: CalendarEventType[];
  height?: string;
  name: string;
  onChange?: (data: CalendarEventType[], bgDate: CalendarEventType[]) => void;
  permissions: CalendarPermissions;
  scrollToTime?: Date;
  startMode?: typeof Views.WEEK | typeof Views.WORK_WEEK | typeof Views.DAY;
  timezone?: (typeof allZones)[number];
  weekMode?: TimeConfig | null;
};

export const RawCalendar = ({
  backgroundEvents,
  dayMode,
  displayDate,
  events,
  height = '600px',
  name,
  onChange,
  permissions,
  scrollToTime,
  startMode = Views.WEEK,
  timezone,
  weekMode,
}: CalendarProps) => {
  backgroundEvents = backgroundEvents || [];
  events = events || [];
  displayDate = displayDate || new Date();

  const { isMobile } = useBreakpoint();
  const perms = isMobile
    ? CalendarPermissionsUtil().getBasePermissions()
    : permissions;
  const internalEventContext = useInternalEventContext();
  const timesConfig = useMemo(() => weekMode || dayMode, [weekMode, dayMode]);
  const mergeEvents = useMergeEvents();
  const bgEventIdsSet = new Set<CalendarEventType['id']>();
  const [selectedEvent, setSelectedEvent] =
    useState<CalendarComponentEvent | null>(null);
  const [timeZoneChanged, setTimeZoneChanged] = useState(false);
  const [eventData, setEventData] = useState(
    CalendarEventsUtil.cleanForegroundEventsForState(events, perms)
  );
  const [backgroundEventData, setBackgroundEventData] = useState(
    CalendarEventsUtil.cleanBackgroundEventsForState(
      backgroundEvents,
      perms,
      bgEventIdsSet
    )
  );

  useOnDependencyUpdate(() => {
    const data = CalendarEventsUtil.cleanForegroundEventsForState(
      events,
      perms
    );

    // "isEqual" to prevent render looping
    if (!isEqual(data, eventData)) {
      setEventData(data);
    }
  }, [events]);

  useOnDependencyUpdate(() => {
    const data = CalendarEventsUtil.cleanBackgroundEventsForState(
      backgroundEvents,
      perms,
      bgEventIdsSet
    );

    // "isEqual" to prevent render looping
    if (!isEqual(data, backgroundEvents)) {
      setBackgroundEventData(data);
    }
  }, [backgroundEvents]);

  const { handleDraggableAccessor, handleResizableAccessor } =
    useCalendarAccessors(perms);

  const { appLocalizer, defaultDate, toTime } = useMemo(() => {
    const invalidMomentDays = new Set([5, 6]); // Fri, Sat
    moment.tz.setDefault(timezone);

    return {
      defaultDate: invalidMomentDays.has(moment(displayDate).weekday())
        ? moment().startOf('week').add(1, 'week').toDate()
        : moment(displayDate).toDate(),
      appLocalizer: momentLocalizer(moment),
      toTime: scrollToTime || moment().subtract(1, 'hour').toDate(),
    };
  }, [timezone]);

  // https://github.com/jquense/react-big-calendar/blob/master/stories/demos/exampleCode/rendering.js
  const { formats, components } = useMemo(
    () => ({
      formats: {
        dayRangeHeaderFormat: (
          { start, end }: DateRange,
          culture?: Culture,
          localizer?: DateLocalizer
        ) => {
          const sameMonth = moment(start).month() === moment(end).month();
          const startFormat = TIME_FORMATS.MONTH_DAY;
          const endFormat = sameMonth ? 'D' : startFormat;

          return `${localizer?.format(start, TIME_FORMATS.MONTH_DAY, culture)}${
            sameMonth ? '-' : ' - '
          }${localizer?.format(end, endFormat, culture)}`;
        },
        dayFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer) =>
          localizer?.format(date, TIME_FORMATS.MONTH_ABBR_DAY, culture) || '',
        eventTimeRangeFormat: (
          { start, end }: { start: Date; end: Date },
          culture?: Culture,
          localizer?: DateLocalizer
        ) =>
          `${localizer?.format(
            start,
            TIME_FORMATS.HOUR_MINS,
            culture
          )} - ${localizer?.format(end, TIME_FORMATS.HOUR_MINS, culture)}`,
      },
      components: {
        toolbar: CalendarToolbar,
        event: CalendarEvent,
        timeSlotWrapper: CalendarTimeSlot,
        dayColumnWrapper: CalendarDayColumn,
      },
    }),
    []
  );

  useEffect(() => {
    return () => {
      moment.tz.setDefault(); // reset to browser TZ on unmount
    };
  }, []);

  useOnDependencyUpdate(() => {
    /**
     * This is not run on the 'on mount' render cycle
     * since it is only needed if "eventData" changes
     * aka a new or modified event from the user
     * */
    onChange?.(
      [...CalendarEventsUtil.translateToStartTimeEndTimeProps(eventData)],
      [
        ...CalendarEventsUtil.translateToStartTimeEndTimeProps(
          backgroundEventData
        ),
      ]
    );
  }, [eventData]);

  useOnDependencyUpdate(() => {
    /**
     * This is not run on the 'on mount' render cycle
     * since it is only needed if "eventData" changes
     * aka a new or modified event from the user
     * */
    if (!timeZoneChanged) {
      setEventData(prevState => {
        return prevState.map(event => ({
          ...event,
          state:
            event.state === EventStates.SAVED
              ? EventStates.MODIFIED
              : event.state,
        }));
      });
      setTimeZoneChanged(true);
    }
  }, [timezone]);

  const eventPropGetter = useCallback(
    (event: CalendarComponentEvent) => {
      const data = {
        className: 'nonDraggable',
      };

      if (event.busy) {
        data.className = `staticEvent ${
          perms.staticEvent.canMove ? 'isDraggable' : 'nonDraggable'
        }`;
      } else if (!event.busy) {
        data.className = perms.eventBoundary.canMove
          ? 'isDraggable'
          : 'nonDraggable';
      }

      if (event.error) {
        data.className += ' error';
      }

      return data;
    },
    [perms]
  );

  const slotPropGetter = useCallback(
    (date: Date) => {
      const inThePast = CalendarEventsUtil.isInThePast(date, perms);

      return { className: inThePast ? 'inactive-time-block' : '' };
    },
    [perms]
  );

  const handleSelectSlot = useCallback(
    (props: {
      start: Date;
      end: Date;
      action: 'select' | 'click' | 'doubleClick';
    }): null => {
      let lonelyStrictEvent = false;
      if (
        perms.staticEvent.canCreate &&
        !perms.staticEvent.canDragToCreate &&
        (props.action === 'select' || props.action === 'doubleClick')
      ) {
        return null;
      }

      const canCreateStaticEvent =
        perms.staticEvent.canCreate || perms.staticEvent.canDragToCreate;
      const newEvent: CalendarComponentEvent = {
        id: `UNSAVED_${Math.round(Math.random() * 1e17) + 1e17}`,
        title: canCreateStaticEvent ? 'Interview' : '',
        start: props.start,
        end: props.end,
        busy: canCreateStaticEvent,
        error: null,
        state: EventStates.NEW,
      };

      if (newEvent.busy) {
        const dataEvents: CalendarComponentEvent[] =
          backgroundEventData.length > 0 ? backgroundEventData : eventData;
        let eventBoundaryEvent: boolean = false;
        eventBoundaryEvent = dataEvents.some(abe => {
          return (
            moment(newEvent.start).isBetween(
              abe.start,
              abe.end,
              undefined,
              '[]'
            ) &&
            moment(newEvent.end).isBetween(abe.start, abe.end, undefined, '[]')
          );
        });

        if (!perms.staticEvent.canCreateFreely && !eventBoundaryEvent) {
          // staticEvent is not within the free time
          return null;
        }

        lonelyStrictEvent =
          !eventBoundaryEvent && perms.staticEvent.canCreateFreely;
      } else if (
        !newEvent.busy &&
        !CalendarEventsUtil.canAddEvent(perms, newEvent.start.toISOString())
      ) {
        return null;
      }

      setEventData(prevState => {
        const unsavedStaticEvent = prevState.find(
          event => event.busy && event.state !== EventStates.SAVED
        );
        const data =
          !perms.staticEvent.canCreateMultiple && !!unsavedStaticEvent
            ? prevState.filter(pe => pe.id !== unsavedStaticEvent.id)
            : prevState;

        data.push(newEvent);

        return CalendarEventsUtil.setEventError(
          {
            foreground: mergeEvents(data),
            background: backgroundEventData,
          },
          perms
        ).filter(evnt => !bgEventIdsSet.has(evnt.id));
      });

      if (lonelyStrictEvent) {
        setBackgroundEventData(prevState => {
          return mergeEvents([
            ...prevState,
            {
              id: `UNSAVED_${Math.round(Math.random() * 1e17) + 1e17}`,
              title: '',
              name: 'BACKGROUND_EVENT',
              start: props.start,
              end: props.end,
              busy: false,
              error: null,
              state: EventStates.NEW,
            },
          ]);
        });
      }

      return null;
    },
    [eventData, backgroundEventData, perms]
  );

  const handleOnSelecting = useCallback(
    (range: { start: Date; end: Date }) => {
      return (
        !CalendarEventsUtil.isInThePast(range.end, perms) &&
        (perms.eventBoundary.canDragToCreate ||
          perms.staticEvent.canDragToCreate)
      );
    },
    [perms]
  );

  const updateEvent = useCallback(
    ({ event, start, end }: UpdateEventArgType) => {
      setEventData(prevState => {
        let movedEvent = false;

        const toBeMerged = prevState.reduce((allEvents, calEvent) => {
          const data: CalendarComponentEvent = {
            ...calEvent,
            error: null,
          };

          if (data.id === event.id) {
            // modifying an existing event
            data.start = start;
            data.end = end;
            data.state =
              data.state === EventStates.SAVED
                ? EventStates.MODIFIED
                : data.state;
            movedEvent = true;
          }

          allEvents.push(data);

          return allEvents;
        }, [] as CalendarComponentEvent[]);

        if (!movedEvent) {
          toBeMerged.push(event);
        }

        if (backgroundEventData.length > 0) {
          // need the background events for error checking only
          return CalendarEventsUtil.setEventError(
            {
              foreground: mergeEvents(toBeMerged),
              background: backgroundEventData,
            },
            perms
          ).filter(data => !bgEventIdsSet.has(data.id));
        }

        return CalendarEventsUtil.setEventError(
          { foreground: mergeEvents(toBeMerged) },
          perms
        );
      });
    },
    [eventData, backgroundEventData, perms]
  );

  const handleOnEventDrop = updateEvent;

  const handleOnEventResize = updateEvent;

  const onSelectHandler = (ev: CalendarComponentEvent) => {
    if (
      ev.isBackgroundEvent ||
      (ev.state === EventStates.SAVED &&
        ev.busy &&
        !perms.staticEvent.canDeleteSaved)
    ) {
      return;
    }

    setSelectedEvent(ev);
  };

  const onDeleteHandler = () => {
    setEventData(prevState => {
      return prevState.filter(ev => ev.id !== selectedEvent?.id);
    });

    setSelectedEvent(null);
  };

  /**
   * This is for Internal Unit Test event signalling
   * START
   */
  useEffect(() => {
    const sub = internalEventContext.calendarCreationEvent.subscribe(value => {
      const [convertedValue] =
        CalendarEventsUtil.translateFromStartTimeEndTimeProps([value]) as [
          CalendarComponentEvent,
        ];

      handleSelectSlot({
        start: convertedValue.start,
        end: convertedValue.end,
        action: 'click',
      });
    });

    return () => {
      sub.unsubscribe();
    };
  }, [perms]);
  /**
   * This is for Internal Unit Test event signalling
   * END
   */

  // Calendar will error if defaultView={startMode} is not one of the "views"
  return (
    <>
      <div data-test={name}>
        <DnDCalendar
          dayLayoutAlgorithm="no-overlap"
          onSelectEvent={onSelectHandler}
          defaultDate={defaultDate}
          defaultView={isMobile ? 'day' : startMode}
          events={eventData}
          localizer={appLocalizer}
          scrollToTime={toTime}
          views={[Views.WEEK, Views.WORK_WEEK, Views.DAY]}
          backgroundEvents={backgroundEventData}
          eventPropGetter={eventPropGetter}
          slotPropGetter={slotPropGetter}
          // @ts-expect-error This needs to be converted to proper type
          components={components}
          step={timesConfig?.increments}
          timeslots={timesConfig?.slots}
          style={{ height }}
          formats={formats}
          resizable={
            perms.staticEvent.canResize || perms.eventBoundary.canResize
          }
          selectable={
            perms.staticEvent.canCreate ||
            perms.eventBoundary.canCreate ||
            perms.staticEvent.canDragToCreate ||
            perms.eventBoundary.canDragToCreate
              ? 'ignoreEvents'
              : false
          }
          draggableAccessor={handleDraggableAccessor}
          resizableAccessor={handleResizableAccessor}
          onSelectSlot={handleSelectSlot}
          onSelecting={handleOnSelecting}
          onEventDrop={event => handleOnEventDrop(event as UpdateEventArgType)}
          onEventResize={event =>
            handleOnEventResize(event as UpdateEventArgType)
          }
          showMultiDayTimes
        />
      </div>
      {selectedEvent && (
        <CalendarDeleteEventModal
          permissions={perms}
          onDelete={onDeleteHandler}
          onClose={() => setSelectedEvent(null)}
          deleteEvent={selectedEvent}
        />
      )}
    </>
  );
};
