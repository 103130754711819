import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

export const ExpressInterestSchema = z
  .object({
    hasNoConflicts: z.boolean().refine(value => !!value, 'Required'),
    openToLowerEsc: z.boolean().default(false),
    openToLowerEscCompensationPercentage: z.number().nullish(),
    opportunityId: z.string().uuid(),
    roleFitDescription: z.string().trim().min(1, 'Required').max(1000),
  })
  .superRefine((schema, ctx) => {
    if (schema.openToLowerEsc && !schema.openToLowerEscCompensationPercentage) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['openToLowerEscCompensationPercentage'],
        message: 'Selection required if willing to discuss compensation.',
      });
    }

    return ctx;
  });

export type ExpressInterest = z.infer<typeof ExpressInterestSchema>;

const ajv = new Ajv(axiomValidationOptions());

const expressInterestValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    roleFitDescription: { type: 'string' },
    hasNoConflicts: { type: 'boolean', enum: [true] },
  },
  required: ['roleFitDescription', 'hasNoConflicts'],
};

export const expressInterestValidator = ajv.compile(expressInterestValidation);
