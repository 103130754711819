import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

export const AuthProvidersSchema = z.object({
  id: z.string().uuid(),
  organizationId: z.string().uuid().nullable(),
  type: z.string().min(1).nullable(),
  domain: z.string().min(1).nullable(),
  url: z.string().url().nullable(),
});

export type AuthProviders = z.infer<typeof AuthProvidersSchema>;

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

const authProviderValidation = {
  additionalProperties: false,
  type: 'object',
  properties: {
    type: {
      type: 'string',
      minLength: 1,
    },
    domain: {
      type: 'string',
      minLength: 1,
    },
    url: {
      type: 'string',
      format: 'url',
    },
  },
  required: ['type', 'domain', 'url'],
};

export const authProviderValidator = ajv.compile(authProviderValidation);
