import { z } from 'zod';

import { AfcSubmissionPositionSchema } from './afc-submission-position';
import { AfcSubmissionPublicCandidateOpportunitySchema } from './afc-submission-public-candidate-opportunity';

export const AfcSubmissionPublicPositionSchema =
  AfcSubmissionPositionSchema.pick({
    billingHoursPerDay: true,
    billingHoursPerWeek: true,
    candidateOpportunities: true,
    description: true,
    name: true,
    role: true,
  }).extend({
    candidateOpportunities: z.array(
      AfcSubmissionPublicCandidateOpportunitySchema
    ),
  });

export type AfcSubmissionPublicPosition = z.infer<
  typeof AfcSubmissionPublicPositionSchema
>;
