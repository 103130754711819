import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

const ajv = new Ajv(axiomValidationOptions());

export const ContactAccountSchema = z.object({
  accountId: z.string().uuid(),
  contactId: z.string().uuid(),
  id: z.string().uuid(),
});

export type ContactAccount = z.infer<typeof ContactAccountSchema>;

const contactAccountValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    accountId: {
      type: 'string',
      format: 'uuid',
    },
    contactId: {
      type: 'string',
      format: 'uuid',
    },
  },
};

export const contactAccountUpdateValidator = ajv.compile({
  ...contactAccountValidation,
});
