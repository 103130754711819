import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

const ajv = new Ajv(axiomValidationOptions());

export const HomeOfficeSchema = z.object({
  categories: z.array(z.string()),
  id: z.string().uuid(),
  isUSHomeOffice: z.boolean().default(false),
  maxDailyHours: z.number().positive().int(),
  maxHours: z.number().nonnegative().int(),
  name: z.string().max(255),
});

export type HomeOffice = z.infer<typeof HomeOfficeSchema>;

const homeOfficeValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
      maxLength: 255,
    },
    maxHours: {
      type: 'integer',
    },
  },
  required: ['name', 'maxHours'],
};

export const homeOfficeValidator = ajv.compile(homeOfficeValidation);
