import { z } from 'zod';
import { SchemaBadges } from '@axiom/types';

import { CandidateSchema } from './candidate';

export const LimitedCandidateSchema = CandidateSchema.extend({
  badges: SchemaBadges,
  restricted: z.boolean().nullish(),
});

export type LimitedCandidate = z.infer<typeof LimitedCandidateSchema>;
