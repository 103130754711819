import { isAStrictInteger } from './number';

export const ContactConst = {
  Expansions: {
    contactES: 'contactES',
  },
  maxSpvCount:
    process.env.MAX_CLIENT_SPV && isAStrictInteger(process.env.MAX_CLIENT_SPV)
      ? process.env.MAX_CLIENT_SPV
      : 25,
  spvAlertTrigger:
    process.env.SPV_ALERT_TRIGGER &&
    isAStrictInteger(process.env.SPV_ALERT_TRIGGER)
      ? process.env.SPV_ALERT_TRIGGER
      : 10,
} as const;
