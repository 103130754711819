import Ajv from 'ajv';
import { z } from 'zod';
import { AccountConst } from '@axiom/const';
import { ZodArrayToEnum } from '@axiom/types';

import { axiomValidationOptions } from './options';

export const AccountExpansionsExpands = Object.keys(
  AccountConst.Expansions
) as Array<keyof typeof AccountConst.Expansions>;

export const AccountExpansionSchema = z.object({
  expand: z.array(z.enum(ZodArrayToEnum(AccountExpansionsExpands))).nullish(),
});
export type AccountExpansion = z.infer<typeof AccountExpansionSchema>;

const ajv = new Ajv({
  ...axiomValidationOptions(),
});

// Validations
const accountExpansionsValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    expand: {
      type: 'array',
      items: {
        type: 'string',
        enum: Object.values(AccountExpansionsExpands),
      },
    },
  },
};

// Validators
export const accountExpansionsValidator = ajv.compile({
  ...accountExpansionsValidation,
});
