import moment from 'moment';
import { SchemaDate, SchemaLocation, ZodArrayToEnum } from '@axiom/types';
import { z } from 'zod';
import { PositionsConst } from '@axiom/const';

import {
  legalTeamSizeValues,
  levelOfExperienceValues,
  licensedLawyerValues,
  occupationTypeValues,
  positionReservedTypeValues,
} from './account-intake';

export const billingUnitValues = [5, 10, 15, 20, 25, 30, 35, 40] as const;

export const worksiteValues = {
  [PositionsConst.Worksites.Remote]: 'No, remote is fine',
  [PositionsConst.Worksites.OnsiteRemote]: 'Yes, periodically in our office',
  [PositionsConst.Worksites.Onsite]: 'Yes, full time in our office',
} as const;

export const selfServiceAdditionalDetailsForTalentMaxLength = 150;

export const levelOfExperienceAllowedValues = Object.keys(
  levelOfExperienceValues
) as Array<keyof typeof levelOfExperienceValues>;

export const licensedLawyerAllowedValues = Object.keys(
  licensedLawyerValues
) as Array<keyof typeof licensedLawyerValues>;

export const occupationTypeAllowedValues = Object.keys(
  occupationTypeValues
) as Array<keyof typeof occupationTypeValues>;

export const positionReservedTypeAllowedValues = Object.keys(
  positionReservedTypeValues
) as Array<keyof typeof positionReservedTypeValues>;

export const worksiteAllowedValues = Object.keys(worksiteValues) as Array<
  keyof typeof worksiteValues
>;

const legalTeamSizeAllowedValues = Object.values(legalTeamSizeValues);

export const ProjectNeedsDefaultRemoteLocation = {
  locationAddressComponents: {
    addresses: [
      {
        long_name: 'Default Remote Location',
        short_name: 'Default Remote Location',
        types: ['route', 'political'],
      },
      {
        long_name: 'Default Remote Location',
        short_name: 'Default Remote Location',
        types: ['locality', 'political'],
      },
      {
        long_name: 'Default Remote Location',
        short_name: 'NY',
        types: ['administrative_area_level_1', 'political'],
      },
      {
        long_name: 'United States',
        short_name: 'US',
        types: ['country', 'political'],
      },
    ],
  },
  locationLatitude: 40.7127753,
  locationLongitude: -74.0059728,
  locationName: 'Default Remote Location, USA',
  locationPlaceId: 'ChIJOwg_06VPwokRYv534QaPC8g',
};

export const ProjectNeedsSchema = z.object({
  additionalDetailsForTalent: z
    .string()
    .max(selfServiceAdditionalDetailsForTalentMaxLength)
    .nullish(),
  billingUnitsPerWeek: z.number().gte(5).lte(40),
  companyLocation: SchemaLocation.superRefine((val, context) => {
    const addresses = val?.locationAddressComponents?.addresses || [];

    const isUSLocation =
      addresses.filter(
        address =>
          address.types.includes('country') && address.short_name === 'US'
      )?.length > 0;

    const hasStreetAddress =
      addresses.filter(address => address.types.includes('route'))?.length > 0;

    if (!isUSLocation || !hasStreetAddress) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Must be a US address',
      });
    }
  }),
  endDate: SchemaDate,
  generalSkills: z.array(z.string()).nullish(),
  industry: z.string().nullish(),
  languageId: z.string().uuid().nullish(),
  legalSkills: z.array(z.string()).nullish(),
  legalTeamSize: z.enum(ZodArrayToEnum(legalTeamSizeAllowedValues)).nullish(),
  legalTechSkills: z.array(z.string()).nullish(),
  levelOfExperience: z.enum(ZodArrayToEnum(levelOfExperienceAllowedValues)),
  licensedLawyer: z.enum(ZodArrayToEnum(licensedLawyerAllowedValues)),
  licensedLawyerState: z.string(),
  maxBudgetPerHour: z
    .number()
    .positive()
    .gte(101, { message: 'Must be more than $100' })
    .max(99999999, { message: 'Must be less than $99,999,999' }),
  minBudgetPerHour: z
    .number()
    .positive()
    .gte(1, { message: 'Enter a number over $0' })
    .max(99999999, { message: 'Must be less than $99,999,999' }),
  occupationType: z.enum(ZodArrayToEnum(occupationTypeAllowedValues)),
  otherFocusAreas: z.array(z.string()).nullish(),
  primaryFocusArea: z.string(),
  reservedType: z.enum(ZodArrayToEnum(positionReservedTypeAllowedValues)),
  startDate: SchemaDate.superRefine((value, ctx) => {
    if (moment().isAfter(moment(value), 'day')) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Must be today or after',
      });
    }
  }),
  worksite: z.enum(ZodArrayToEnum(worksiteAllowedValues)),
});

export type ProjectNeeds = z.infer<typeof ProjectNeedsSchema>;
