import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import {
  Button,
  CondensedSmall,
  CookieUtil,
  Form,
  Gutter,
  Input,
  Dropdown,
  Grid,
  GridRow,
  GridColumn,
  SmallHeader,
  Paragraph,
  AxiomLogo,
} from '@axiom/ui';
import {
  CountryCodesDropdownOptions,
  CountryCodeAddressCountryValues,
  CountryCodeValues,
} from '@axiom/const';
import { MfaPhoneNumberSchema } from '@axiom/validation';
import { ZodArrayToEnum } from '@axiom/types';

import sideImageUrl from '../public/svg/fingerprint.svg?url';
import { WindowUtil } from '../utils/WindowUtil';
import { ClientLoginTwoColumnLayout } from '../layouts/ClientLogin/ClientLoginTwoColumnLayout';
import { UsersApi } from '../api/auth-users-api';
import { LoginContext } from '../components/LoginContext/LoginContext';

const { getUrlWithRelayState } = WindowUtil;

const TwoFactorFormSchema = z
  .object({
    countryCodeAddress: z.enum(ZodArrayToEnum(CountryCodeAddressCountryValues)),
    countryCode: z.enum(ZodArrayToEnum(CountryCodeValues)),
    phoneNumber: z
      .string()
      .regex(/^[\d-.\s()\u202A-\u202E]+$/, {
        message: 'Please add a valid phone number',
      })
      .transform(value => value.replaceAll(/\D/g, '')),
  })
  .refine(
    value => {
      const concatString = `${value.countryCode}${value.phoneNumber}`;
      return concatString.length >= 6 && concatString.length <= 14
        ? value
        : null;
    },
    {
      message: 'Please add a valid phone number',
      path: ['phoneNumber'],
    }
  );

export const TwoFactorAuthSetupPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loginState, setLoginState, isInvalidMfaState } =
    useContext(LoginContext);

  useEffect(() => {
    if (isInvalidMfaState) {
      navigate(
        getUrlWithRelayState('/', {
          location,
        })
      );
    }
  }, [location]);

  return (
    <ClientLoginTwoColumnLayout sideImageUrl={sideImageUrl}>
      <Grid centered>
        <GridRow>
          <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
            <Gutter bottom="24px" className="text-align-center">
              <AxiomLogo width="230px" />
            </Gutter>
            <Gutter bottom="8px">
              <SmallHeader>Set up two-factor authentication</SmallHeader>
            </Gutter>
            <Gutter bottom="24px">
              <Paragraph>
                Provide a mobile phone number we can use to verify it's you.
                Please enter this number with no dashes, spaces, or parentheses.
              </Paragraph>
            </Gutter>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
            <Form
              name="TWO_FACTOR_AUTH_SETUP_FORM"
              schema={TwoFactorFormSchema}
              initialValues={{
                countryCodeAddress: undefined,
                countryCode: undefined,
                phoneNumber: undefined,
              }}
              onSubmit={async formData => {
                const { id } = CookieUtil.decodeUserCookie();

                setLoginState({
                  ...loginState,
                  countryCode: formData.countryCode,
                  phoneNumber: formData.phoneNumber,
                });

                await UsersApi.updateMfaPhoneNumber(
                  id,
                  MfaPhoneNumberSchema.parse({
                    countryCode: formData.countryCode,
                    phoneNumber: formData.phoneNumber,
                  })
                );

                navigate(
                  getUrlWithRelayState(`/two-factor-confirm`, { location })
                );
              }}
            >
              {({ fireSubmit, setValues, values }) => {
                return (
                  <>
                    <Gutter bottom="16px">
                      <Dropdown
                        name="countryCodeAddress"
                        label="Country code"
                        options={CountryCodesDropdownOptions}
                        displayKey="display"
                        valueKey="addressCountryCode"
                        onChange={v => {
                          const countryCode = CountryCodesDropdownOptions.find(
                            item => item.addressCountryCode === v
                          );
                          setValues({
                            ...values,
                            countryCode: countryCode?.value,
                          });
                        }}
                      />
                      <Gutter bottom="8px" />
                      <Input name="phoneNumber" label="Mobile phone number" />
                    </Gutter>
                    <Gutter bottom="16px">
                      <CondensedSmall name="2FA_TERMS">
                        By continuing, you authorize Axiom to send security
                        codes and other Axiom communications via SMS. Carrier
                        fees may apply. You can update your communication
                        preferences at any time.
                      </CondensedSmall>
                    </Gutter>
                    <Gutter bottom="16px">
                      <Button fluid onClick={fireSubmit} name="continue-button">
                        Continue
                      </Button>
                    </Gutter>
                    <Button
                      fluid
                      onClick={() => {
                        navigate(getUrlWithRelayState(`/sso`, { location }));
                      }}
                      name="maybe-later-button"
                      inverse
                      variation="minimal"
                    >
                      Maybe Later
                    </Button>
                  </>
                );
              }}
            </Form>
          </GridColumn>
        </GridRow>
      </Grid>
    </ClientLoginTwoColumnLayout>
  );
};
