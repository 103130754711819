import { z } from 'zod';
import { SchemaDate, ZodArrayToEnum } from '@axiom/types';
import { OpportunitiesConst } from '@axiom/const';

const AfcAccountSubmissionProjectStates = Object.values(
  OpportunitiesConst.ProjectStates
);

export const AfcAccountSubmissionProjectStateDetailsSchema = z.object({
  endDate: SchemaDate.nullable(),
  startDate: SchemaDate,
  state: z.enum(ZodArrayToEnum(AfcAccountSubmissionProjectStates)),
});

export type AfcAccountSubmissionProjectStateDetails = z.infer<
  typeof AfcAccountSubmissionProjectStateDetailsSchema
>;
