import { z } from 'zod';
import { SchemaPassword } from '@axiom/types';

export const ClientResetPasswordFormSchema = z.object({
  password: SchemaPassword,
});

export type ClientResetPasswordFormData = z.infer<
  typeof ClientResetPasswordFormSchema
>;
