import { OpportunitiesConst } from './opportunity';

const { Stages } = OpportunitiesConst;

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_STAGE_FILTER = [Stages.Craft, Stages.Compete];
const DEFAULT_SORT = 'createdAt';

export const CandidateOpportunityFeedConst = {
  DEFAULT_PAGE_SIZE,
  DEFAULT_STAGE_FILTER,
  DEFAULT_SORT,
} as const;
