export const LegalTechSkillsProficiencyKeys = {
  None: 'None',
  SomeFamiliarity: 'SomeFamiliarity',
  HighProficiency: 'HighProficiency',
} as const;

export const LegalTechSkillsProficiencies = Object.values(
  LegalTechSkillsProficiencyKeys
);

export const TaxonomyConst = {
  LegalTechSkillsProficiencies: {
    [LegalTechSkillsProficiencyKeys.None]: 'None', // does not come from or go to the api (for now)
    [LegalTechSkillsProficiencyKeys.SomeFamiliarity]: 'SomeFamiliarity',
    [LegalTechSkillsProficiencyKeys.HighProficiency]: 'HighProficiency',
  },
  LegalTechSkillsProficienciesOptions: [
    {
      label: 'None',
      value: LegalTechSkillsProficiencyKeys.None,
    },
    {
      label: 'Some',
      value: LegalTechSkillsProficiencyKeys.SomeFamiliarity,
    },
    {
      label: 'High',
      value: LegalTechSkillsProficiencyKeys.HighProficiency,
    },
  ],
  LimitedLegalTechSkillsProficienciesOptions: [
    {
      label: 'High only',
      value: LegalTechSkillsProficiencyKeys.HighProficiency,
    },
  ],
} as const;
