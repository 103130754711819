export const CountryCodes = {
  AO: 'Angola',
  AR: 'Argentina',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BE: 'Belgium',
  BU: 'Bermuda',
  BA: 'Bosnia and Herzegovina',
  BR: 'Brazil',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  KH: 'Cambodia',
  CA: 'Canada',
  CL: 'Chile',
  CN: 'China',
  CO: 'Colombia',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  EG: 'Egypt',
  FI: 'Finland',
  FR: 'France',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GB: 'Great Britain',
  GR: 'Greece',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IN: 'India',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IT: 'Italy',
  JP: 'Japan',
  JE: 'Jersey',
  KE: 'Kenya',
  KR: 'Korea; Republic Of (South)',
  LV: 'Latvia',
  LB: 'Lebanon',
  LU: 'Luxembourg',
  MO: 'Macau',
  MY: 'Malaysia',
  MT: 'Malta',
  MU: 'Mauritius',
  MX: 'Mexico',
  MJ: 'Montenegro',
  NL: 'Netherlands',
  NZ: 'New Zealand',
  NG: 'Nigeria',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PH: 'Philippines',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russian Federation',
  BL: 'Saint Barthélemy',
  RI: 'Serbia',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  ZA: 'South Africa',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SE: 'Sweden',
  CH: 'Switzerland',
  TW: 'Taiwan',
  TH: 'Thailand',
  TT: 'Trinidad and Tobago',
  TR: 'Turkey',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  UK: 'United Kingdom',
  US: 'United States',
  VN: 'Vietnam',
} as const;

export const CountryCodesDropdownOptions = [
  { display: 'United States +1', value: '1', addressCountryCode: 'US' },
  { display: 'United Kingdom +44', value: '44', addressCountryCode: 'UK' },
  { display: 'Canada +1', value: '1', addressCountryCode: 'CA' },
  { display: 'Singapore +65', value: '65', addressCountryCode: 'SG' },
  { display: 'Hong Kong +852', value: '852', addressCountryCode: 'HK' },
  { display: 'Germany +49', value: '49', addressCountryCode: 'DE' },
  { display: 'Switzerland +41', value: '41', addressCountryCode: 'CH' },
  { display: 'Australia +61', value: '61', addressCountryCode: 'AU' },
  { display: 'Afghanistan +93', value: '93', addressCountryCode: 'AF' },
  { display: 'Albania +355', value: '355', addressCountryCode: 'AL' },
  { display: 'Algeria +213', value: '213', addressCountryCode: 'DZ' },
  { display: 'American Samoa +1', value: '1', addressCountryCode: 'AS' },
  { display: 'Andorra +376', value: '376', addressCountryCode: 'AD' },
  { display: 'Angola +244', value: '244', addressCountryCode: 'AO' },
  { display: 'Anguilla +1', value: '1', addressCountryCode: 'AI' },
  { display: 'Antartica +672', value: '672', addressCountryCode: 'AQ' },
  { display: 'Antigua and Barbuda +1', value: '1', addressCountryCode: 'AG' },
  { display: 'Argentina +54', value: '54', addressCountryCode: 'AR' },
  { display: 'Armenia +374', value: '374', addressCountryCode: 'AM' },
  { display: 'Aruba +297', value: '297', addressCountryCode: 'AW' },
  { display: 'Austria +43', value: '43', addressCountryCode: 'AT' },
  { display: 'Azerbaijan +994', value: '994', addressCountryCode: 'AZ' },
  { display: 'Bahamas +1', value: '1', addressCountryCode: 'BS' },
  { display: 'Bahrain +973', value: '973', addressCountryCode: 'BH' },
  { display: 'Bangladesh +880', value: '880', addressCountryCode: 'BD' },
  { display: 'Barbados +1', value: '1', addressCountryCode: 'BB' },
  { display: 'Belarus +375', value: '375', addressCountryCode: 'BY' },
  { display: 'Belgium +32', value: '32', addressCountryCode: 'BE' },
  { display: 'Belize +501', value: '501', addressCountryCode: 'BZ' },
  { display: 'Benin +229', value: '229', addressCountryCode: 'BJ' },
  { display: 'Bermuda +1', value: '1', addressCountryCode: 'BM' },
  { display: 'Bhutan +975', value: '975', addressCountryCode: 'BT' },
  { display: 'Bolivia +591', value: '591', addressCountryCode: 'BO' },
  {
    display: 'Bosnia and Herzegovina +387',
    value: '387',
    addressCountryCode: 'BA',
  },
  { display: 'Botswana +267', value: '267', addressCountryCode: 'BW' },
  { display: 'Brazil +55', value: '55', addressCountryCode: 'BR' },
  {
    display: 'British Indian Ocean Territory +246',
    value: '246',
    addressCountryCode: 'IO',
  },
  {
    display: 'British Virgin Islands +1',
    value: '1',
    addressCountryCode: 'VG',
  },
  { display: 'Brunei +673', value: '673', addressCountryCode: 'BN' },
  { display: 'Bulgaria +359', value: '359', addressCountryCode: 'BG' },
  { display: 'Burkina Faso +226', value: '226', addressCountryCode: 'BF' },
  { display: 'Burundi +257', value: '257', addressCountryCode: 'BI' },
  { display: 'Cambodia +855', value: '855', addressCountryCode: 'KH' },
  { display: 'Cameroon +237', value: '237', addressCountryCode: 'CM' },
  { display: 'Cape Verde +238', value: '238', addressCountryCode: 'CV' },
  { display: 'Caymon Islands +1', value: '1', addressCountryCode: 'KY' },
  {
    display: 'Central African Republic +236',
    value: '236',
    addressCountryCode: 'CF',
  },
  { display: 'Chad +235', value: '235', addressCountryCode: 'TD' },
  { display: 'Chile +56', value: '56', addressCountryCode: 'CL' },
  { display: 'China +86', value: '86', addressCountryCode: 'CN' },
  { display: 'Christmas Island +61', value: '61', addressCountryCode: 'CX' },
  { display: 'Coco Islands +61', value: '61', addressCountryCode: 'CC' },
  { display: 'Colombia +57', value: '57', addressCountryCode: 'CO' },
  { display: 'Comoros +269', value: '269', addressCountryCode: 'KM' },
  { display: 'Cook Islands +682', value: '682', addressCountryCode: 'CK' },
  { display: 'Costa Rica +506', value: '506', addressCountryCode: 'CR' },
  { display: 'Croatia +385', value: '385', addressCountryCode: 'HR' },
  { display: 'Cuba +53', value: '53', addressCountryCode: 'CU' },
  { display: 'Curacao +599', value: '599', addressCountryCode: 'CW' },
  { display: 'Cyprus +357', value: '357', addressCountryCode: 'CY' },
  { display: 'Czech Republic +420', value: '420', addressCountryCode: 'CZ' },
  {
    display: 'Democratic Republic of the Congo +243',
    value: '243',
    addressCountryCode: 'CD',
  },
  { display: 'Denmark +45', value: '45', addressCountryCode: 'DK' },
  { display: 'Djibouti +253', value: '253', addressCountryCode: 'DJ' },
  { display: 'Dominica +1', value: '1', addressCountryCode: 'DM' },
  { display: 'Dominican Republic +1', value: '1', addressCountryCode: 'DO' },
  { display: 'East Timor +670', value: '670', addressCountryCode: 'TL' },
  { display: 'Ecuador +593', value: '593', addressCountryCode: 'EC' },
  { display: 'Egypt +20', value: '20', addressCountryCode: 'EG' },
  { display: 'El Salvador +503', value: '503', addressCountryCode: 'SV' },
  { display: 'Equatorial Guinea +240', value: '240', addressCountryCode: 'GQ' },
  { display: 'Eritrea +291', value: '291', addressCountryCode: 'ER' },
  { display: 'Estonia +372', value: '372', addressCountryCode: 'EE' },
  { display: 'Ethiopia +251', value: '251', addressCountryCode: 'ET' },
  { display: 'Falkland Islands +500', value: '500', addressCountryCode: 'FK' },
  { display: 'Faroe Islands +298', value: '298', addressCountryCode: 'FO' },
  { display: 'Fiji +679', value: '679', addressCountryCode: 'FJ' },
  { display: 'Finland +358', value: '358', addressCountryCode: 'FI' },
  { display: 'France +33', value: '33', addressCountryCode: 'FR' },
  { display: 'French Polynesia +689', value: '689', addressCountryCode: 'PF' },
  { display: 'Gabon +241', value: '241', addressCountryCode: 'GA' },
  { display: 'Gambia +220', value: '220', addressCountryCode: 'GM' },
  { display: 'Georgia +995', value: '995', addressCountryCode: 'GE' },
  { display: 'Ghana +233', value: '233', addressCountryCode: 'GH' },
  { display: 'Gibraltar +350', value: '350', addressCountryCode: 'GI' },
  { display: 'Greece +30', value: '30', addressCountryCode: 'GR' },
  { display: 'Greenland +299', value: '299', addressCountryCode: 'GL' },
  { display: 'Grenada +1', value: '1', addressCountryCode: 'GD' },
  { display: 'Guam +1', value: '1', addressCountryCode: 'GU' },
  { display: 'Guatemala +502', value: '502', addressCountryCode: 'GT' },
  { display: 'Guernsey +44', value: '44', addressCountryCode: 'GG' },
  { display: 'Guinea +224', value: '224', addressCountryCode: 'GN' },
  { display: 'Guinea-Bissau +245', value: '245', addressCountryCode: 'GW' },
  { display: 'Guyana +592', value: '592', addressCountryCode: 'GY' },
  { display: 'Haiti +509', value: '509', addressCountryCode: 'HT' },
  { display: 'Honduras +504', value: '504', addressCountryCode: 'HN' },
  { display: 'Hungary +36', value: '36', addressCountryCode: 'HU' },
  { display: 'Iceland +354', value: '354', addressCountryCode: 'IS' },
  { display: 'India +91', value: '91', addressCountryCode: 'IN' },
  { display: 'Indonesia +62', value: '62', addressCountryCode: 'ID' },
  { display: 'Iran +98', value: '98', addressCountryCode: 'IR' },
  { display: 'Iraq +964', value: '964', addressCountryCode: 'IQ' },
  { display: 'Ireland +353', value: '353', addressCountryCode: 'IE' },
  { display: 'Isle of Man +44', value: '44', addressCountryCode: 'IM' },
  { display: 'Israel +972', value: '972', addressCountryCode: 'IL' },
  { display: 'Italy +39', value: '39', addressCountryCode: 'IT' },
  { display: 'Ivory Coast +225', value: '225', addressCountryCode: 'CI' },
  { display: 'Jamaica +1', value: '1', addressCountryCode: 'JM' },
  { display: 'Japan +81', value: '81', addressCountryCode: 'JP' },
  { display: 'Jersey +44', value: '44', addressCountryCode: 'JE' },
  { display: 'Jordon +962', value: '962', addressCountryCode: 'JO' },
  { display: 'Kazakhstan +7', value: '7', addressCountryCode: 'KZ' },
  { display: 'Kenya +254', value: '254', addressCountryCode: 'KE' },
  { display: 'Kiribati +686', value: '686', addressCountryCode: 'KI' },
  { display: 'Kosovo +383', value: '383', addressCountryCode: 'XK' },
  { display: 'Kuwait +965', value: '965', addressCountryCode: 'KW' },
  { display: 'Kyrgyzstan +996', value: '996', addressCountryCode: 'KG' },
  { display: 'Laos +856', value: '856', addressCountryCode: 'LA' },
  { display: 'Latvia +371', value: '371', addressCountryCode: 'LV' },
  { display: 'Lebanon +961', value: '961', addressCountryCode: 'LB' },
  { display: 'Lesotho +266', value: '266', addressCountryCode: 'LS' },
  { display: 'Liberia +231', value: '231', addressCountryCode: 'LR' },
  { display: 'Libya +218', value: '218', addressCountryCode: 'LY' },
  { display: 'Liechtenstein +423', value: '423', addressCountryCode: 'LI' },
  { display: 'Lithuania +370', value: '370', addressCountryCode: 'LT' },
  { display: 'Luxembourg +352', value: '352', addressCountryCode: 'LU' },
  { display: 'Macau +853', value: '853', addressCountryCode: 'MO' },
  { display: 'Macedonia +389', value: '389', addressCountryCode: 'MK' },
  { display: 'Madagascar +261', value: '261', addressCountryCode: 'MG' },
  { display: 'Malawi +265', value: '265', addressCountryCode: 'MW' },
  { display: 'Malaysia +60', value: '60', addressCountryCode: 'MY' },
  { display: 'Maldives +960', value: '960', addressCountryCode: 'MV' },
  { display: 'Mali +223', value: '223', addressCountryCode: 'ML' },
  { display: 'Malta +356', value: '356', addressCountryCode: 'MT' },
  { display: 'Marshall Islands +692', value: '692', addressCountryCode: 'MH' },
  { display: 'Mauritania +222', value: '222', addressCountryCode: 'MR' },
  { display: 'Mauritius +230', value: '230', addressCountryCode: 'MU' },
  { display: 'Mayotte +262', value: '262', addressCountryCode: 'YT' },
  { display: 'Mexico +52', value: '52', addressCountryCode: 'MX' },
  { display: 'Micronesia +691', value: '691', addressCountryCode: 'FM' },
  { display: 'Moldova +373', value: '373', addressCountryCode: 'MD' },
  { display: 'Monaco +377', value: '377', addressCountryCode: 'MC' },
  { display: 'Mongolia +976', value: '976', addressCountryCode: 'MN' },
  { display: 'Montenegro +382', value: '382', addressCountryCode: 'ME' },
  { display: 'Montserrat +1', value: '1', addressCountryCode: 'MS' },
  { display: 'Morocco +212', value: '212', addressCountryCode: 'MA' },
  { display: 'Mozambique +258', value: '258', addressCountryCode: 'MZ' },
  { display: 'Myanmar +95', value: '95', addressCountryCode: 'MM' },
  { display: 'Namibia +264', value: '264', addressCountryCode: 'NA' },
  { display: 'Nauru +674', value: '674', addressCountryCode: 'NR' },
  { display: 'Nepal +977', value: '977', addressCountryCode: 'NP' },
  { display: 'Netherlands +31', value: '31', addressCountryCode: 'NL' },
  {
    display: 'Netherlands Antilles +591',
    value: '591',
    addressCountryCode: 'AN',
  },
  { display: 'New Caledonia +687', value: '687', addressCountryCode: 'NC' },
  { display: 'New Zealand +64', value: '64', addressCountryCode: 'NZ' },
  { display: 'Nicaragua +505', value: '505', addressCountryCode: 'NI' },
  { display: 'Niger +227', value: '227', addressCountryCode: 'NE' },
  { display: 'Nigeria +234', value: '234', addressCountryCode: 'NG' },
  { display: 'Niue +683', value: '683', addressCountryCode: 'NU' },
  { display: 'North Korea +850', value: '850', addressCountryCode: 'KP' },
  {
    display: 'Northern Mariana Islands +1',
    value: '1',
    addressCountryCode: 'MP',
  },
  { display: 'Norway +47', value: '47', addressCountryCode: 'NO' },
  { display: 'Oman +968', value: '968', addressCountryCode: 'OM' },
  { display: 'Pakistan +92', value: '92', addressCountryCode: 'PK' },
  { display: 'Palau +680', value: '680', addressCountryCode: 'PW' },
  { display: 'Palestine +970', value: '970', addressCountryCode: 'PS' },
  { display: 'Panama +507', value: '507', addressCountryCode: 'PA' },
  { display: 'Papua New Guinea +675', value: '675', addressCountryCode: 'PG' },
  { display: 'Paraguay +595', value: '595', addressCountryCode: 'PY' },
  { display: 'Peru +51', value: '51', addressCountryCode: 'PE' },
  { display: 'Philippines +63', value: '63', addressCountryCode: 'PH' },
  { display: 'Pitcairn +64', value: '64', addressCountryCode: 'PN' },
  { display: 'Poland +48', value: '48', addressCountryCode: 'PL' },
  { display: 'Portugal +351', value: '351', addressCountryCode: 'PT' },
  { display: 'Puerto Rico +1', value: '1', addressCountryCode: 'PR' },
  { display: 'Qatar +974', value: '974', addressCountryCode: 'QA' },
  {
    display: 'Republic of the Congo +242',
    value: '242',
    addressCountryCode: 'CG',
  },
  { display: 'Reunion +262', value: '262', addressCountryCode: 'RE' },
  { display: 'Romania +40', value: '40', addressCountryCode: 'RO' },
  { display: 'Russia +7', value: '7', addressCountryCode: 'RU' },
  { display: 'Rwanda +250', value: '250', addressCountryCode: 'RW' },
  { display: 'Saint Barthelemy +590', value: '590', addressCountryCode: 'BL' },
  { display: 'Saint Helena +290', value: '290', addressCountryCode: 'SH' },
  { display: 'Saint Kitts and Nevis +1', value: '1', addressCountryCode: 'KN' },
  { display: 'Saint Lucia +1', value: '1', addressCountryCode: 'LC' },
  { display: 'Saint Martin +590', value: '590', addressCountryCode: 'MF' },
  {
    display: 'Saint Pierre and Miquelon +508',
    value: '508',
    addressCountryCode: 'PM',
  },
  {
    display: 'Saint Vincent and the Grenadines +1',
    value: '1',
    addressCountryCode: 'VC',
  },
  { display: 'Samoa +685', value: '685', addressCountryCode: 'WS' },
  { display: 'San Marino +378', value: '378', addressCountryCode: 'SM' },
  {
    display: 'Sao Tome and Principe +239',
    value: '239',
    addressCountryCode: 'ST',
  },
  { display: 'Saudi Arabia +966', value: '966', addressCountryCode: 'SA' },
  { display: 'Senegal +221', value: '221', addressCountryCode: 'SN' },
  { display: 'Serbia +381', value: '381', addressCountryCode: 'RS' },
  { display: 'Seychelles +248', value: '248', addressCountryCode: 'SC' },
  { display: 'Sierra Leone +232', value: '232', addressCountryCode: 'SL' },
  { display: 'Sint Maarten +1', value: '1', addressCountryCode: 'SX' },
  { display: 'Slovakia +421', value: '421', addressCountryCode: 'SK' },
  { display: 'Slovenia +386', value: '386', addressCountryCode: 'SI' },
  { display: 'Solomon Islands +677', value: '677', addressCountryCode: 'SB' },
  { display: 'Somalia +252', value: '252', addressCountryCode: 'SO' },
  { display: 'South Africa +27', value: '27', addressCountryCode: 'ZA' },
  { display: 'South Korea +82', value: '82', addressCountryCode: 'KR' },
  { display: 'South Sudan +211', value: '211', addressCountryCode: 'SS' },
  { display: 'Spain +34', value: '34', addressCountryCode: 'ES' },
  { display: 'Sri Lanka +94', value: '94', addressCountryCode: 'LK' },
  { display: 'Sudan +249', value: '249', addressCountryCode: 'SD' },
  { display: 'Suriname +597', value: '597', addressCountryCode: 'SR' },
  {
    display: 'Svalbard and Jan Mayen +47',
    value: '47',
    addressCountryCode: 'SJ',
  },
  { display: 'Swaziland +268', value: '268', addressCountryCode: 'SZ' },
  { display: 'Sweden +46', value: '46', addressCountryCode: 'SE' },
  { display: 'Syria +963', value: '963', addressCountryCode: 'SY' },
  { display: 'Taiwan +886', value: '886', addressCountryCode: 'TW' },
  { display: 'Tajikistan +992', value: '992', addressCountryCode: 'TJ' },
  { display: 'Tanzania +255', value: '255', addressCountryCode: 'TZ' },
  { display: 'Thailand +66', value: '66', addressCountryCode: 'TH' },
  { display: 'Togo +228', value: '228', addressCountryCode: 'TG' },
  { display: 'Tokelau +690', value: '690', addressCountryCode: 'TK' },
  { display: 'Tonga +276', value: '276', addressCountryCode: 'TO' },
  { display: 'Trinidad and Tobago +1', value: '1', addressCountryCode: 'TT' },
  { display: 'Tunisia +216', value: '216', addressCountryCode: 'TN' },
  { display: 'Turkey +90', value: '90', addressCountryCode: 'TR' },
  { display: 'Turkmenistan +993', value: '993', addressCountryCode: 'TM' },
  {
    display: 'Turks and Caicos Islands +1',
    value: '1',
    addressCountryCode: 'TC',
  },
  { display: 'Tuvalu +688', value: '688', addressCountryCode: 'TV' },
  { display: 'U.S. Virgin Islands +1', value: '1', addressCountryCode: 'VI' },
  { display: 'Uganda +256', value: '256', addressCountryCode: 'UG' },
  { display: 'Ukraine +380', value: '380', addressCountryCode: 'UA' },
  {
    display: 'United Arab Emirates +971',
    value: '971',
    addressCountryCode: 'AE',
  },
  { display: 'Uruguay +598', value: '598', addressCountryCode: 'UY' },
  { display: 'Uzbekistan +998', value: '998', addressCountryCode: 'UZ' },
  { display: 'Vanuatu +678', value: '678', addressCountryCode: 'VU' },
  { display: 'Vatican +379', value: '379', addressCountryCode: 'VA' },
  { display: 'Venezuela +58', value: '58', addressCountryCode: 'VE' },
  { display: 'Vietnam +84', value: '84', addressCountryCode: 'VN' },
  { display: 'Wallis and Futuna +681', value: '681', addressCountryCode: 'WF' },
  { display: 'Western Sahara +212', value: '212', addressCountryCode: 'VH' },
  { display: 'Yemen +967', value: '967', addressCountryCode: 'YE' },
  { display: 'Zambia +260', value: '260', addressCountryCode: 'ZM' },
  { display: 'Zimbabwe +263', value: '263', addressCountryCode: 'ZW' },
] as const;

export const CountryCodeValues = CountryCodesDropdownOptions.map(
  item => item.value
) as Array<(typeof CountryCodesDropdownOptions)[number]['value']>;

export const CountryCodeAddressCountryValues = CountryCodesDropdownOptions.map(
  item => item.addressCountryCode
) as Array<(typeof CountryCodesDropdownOptions)[number]['addressCountryCode']>;

export type CountryCodesAbbreviationsType = keyof typeof CountryCodes;
export type CountryCodesType =
  (typeof CountryCodes)[CountryCodesAbbreviationsType];

const CountryCodeFilter = (codes: CountryCodesAbbreviationsType) => {
  return { filters: { addressCountryCode: [codes] } };
};

const CountryCodesAbbreviations = Object.keys(CountryCodes).reduce(
  (
    acc,
    v
  ): Record<CountryCodesAbbreviationsType, CountryCodesAbbreviationsType> => ({
    ...acc,
    [v]: v,
  }),
  {} as Record<CountryCodesAbbreviationsType, CountryCodesAbbreviationsType>
);

const CountryCodesAbbreviationsList = Object.keys(
  CountryCodes
) as CountryCodesAbbreviationsType[];

export const CountryCodesConst = {
  CountryCodes,
  CountryCodesAbbreviations,
  CountryCodesAbbreviationsList,
  AbbreviatedCountryCode: (
    country: CountryCodesType
  ): CountryCodesAbbreviationsType | undefined => {
    return CountryCodesAbbreviationsList.find(
      (e: CountryCodesAbbreviationsType) => CountryCodes[e] === country
    );
  },
  CountryCodeFilter,
} as const;
