import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input as SInput } from 'semantic-ui-react';
import { CustomSchemaTypes, SchemaTypes } from '@axiom/types';
import debounce from 'lodash/debounce';

import { DesignSysEnvUtil } from '../../../utils/design-sys-env-util';

const InputWrapper = styled.div``;
const SchemaTypeMap = {
  [CustomSchemaTypes.SchemaEmail]: 'email',
  [CustomSchemaTypes.SchemaCurrency]: 'text',
  [SchemaTypes.ZodNumber]: 'number',
  [CustomSchemaTypes.SchemaPassword]: 'password',
  [SchemaTypes.ZodString]: 'text',
  [SchemaTypes.ZodEnum]: 'text',
  [CustomSchemaTypes.SchemaPhoneNumber]: 'text',
  [CustomSchemaTypes.SchemaRate]: 'text',
} as const;
export type RawInputValueType = string | number | null;

export type RawInputProps = {
  disabled?: boolean;
  id?: string;
  invalid?: boolean;
  name: string;
  onBlur: (
    value: RawInputValueType,
    e: React.FocusEvent<HTMLInputElement>
  ) => void;
  onChange: (value: RawInputValueType) => void;
  onFocus: (
    value: RawInputValueType,
    e: React.FocusEvent<HTMLInputElement>
  ) => void;
  placeholder?: string;
  type: keyof typeof SchemaTypeMap;
  value?: RawInputValueType;
};

const formatOut = (
  type: string,
  e: React.ChangeEvent<HTMLInputElement>
): RawInputValueType => {
  let formatValue: RawInputValueType = e.target.value;
  if (formatValue === '' || formatValue === undefined) {
    formatValue = null;
  }

  if (type === SchemaTypes.ZodNumber && formatValue !== null) {
    formatValue = +formatValue;
  }

  return formatValue;
};

const formatIn = (value?: RawInputValueType) => {
  let formattedValue = value;
  if (formattedValue === null || formattedValue === undefined) {
    formattedValue = '';
  }

  return formattedValue;
};

export const RawInput = ({
  disabled = false,
  id,
  invalid = false,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  type = SchemaTypes.ZodString,
  value = '',
}: RawInputProps) => {
  /**
   * Keeps it from flop-toggling between an uncontrolled/controlled component
   */

  const formattedValue = formatIn(value);
  const [inputState, setInputState] =
    useState<RawInputValueType>(formattedValue);

  const debounceChange = useCallback(
    debounce(event => {
      onChange(formatOut(type, event));
    }, DesignSysEnvUtil.typingDebounce),
    []
  );
  if (!Object.keys(SchemaTypeMap).includes(type)) {
    throw new Error(
      `Unknown Input Schema Type: ${type}; Allowed types: ${Object.keys(
        SchemaTypeMap
      ).join(', ')}`
    );
  }

  useEffect(() => {
    if (formattedValue !== inputState) {
      setInputState(formattedValue);
    }
  }, [value]);

  return (
    <InputWrapper data-test={name}>
      <SInput
        fluid
        key={name}
        {...{
          id,
          name,
          disabled,
          error: invalid,
          onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
            onBlur(formatOut(type, event), event);
          },
          onChange: event => {
            setInputState(formatOut(type, event));
            if (DesignSysEnvUtil.typingDebounce > 0) {
              debounceChange(event);
            } else {
              onChange(formatOut(type, event));
            }
          },
          onFocus,
          placeholder,
          type: SchemaTypeMap[type],
          value: inputState ?? '',
        }}
      />
    </InputWrapper>
  );
};
