import { z } from 'zod';

import {
  CandidateOpportunitySchema,
  ZodCandidateOpportunityCandidateStatuses,
} from './candidate-opportunities';
import { AfcSubmissionCandidateSchema } from './afc-submission-candidate';

export const AfcSubmissionCandidateOpportunityBadges = [
  'Axiom Pick',
  'Top Match',
  'Self-Submitted',
] as const;

export const AfcSubmissionCandidateOpportunitySchema =
  CandidateOpportunitySchema.pick({
    displayBillingRate: true,
    id: true,
    proposedHourlyRate: true,
  }).extend({
    badge: z.enum(AfcSubmissionCandidateOpportunityBadges).nullable(),
    candidate: AfcSubmissionCandidateSchema,
    candidateStatus: ZodCandidateOpportunityCandidateStatuses.nullable(),
    interviewRequested: z.boolean().nullable(),
    isInstantMatch: z.boolean().default(false), // Bad Prop APCORE-2591
    submissionDescription: z.string().nullable(),
  });
export type AfcSubmissionCandidateOpportunity = z.infer<
  typeof AfcSubmissionCandidateOpportunitySchema
>;
