import Ajv from 'ajv';
import { z } from 'zod';
import { ClientRequestConst } from '@axiom/const';
import { ZodArrayToEnum } from '@axiom/types';

import { axiomValidationOptions } from './options';
import { ZodCandidateOpportunityCandidateStatuses } from './candidate-opportunities';

export const ClientRequestRejectionLossCodes = Object.values(
  ClientRequestConst.AfcRejectionLossCodes
);

export const ClientRequestRequestTypes = Object.values(
  ClientRequestConst.RequestTypes
);

export const ClientRequestSchema = z.object({
  candidateId: z.string().uuid(),
  candidateOpportunityStatus: ZodCandidateOpportunityCandidateStatuses,
  clientUserId: z.string().uuid(),
  id: z.string().uuid(),
  notes: z.string().nullish(),
  opportunityId: z.string().uuid(),
  rejectionLossCodes: z
    .array(z.enum(ZodArrayToEnum(ClientRequestRejectionLossCodes)))
    .nullable(),
  requestType: z.enum(ZodArrayToEnum(ClientRequestRequestTypes)),
  submissionId: z.string().uuid(),
});

export type ClientRequest = z.infer<typeof ClientRequestSchema>;

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

export const createClientRequestValidator = ajv.compile({
  type: 'object',
  properties: {
    candidateId: {
      type: 'string',
      format: 'uuid',
    },
    clientUserId: {
      type: 'string',
      format: 'uuid',
    },
    notes: {
      type: 'string',
    },
    rejectionLossCodes: {
      type: 'array',
      items: {
        type: 'string',
        enum: ClientRequestRejectionLossCodes,
      },
    },
    requestType: {
      type: ['string', 'null'],
      enum: [...ClientRequestRequestTypes, null],
    },
    submissionId: {
      type: 'string',
      format: 'uuid',
    },
  },
  required: ['candidateId', 'submissionId', 'requestType', 'clientUserId'],
});
