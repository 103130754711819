import { SchemaTimestamp, ZodArrayToEnum } from '@axiom/types';
import { z } from 'zod';

export const TopTaskTypes = {
  TIME_TO_SUBMISSION: 'TIME_TO_SUBMISSION',
  CHECK_IN: 'CHECK_IN',
  IDLE_REMINDER: 'IDLE_REMINDER',
  CANDIDATE_ISSUE_MISSING_FOCUS_AREA: 'CANDIDATE_ISSUE_MISSING_FOCUS_AREA',
  CANDIDATE_ISSUE_MISSING_OCCUPATION_TYPE:
    'CANDIDATE_ISSUE_MISSING_OCCUPATION_TYPE',
  CANDIDATE_ISSUE_MISSING_ADDRESS_FIELDS:
    'CANDIDATE_ISSUE_MISSING_ADDRESS_FIELDS',
} as const;

export const TopTaskTopTaskTypes = Object.values(TopTaskTypes);

export const TopTaskSchema = z.object({
  candidateCalculatedDisplayName: z.string().max(255).nullable(),
  candidateId: z.string().uuid(),
  createdAt: SchemaTimestamp,
  id: z.string().uuid(),
  opportunityId: z.string().uuid(),
  opportunityJobName: z.string().max(255).nullable(),
  ownerId: z.string().uuid(),
  taskExpectedCompletionDateTime: SchemaTimestamp,
  taskExpectedStartDateTime: SchemaTimestamp,
  topTaskType: z.enum(ZodArrayToEnum(TopTaskTopTaskTypes)),
});

export type TopTask = z.infer<typeof TopTaskSchema>;
