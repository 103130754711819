import React, { useMemo } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { IconButton } from '../../element/Button/IconButton';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

const SortableWrapper = styled.div``;

export type RawSortableValueType = Array<string>;

export type RawSortableProps = {
  displayKey: string;
  id?: string;
  name: string;
  onChange: (value: RawSortableValueType) => void;
  options: Array<Record<string, unknown>>;
  value: RawSortableValueType;
  valueKey: string;
};

export const RawSortable = ({
  displayKey,
  id,
  name,
  onChange,
  options,
  value,
  valueKey,
}: RawSortableProps) => {
  const displayByValueLookup = useMemo(
    () =>
      (options ?? []).reduce((acc, curr) => {
        const display = get(curr, displayKey) as React.ReactNode;
        const v = get(curr, valueKey) as string;
        return {
          ...acc,
          ...(display && v ? { [v]: display } : {}),
        };
      }, {}),
    [options]
  ) as Record<string, React.ReactNode>;

  return value ? (
    <SortableWrapper
      className={AttrsHelper.formatClassname(
        'sortable',
        value.length === 0 && 'sortable-empty'
      )}
      data-test={name}
      data-value={JSON.stringify(value)}
      id={id}
    >
      {value.length === 0
        ? 'No items in the list'
        : value.map((currentValue, index) => (
            <div
              className="sortable-item"
              data-test={currentValue}
              data-value={currentValue}
              key={currentValue}
            >
              <Layout position="middle space-between" horizontalGutter="8px">
                <LayoutItem>
                  <Layout stackableOn="mobile">
                    <IconButton
                      name="sortable-move-up-button"
                      pattern="secondary"
                      variation="minimal"
                      icon="move-up"
                      disabled={index === 0}
                      onClick={() => {
                        if (index > 0 && index < value.length) {
                          const newSortedValues = [...value];
                          const temp = newSortedValues[index];
                          newSortedValues[index] = newSortedValues[index - 1];
                          newSortedValues[index - 1] = temp;
                          onChange(newSortedValues);
                        }
                      }}
                    />
                    <IconButton
                      name="sortable-move-down-button"
                      pattern="secondary"
                      variation="minimal"
                      icon="move-down"
                      disabled={index === value.length - 1}
                      onClick={() => {
                        if (index < value.length - 1) {
                          const newSortedValues = [...value];
                          const temp = newSortedValues[index];
                          newSortedValues[index] = newSortedValues[index + 1];
                          newSortedValues[index + 1] = temp;
                          onChange(newSortedValues);
                        }
                      }}
                    />
                  </Layout>
                </LayoutItem>
                <LayoutItem fluid>
                  {displayByValueLookup[currentValue]}
                </LayoutItem>
              </Layout>
            </div>
          ))}
    </SortableWrapper>
  ) : null;
};
