import React from 'react';
import { IHeaderParams } from '@ag-grid-community/core';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { LayoutItem } from '../Layout/LayoutItem';
import { Layout } from '../Layout/Layout';
import { IconButton } from '../../element/Button/IconButton';
import { ContextMenu } from '../../element/ContextMenu/ContextMenu';

import { SortStructure, SortType } from './data-grid-props';
import { DataGridHeaderProps } from './DataGridHeader';

const sortIconMap = {
  asc: 'sort-ascending',
  desc: 'sort-descending',
} as const;
export const RawDataGridHeader = <T,>({
  enableSorting,
  sorting,
  sortingOrder,
  displayName,
  FiltersRender,
  onSortChange,
  column,
  name,
}: IHeaderParams &
  DataGridHeaderProps<T> & {
    onSortChange: (changeSort: SortStructure) => void;
    sorting: SortStructure;
  }) => {
  const getNextSort = (currentSort?: SortType) => {
    if (!enableSorting) {
      return null;
    }

    if (!sortingOrder) {
      return null;
    }

    if (!currentSort) {
      return sortingOrder[0];
    }
    const currentSortIndex = sortingOrder.indexOf(currentSort);
    if (currentSortIndex + 1 > sortingOrder.length) {
      return sortingOrder[0];
    }
    return sortingOrder[currentSortIndex + 1];
  };

  const currentSort = sorting[name];
  const sortIcon = currentSort && sortIconMap?.[currentSort];

  let sort = null;
  if (enableSorting) {
    sort = (
      <div style={{ display: 'inline-block' }}>
        <IconButton
          name="column-sort-button"
          icon={sortIcon || 'sort'}
          onClick={() => {
            const nextSort = getNextSort(currentSort) || null;
            const columnId = column.getColId();
            onSortChange({ [columnId]: nextSort });
          }}
          pattern="secondary"
          variation="minimal"
        />
      </div>
    );
  }

  let renderedDisplay: JSX.Element | string = displayName;
  if (typeof displayName === 'function') {
    const displayFunction: () => JSX.Element = displayName;
    renderedDisplay = displayFunction();
  }
  return (
    <div className={AttrsHelper.formatClassname('custom-header')}>
      {!FiltersRender && !sort ? (
        renderedDisplay
      ) : (
        <Layout horizontalGutter="8px" position="middle" name={name}>
          <LayoutItem fluid name="column-display-name">
            {renderedDisplay}
          </LayoutItem>
          {!!FiltersRender && (
            <LayoutItem>
              <ContextMenu
                name="data-grid-filters"
                fixed
                anchor={
                  <IconButton
                    icon="filter"
                    pattern="secondary"
                    variation="minimal"
                  />
                }
              >
                {FiltersRender()}
              </ContextMenu>
            </LayoutItem>
          )}
          {!!sort && <LayoutItem>{sort}</LayoutItem>}
        </Layout>
      )}
    </div>
  );
};
