const hoursPerWeek = {
  oneToTen: '1-10 hours per week',
  tenToTwenty: '10-20 hours per week',
  twentyToThirty: '20-30 hours per week',
  thirtyToForty: '30-40 hours per week',
  overForty: 'Over 40 hours per week',
} as const;

const expectedDuration = {
  oneToThree: '1-3 months',
  threeToSix: '3-6 months',
  sixToTwelve: '6-12 months',
  overTwelve: 'Over 12 months',
} as const;

const requestType = {
  Talk: 'Talk',
  Inquire: 'Inquire',
} as const;

export const TalkConst = {
  // Expected hours per week
  hoursPerWeek,
  expectedDuration,
  hoursPerWeekArr: Object.values(hoursPerWeek),
  expectedDurationArr: Object.values(expectedDuration),
  requestChosenFormat: 'MMMM Do, YYYY [at] ha zz',
  requestChosenFormatUtc: 'MMMM Do, YYYY [at] ha [UTC]',
  requestType,
} as const;
