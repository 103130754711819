import { z } from 'zod';
import {
  CandidatesConst,
  CandidateOpportunitiesConst,
  CountryCodesConst,
  MLRecommendationsConst,
} from '@axiom/const';
import { SchemaTimestamp, ZodArrayToEnum } from '@axiom/types';

import { CandidateSchema } from './candidate';
import {
  OpportunityCandidateSchema,
  ZodCandidateOpportunityCandidateStatuses,
} from './candidate-opportunities';
import { requireAtLeastOneDefined } from './general';

const CandidateRejectionLossCodes = Object.values(
  CandidateOpportunitiesConst.CandidateRejectionLossCodes
);
const CountryCodes = Object.values(CountryCodesConst.CountryCodesAbbreviations);
const ProfileStatuses = Object.values(CandidatesConst.ProfileStatuses);

export const MlRecommendedRequestSchema = z.object({
  addressCountryCode: z.enum(ZodArrayToEnum(CountryCodes)),
  addressState: z.array(z.string()),
  barredLocations: z.array(z.string().uuid()),
  candidatesToExclude: z.array(z.string().uuid()),
  compensation: z.object({
    start: z.number().nonnegative().max(9999999999999.99).nullish(),
    end: z.number().nonnegative().max(9999999999999.99).nullish(),
  }),
  hourlyCompensation: z.object({
    start: z.number().nonnegative().max(999999.99).nullish(),
    end: z.number().nonnegative().max(999999.99).nullish(),
  }),
  occupationType: z.array(
    z.enum(
      ZodArrayToEnum(
        Object.values(MLRecommendationsConst.SupportedOccupationTypes)
      )
    )
  ),
  profileStatus: z.array(z.enum(ZodArrayToEnum(ProfileStatuses))),
  size: z.number().nonnegative().int().nullable(),
  weeklyAvailability: z.object({
    start: z.number().nonnegative().int().nullable(),
  }),
  yearsOfExperience: z.object({
    start: z.number().nonnegative().int().nullable(),
    end: z.number().nonnegative().int().nullable(),
  }),
});

export const MlRecommendedSchema = z.object({
  id: z.string().uuid(),
  opportunityId: z.string().uuid(),
  candidateId: z.string().uuid(),
  candidateOpportunityId: z.string().uuid().nullable(),
  candidateStatus: ZodCandidateOpportunityCandidateStatuses,
  createdAt: SchemaTimestamp,
  isAccepted: z.boolean(),
  isRejected: z.boolean(),
  isSuggested: z.boolean(),
  isMlRecommendation: z.boolean(),
  mlLossCode: z.enum(ZodArrayToEnum(CandidateRejectionLossCodes)).nullable(),
  qualityScore: z.number().nullable(),
  updatedAt: SchemaTimestamp.nullable(),
  updatedBy: z.string().uuid().nullable(),
});

export type MlRecommended = z.infer<typeof MlRecommendedSchema>;

export const CandidateOrMlRecommendedSchema =
  OpportunityCandidateSchema.merge(MlRecommendedSchema);

export type OpportunityCandidateOrMlRecommended = z.infer<
  typeof CandidateOrMlRecommendedSchema
>;

export const PostMlRecommendedSchema =
  MlRecommendedRequestSchema.partial().strict();

export type PostMlRecommendedBody = z.infer<typeof PostMlRecommendedSchema>;

export const PostMlRecommendedResponseCandidateSchema = z.object({
  id: z.string().uuid(),
  calculatedDisplayName: z.string(),
  duplicate: z.boolean(),
});

export type PostMlRecommendedResponseCandidate = z.infer<
  typeof PostMlRecommendedResponseCandidateSchema
>;

export const PostMlRecommendedResponseDataSchema = z.object({
  id: z.string().uuid(),
  status: z.string(),
  candidateIds: z.array(z.string().uuid()),
  opportunityCandidates: z
    .array(PostMlRecommendedResponseCandidateSchema)
    .nullish(),
  results: z.array(CandidateSchema).nullish(),
});

export type PostMlRecommendedResponseData = z.infer<
  typeof PostMlRecommendedResponseDataSchema
>;
export const PatchMlRecommendedSchema = MlRecommendedSchema.partial()
  .pick({ mlLossCode: true })
  .strict()
  .refine(requireAtLeastOneDefined);
