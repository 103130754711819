const ROLES_ADDED_BY_CLIENT = 'AddedByClient';

const Roles = {
  DecisionMaker: 'Decision Maker',
  Buyer: 'Buyer',
  ExecutiveSponsor: 'Executive Sponsor',
  Influencer: 'Influencer',
  Coach: 'Coach',
  BusinessUser: 'Business User',
  None: 'None',
  Owner: 'Owner',
  [ROLES_ADDED_BY_CLIENT]: 'Added by Client',
} as const;

export const ContactsOpportunitiesConst = {
  Roles,
  ROLES_ADDED_BY_CLIENT,
} as const;
