import { AreasOfStudyCategoriesConst } from '@axiom/const';
import { z } from 'zod';
import { ZodArrayToEnum } from '@axiom/types';

export const categoryDbValues = Object.keys(
  AreasOfStudyCategoriesConst.Categories
) as Array<keyof typeof AreasOfStudyCategoriesConst.Categories>;

export const AreaOfStudySchema = z.object({
  id: z.string().uuid(),
  name: z
    .string()
    .trim()
    .min(3, { message: 'Name must contain at least 3 characters' })
    .max(50, { message: 'Name must be no more than 50 characters' }),
  category: z.enum(ZodArrayToEnum(categoryDbValues)),
  isStem: z.boolean(),
});

export type AreaOfStudy = z.infer<typeof AreaOfStudySchema>;
