import { z } from 'zod';

import { CalendarSchema } from './calendar';

export const CandidateCalendarSchema = CalendarSchema.pick({
  nylasCalendarId: true,
  timezone: true,
}).extend({
  id: z.string().uuid(),
});

export type CandidateCalendar = z.infer<typeof CandidateCalendarSchema>;
