import { z } from 'zod';

import { AfcSubmissionCandidateOpportunitySchema } from './afc-submission-candidate-opportunity';

export const AfcSubmissionPublicCandidateOpportunitySchema =
  AfcSubmissionCandidateOpportunitySchema.pick({
    badge: true,
    candidate: true,
    candidateStatus: true,
    displayBillingRate: true,
    proposedHourlyRate: true,
    submissionDescription: true,
  });

export type AfcSubmissionPublicCandidateOpportunity = z.infer<
  typeof AfcSubmissionPublicCandidateOpportunitySchema
>;
