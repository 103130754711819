import { Api } from '@axiom/ui';

import { EnvUtil } from '../utils/env-util';

import { ReadAuthResponse } from './auth-api';

class LegacyAuthApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readAuthForEmail(email: string, queryObject?: { [key: string]: string }) {
    return super.read<{ data: ReadAuthResponse }>({
      endpoint: `/auth/${email}`,
      query: queryObject,
      method: 'GET',
    });
  }

  createAuthForEmail(email: string, queryObject?: { [key: string]: string }) {
    return super.write<{ data: ReadAuthResponse }>({
      endpoint: `/auth/${email}`,
      query: queryObject,
      method: 'GET',
    });
  }
}

export const LegacyAuthApi = new LegacyAuthApiClass();
