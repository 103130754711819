import { z } from 'zod';
import { SchemaTimestamp } from '@axiom/types';

import { requireAtLeastOneDefined } from './general';

export const UserSettingSchema = z.object({
  appId: z.string().uuid().nullable(),
  createdAt: SchemaTimestamp,
  env: z.string().nullable(),
  id: z.string().uuid(),
  name: z.string(),
  updatedAt: SchemaTimestamp,
  value: z.string().nullable(),
});

export type UserSetting = z.infer<typeof UserSettingSchema>;

export const PostUserSettingsSchema = UserSettingSchema.partial()
  .pick({ appId: true, env: true, name: true, value: true })
  .strict()
  .refine(requireAtLeastOneDefined);
