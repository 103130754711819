import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

export const UserSearchSchema = z.object({
  id: z.string().uuid(),
  name: z.string().max(255).nullable(),
  search: z.string().nullable(),
  type: z.string().max(255).nullable(),
  userId: z.string().uuid().nullable(),
});

export type UserSearch = z.infer<typeof UserSearchSchema>;

const ajv = new Ajv(axiomValidationOptions());
const TYPE_ENUM_VALS = ['candidate', 'opportunity'];

const userSearchCommonValidation = {
  name: { type: 'string' },
  type: { type: 'string', enum: TYPE_ENUM_VALS },
  // TODO: identify search properties
  search: { type: 'object' },
};

const createUserSearchValidation = {
  type: 'object',
  additionalProperties: false,
  properties: userSearchCommonValidation,
  required: ['name', 'type', 'search'],
};

const updateUserSearchValidation = {
  type: 'object',
  additionalProperties: false,
  properties: userSearchCommonValidation,
  anyOf: [
    { required: ['name'] },
    { required: ['type'] },
    { required: ['search'] },
  ],
};

const userSearchValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: TYPE_ENUM_VALS,
    },
  },
};

export const createUserSearchValidator = ajv.compile(
  createUserSearchValidation
);

export const updateUserSearchValidator = ajv.compile(
  updateUserSearchValidation
);

export const userSearchValidator = ajv.compile(userSearchValidation);
