import moment from 'moment';

export const EngagementsUtil = {
  isEndDateValid(startDate: string | null, endDate: string | null) {
    return !!(
      startDate?.length &&
      (endDate?.length ?? 0) > 0 &&
      moment(endDate).isAfter(moment(startDate))
    );
  },

  isBillableHoursValid(
    weeks: number | null,
    billingUnitsPerWeek: number | null
  ) {
    return (weeks ?? 0) * (billingUnitsPerWeek ?? 0) >= 20;
  },

  showEngagementLengthWarning(
    startDate: string | null,
    endDate: string | null,
    billingUnitsPerWeek: number | null,
    weeks: number | null
  ) {
    return !!(
      startDate &&
      endDate &&
      billingUnitsPerWeek &&
      !(
        this.isEndDateValid(startDate, endDate) &&
        this.isBillableHoursValid(weeks, billingUnitsPerWeek)
      )
    );
  },

  calculateEngagementWeeks(engagementLengthDays: number) {
    return Math.max(1, Math.round(engagementLengthDays / 7));
  },

  calculateEngagementLengthInDays(startDate: string, endDate: string) {
    return moment(endDate).diff(moment(startDate), 'days');
  },
};
