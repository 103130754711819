import React from 'react';

import { Icon } from '../Icon/Icon';
import { IconName } from '../Icon/icon-types';

import { RawButton, RawButtonProps } from './RawButton';

export type ButtonProps = {
  fluid?: RawButtonProps['fluid'];
  children?: RawButtonProps['children'];
  className?: RawButtonProps['className'];
  disabled?: RawButtonProps['disabled'];
  icon?: IconName;
  iconPosition?: RawButtonProps['iconPosition'];
  id?: RawButtonProps['id'];
  inverse?: RawButtonProps['inverse'];
  name?: RawButtonProps['name'];
  onClick?: RawButtonProps['onClick'];
  pattern?: RawButtonProps['pattern'];
  title?: RawButtonProps['title'];
  to?: RawButtonProps['to'];
  toTab?: RawButtonProps['toTab'];
  type?: RawButtonProps['type'];
  variation?: RawButtonProps['variation'];
};

export const Button = ({
  fluid,
  children,
  className,
  disabled,
  icon,
  iconPosition,
  id,
  inverse,
  name,
  onClick,
  pattern,
  title,
  to,
  toTab,
  type,
  variation,
}: ButtonProps) => {
  const ButtonIcon = icon ? <Icon name={icon} /> : null;
  return (
    <RawButton
      fluid={fluid}
      className={className}
      disabled={disabled}
      Icon={ButtonIcon}
      iconPosition={iconPosition}
      id={id}
      inverse={inverse}
      name={name}
      onClick={onClick}
      pattern={pattern}
      title={title}
      to={to}
      toTab={toTab}
      type={type}
      variation={variation}
    >
      {children}
    </RawButton>
  );
};
