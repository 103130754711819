import { z } from 'zod';

import { CandidateSchema } from './candidate';

export const InstantMatchSchema = z.object({
  candidate: CandidateSchema,
  candidateId: z.string(),
  opportunityId: z.string(),
  orderNumber: z.number(),
  passed: z.boolean(),
  rate: z.number(),
});

export type InstantMatch = z.infer<typeof InstantMatchSchema>;
