import Ajv from 'ajv';
import { z } from 'zod';
import { SchemaTimestamp } from '@axiom/types';

import { axiomValidationOptions } from './options';

export const UserSavedCandidateSchema = z.object({
  candidateId: z.string().uuid().nullable(),
  createdAt: SchemaTimestamp,
  id: z.string().uuid(),
  updatedAt: SchemaTimestamp,
  userId: z.string().uuid().nullable(),
});

export type UserSavedCandidate = z.infer<typeof UserSavedCandidateSchema>;

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

export const usersSavedCandidateSearchFilterValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    candidates: {
      type: 'array',
      items: {
        type: 'string',
        format: 'uuid',
      },
    },
  },
};

export const usersSavedCandidateCreationValidation = {
  oneOf: [
    {
      type: 'object',
      additionalProperties: false,
      properties: {
        candidates: {
          type: 'array',
          items: {
            type: 'string',
            format: 'uuid',
          },
        },
        user: {
          type: 'string',
          format: 'uuid',
        },
      },
    },
    {
      type: 'object',
      additionalProperties: false,
      properties: {
        candidates: {
          type: 'array',
          items: {
            type: 'number',
            maximum: Number.MAX_SAFE_INTEGER,
          },
        },
        user: {
          type: 'string',
          format: 'uuid',
        },
      },
    },
  ],
};

export const usersSavedCandidateDeletionValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    candidate: {
      anyOf: [
        {
          type: 'string',
          format: 'uuid',
        },
        {
          type: 'number',
          maximum: Number.MAX_SAFE_INTEGER,
        },
      ],
    },
    user: {
      type: 'string',
      format: 'uuid',
    },
  },
};

export const usersSavedCandidateSearchFilterValidator = ajv.compile(
  usersSavedCandidateSearchFilterValidation
);

export const usersSavedCandidateCreationValidator = ajv.compile(
  usersSavedCandidateCreationValidation
);

export const usersSavedCandidateDeletionValidator = ajv.compile(
  usersSavedCandidateDeletionValidation
);
