import React from 'react';
import { FormFieldProps } from 'semantic-ui-react';
import { SchemaTypes } from '@axiom/types';

import { IconName } from '../../element/Icon/icon-types';
import { FormField } from '../FormField/FormField';
import { RawDropdownDirectionType } from '../Dropdown/RawDropdown';

import {
  RawDropdownTree,
  RawDropdownTreeProps,
  RawDropdownTreeValueType,
} from './RawDropdownTree';

export type DropdownTreeProps = {
  description?: string;
  direction?: RawDropdownDirectionType;
  displayKey: RawDropdownTreeProps['displayKey'];
  disabled?: RawDropdownTreeProps['disabled'];
  icon?: IconName;
  id?: RawDropdownTreeProps['id'];
  label?: React.ReactNode;
  name: RawDropdownTreeProps['name'];
  nestedKey: RawDropdownTreeProps['nestedKey'];
  onBlur?: (value?: RawDropdownTreeValueType) => void;
  onChange?: (value?: RawDropdownTreeValueType) => void;
  onFocus?: (value?: RawDropdownTreeValueType) => void;
  options: RawDropdownTreeProps['options'];
  placeholder?: RawDropdownTreeProps['placeholder'];
  Tooltip?: React.ReactNode;
  valueKey: RawDropdownTreeProps['valueKey'];
};

export const DropdownTree = ({
  description,
  direction,
  displayKey,
  disabled,
  icon,
  id,
  label,
  name,
  nestedKey,
  onBlur = () => {},
  onChange = () => {},
  onFocus = () => {},
  options,
  placeholder,
  Tooltip,
  valueKey,
}: DropdownTreeProps) => {
  return (
    <FormField
      {...{
        description,
        disabled,
        icon,
        id,
        name,
        label,
        onClear: (v: RawDropdownTreeValueType) => onChange(v),
        Tooltip,
      }}
      renderField={({
        value,
        onBlur: formOnBlur,
        setFieldValue,
        invalid,
        schemaProperty,
      }: {
        value: RawDropdownTreeProps['value'];
      } & FormFieldProps) => (
        <RawDropdownTree
          direction={direction}
          displayKey={displayKey}
          disabled={disabled}
          hideIcon={!!icon}
          id={id}
          invalid={invalid}
          multiple={schemaProperty.type === SchemaTypes.ZodArray}
          name={name}
          nestedKey={nestedKey}
          onBlur={e => {
            onBlur(e.target.value);
            formOnBlur({ ...e, target: { ...e.target, name } });
          }}
          onChange={v => {
            onChange(v);
            setFieldValue(name, v);
          }}
          onFocus={() => {
            onFocus();
          }}
          options={options}
          placeholder={placeholder}
          value={value}
          valueKey={valueKey}
        />
      )}
    />
  );
};
