import { z } from 'zod';

export const AccountNewEngagementAssignmentSchema = z.object({
  assignTo: z.string().uuid(),
  canSelectSelfService: z.boolean().default(false),
});

export type AccountNewEngagementAssignment = z.infer<
  typeof AccountNewEngagementAssignmentSchema
>;
