import { z } from 'zod';
import { SchemaTimestamp } from '@axiom/types';

import { AfcAccountSubmissionProjectStateDetailsSchema } from './afc-account-submission-project-state-details';

export const AfcAccountSubmissionSchema = z.object({
  clientName: z.string(),
  createdAt: SchemaTimestamp,
  id: z.string().uuid(),
  jobName: z.string(),
  projectStateDetail: AfcAccountSubmissionProjectStateDetailsSchema,
  submissionId: z.string().uuid(),
  submissionLastUpdatedAt: SchemaTimestamp,
});

export type AfcSubmissionsItem = z.infer<typeof AfcAccountSubmissionSchema>;
