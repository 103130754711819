import { z } from 'zod';

import { AhqSubmissionCandidateExperienceSchema } from './ahq-submission-candidate-experience';
import { CandidateSchema } from './candidate';

export const AhqSubmissionCandidateSchema = CandidateSchema.pick({
  calculatedDisplayName: true,
  id: true,
}).extend({
  experiences: z.array(AhqSubmissionCandidateExperienceSchema),
});

export type AhqSubmissionCandidate = z.infer<
  typeof AhqSubmissionCandidateSchema
>;
