export const selfServiceHelpRequiredValues = {
  NeedsHaveChanged: 'Needs have changed',
  Interviews: 'Interviews',
  Matches: 'Matches',
  TechnicalIssue: 'Technical issue',
  SomethingElse: 'Something else',
} as const;

export const selfServiceHelpRequiredKeys = {
  NeedsHaveChanged: 'NeedsHaveChanged',
  Interviews: 'Interviews',
  Matches: 'Matches',
  TechnicalIssue: 'TechnicalIssue',
  SomethingElse: 'SomethingElse',
} as const;

export type SelfServiceHelpRequired =
  (typeof selfServiceHelpRequiredValues)[keyof typeof selfServiceHelpRequiredValues];
