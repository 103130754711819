import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import {
  AxiomTheme,
  THEME_BG_NAMES,
  THEME_PALETTE_NAMES,
} from '../../../theme/axiom-theme';

type WrapperProps = {
  theme: Record<string, Record<string, string>>;
  backgroundName: (typeof THEME_BG_NAMES)[keyof typeof THEME_BG_NAMES] | null;
};
const Wrapper = styled.div`
  color: ${({ theme }: WrapperProps) => theme.text?.default};
  background-color: ${({ theme, backgroundName }: WrapperProps) =>
    backgroundName && theme.backgrounds?.[backgroundName]};
`;

export const LightTheme = ({
  children,
  backgroundName = THEME_BG_NAMES.primary,
  className,
}: {
  children?: React.ReactNode;
  backgroundName?: WrapperProps['backgroundName'];
  className?: string;
}) => {
  return (
    <ThemeProvider
      theme={AxiomTheme[THEME_PALETTE_NAMES.lightPalette as 'lightPalette']}
    >
      <Wrapper
        backgroundName={backgroundName}
        className={`light-theme ${className}`}
      >
        {children}
      </Wrapper>
    </ThemeProvider>
  );
};
